.addButtons {
  display: flex;
  margin-bottom: 8px;
}

.info {
  font-size: 12px;
  margin-bottom: 4px;
}

.addAllEmployersButton {
  height: 32px;
}

.massAddContainer {
  flex: 1;
}
