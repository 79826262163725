.resultImage {
  align-items: center;
  border-radius: 32px;
  cursor: pointer;
  display: flex;
  height: 64px;
  justify-content: center;
  margin: 16px;
  min-width: 64px;
  opacity: 0.6;
  overflow: hidden;
  position: relative;
  width: 64px;
  &:hover {
    opacity: 1;
  }
}
.resultImage > img {
  max-height: 100%;
  min-height: 64px;
}

.selected {
  margin-bottom: 7px;
  opacity: 1;
}

.selectedDiv {
  background-color: var(--gray-05, rgb(60, 63, 70));
  height: 2px;
  margin-bottom: 7px;
  width: 20px;
}

.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hideContainer {
  background-color: var(--gray-05, rgb(60, 63, 70));
  border-radius: 32px;
  height: 64px;
  min-width: 64px;
  opacity: 0.8;
  position: absolute;
  width: 64px;
}

.blur img {
  filter: blur(20px);
}
