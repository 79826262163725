.mainBlockTitle {
  border-bottom: 1px solid #d1d3d4;
  margin: 0 16px;
  min-height: 72px;
  padding: 0px;
}

.candidateContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  width: 170px;
}

.statusContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
}

.dateContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  justify-content: center;
}

.companyHeader {
  align-items: center;
  display: flex;
  margin-right: 10px;
  padding-left: 64px;
  width: 170px;
}

.otherHeader {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.blockTitle {
  display: flex;
  margin-bottom: 8px;
  padding: 8px 0px;
}

.firstBlock {
  margin-top: 8px;
  max-height: 50vh;
}
