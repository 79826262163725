.resultImage {
  align-items: center;
  border-radius: 32px;
  cursor: pointer;
  display: flex;
  height: 64px;
  justify-content: center;
  margin: 16px;
  margin-right: 8px;
  min-width: 64px;
  opacity: 0.6;
  overflow: hidden;
  position: relative;
  width: 64px;
  &:hover {
    opacity: 1;
  }
}
.resultImage > img {
  max-height: 100%;
  min-height: 64px;
}

.selected {
  margin-bottom: 7px;
  opacity: 1;
}

.selectedDiv {
  background-color: var(--gray-05, rgb(60, 63, 70));
  height: 2px;
  margin-bottom: 7px;
  width: 20px;
}

.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mainContainer {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.hideContainer {
  background-color: var(--gray-05, rgb(60, 63, 70));
  border-radius: 32px;
  height: 64px;
  min-width: 64px;
  opacity: 0.8;
  position: absolute;
  width: 64px;
}

.topOverlay {
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  border-top-left-radius: 64px;
  border-top-right-radius: 64px;
  bottom: 32px;
  height: 32px;
  opacity: 0.1;
  position: absolute;
  transition: opacity 0.25s linear;
  width: 64px;
}

.topOverlaySelected {
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  border-top-left-radius: 64px;
  border-top-right-radius: 64px;
  bottom: 32px;
  height: 32px;
  opacity: 0.7;
  position: absolute;
  transition: opacity 0.25s linear;
  width: 64px;
}

.bottomOverlay {
  background-color: var(--red-01, rgb(255, 0, 64));
  border-bottom-left-radius: 64px;
  border-bottom-right-radius: 64px;
  height: 32px;
  opacity: 0.1;
  position: absolute;
  top: 32px;
  transition: opacity 0.25s linear;
  width: 64px;
}

.bottomOverlaySelected {
  background-color: var(--red-01, rgb(255, 0, 64));
  border-bottom-left-radius: 64px;
  border-bottom-right-radius: 64px;
  height: 32px;
  opacity: 0.7;
  position: absolute;
  top: 32px;
  transition: opacity 0.25s linear;
  width: 64px;
}

.voteContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: space-evenly;
  width: 30px;
}

.nahButton {
  background-color: transparent;
  border: none;
  height: unset;
  min-width: 0px;
}

.numberOverlay {
  background-color: #0000;
  display: flex;
  font-size: 14px;
  height: 64px;
  justify-content: center;
  opacity: 0.6;
  position: absolute;
  width: 64px;
  z-index: 1;
}
