@mixin tealLinks {
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}
