.Button {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  border: 1px solid var(--gray-025, #d1d3d4);
  border-radius: 0px;
  border-right: none;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 32px;
  justify-content: center;
  padding: 3px 24px;
  &.activeOption {
    background-color: var(--gray-05, rgb(60, 63, 70));
    border: 1px solid var(--gray-05, rgb(60, 63, 70));
    border-right: none;
    color: #fff;
  }
}

.first {
  border-radius: 2px 0px 0px 2px;
}

.last {
  border: 1px solid var(--gray-025, #d1d3d4);
  border-radius: 0px 2px 2px 0px;
  &.activeOption {
    background-color: var(--gray-05, rgb(60, 63, 70));
    border: 1px solid var(--gray-05, rgb(60, 63, 70));
    border-right: 1px solid var(--gray-05, rgb(60, 63, 70));
    color: #fff;
  }
}

.ButtonGroup {
  display: flex;
  flex-direction: column;
}

.ButtonsContainer {
  display: flex;
}

.disabled {
  color: var(--gray-03, rgb(155, 161, 167)) !important;
  cursor: default !important;
}
