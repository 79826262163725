.Notification {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: 90vh;
  max-width: 600px;
  overflow: auto;
  padding: 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
}

.title {
  align-items: center;
  display: flex;
  font-size: 24px;
  font-weight: 600;
  justify-content: center;
  margin-bottom: 16px;
}

.text {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  margin-bottom: 24px;
  white-space: pre-line;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  > button:last-child {
    margin-left: 16px;
  }
}

.appear {
  opacity: 0;
}
.appear.appearActive {
  opacity: 1;
}
