.editor {
  border-radius: 0px;
  color: var(--gray-05, rgb(60, 63, 70));
  &.focus {
    outline: var(--seafoam-01, rgb(21, 218, 212)) auto 3px;
    outline-offset: -2px;
  }
  > div:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 8px 0 4px;
  }
  > div > div {
    margin: 0px;
  }
  > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
    min-height: 191px;
  }
  > div > div > div > button {
    > span {
      height: 16px;
      width: 16px;
    }
    border: 1px solid #d1d3d4;
    border-radius: 0px !important;
    color: var(--gray-05, rgb(60, 63, 70));
    height: 24px;
    margin-bottom: 4px !important;
    margin-right: 4px !important;
  }
}

.container {
  max-width: calc(100vw - 64px);
  > div {
    font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif; /* 1 */
    min-height: 200px;
  }
  > div > div {
    font-size: 14px !important;
  }
  > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    min-height: 191px;
  }
}

.editorButton {
  border: 1px solid #d1d3d4;
  border-radius: 0px !important;
  color: var(--gray-05, rgb(60, 63, 70));
}
