.container {
  img {
    width: 100%;
  }

  .verticalDelimiter {
    border-top: 3px solid rgb(219, 219, 219);
    display: block;
    margin: 25px auto;
    width: 40px;
  }
}
