.viewCandidate {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex: 1;
  margin: 8px 0px;
  min-height: 470px;
  position: relative;
}

.viewCandidateModal {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex: 1;
  height: 100%;
  min-height: 470px;
  position: relative;
}

.scrollDiv {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex: 1;
  padding: 24px;
  padding-top: 24px;
}

.contentContainer {
  display: block;
}
