.label {
  margin-bottom: 16px;
  margin-top: 16px;
}

.Locations {
  display: flex;
  flex-direction: column;
}

.extReqId {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 195px;
}

.deleteReqId {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  font-size: 18px;
  padding: 0 8px;
  padding-top: 16px;
  transition: color 0.2s;
}

.deleteReqId:hover {
  color: var(--red-01, rgb(255, 0, 64));
}

.addReqId {
  color: var(--seafoam-02, rgb(0, 191, 186));
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 12px;
  transition: color 0.2s;
}

.disableAddReqId {
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: default;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 12px;
  transition: color 0.2s;
}

.reqIdLabel {
  margin-bottom: 16px;
  margin-top: 24px;
}

.reqIds {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.headcount {
  align-items: center;
  display: flex;
}

.headcountCheckbox {
  margin-left: 16px;
  margin-top: 42px;
}

.compensation {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 700px;
  padding: 0px 32px 48px 32px;
  padding-bottom: 48px;
}

.compTop {
  display: flex;
  flex-direction: column;
}

.labelStyle {
  margin-bottom: 16px;
  margin-top: 16px;
}

.rangeInputs {
  display: flex;
  flex-direction: column;
  max-width: 448px;
}

.rangeSpan {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  padding: 0px 5px;
  text-align: center;
}

.rangeInputsContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.inputContainerNoPad {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.searchBarFilter {
  min-width: 262px;
  width: 100%;
}
