.candidateContent {
  color: inherit;
  width: 210px;
}

.dashboardTitleContainer {
  padding-left: 16px;
}

.candidateHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin-right: 16px;
  padding: 8px 0px;
}

.candidateHeaderLeft {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.referrerStatus {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  width: 133px;
}

.sourceStatus {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  width: 133px;
}

.pendingStatus {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 88px;
}

.approvedStatus {
  color: var(--green-01, #5cab06);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 88px;
}

.archivedStatus {
  color: var(--gray-04, rgb(120, 124, 127));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 88px;
}

.activeStatus {
  align-items: center;
  color: var(--green-01, #5cab06);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 70px;
}
.inactiveStatus {
  align-items: center;
  color: var(--red-01, rgb(255, 0, 64));
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 70px;
}

.locationContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  text-transform: capitalize;
  width: 130px;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.capitalizeSubtitle {
  text-transform: capitalize;
}

.nameHeader {
  padding-left: 60px;
  width: 220px;
}

.statusHeader {
  min-width: 98px;
}

.reffererHeader {
  min-width: 70px;
}

.utmSourcerHeader {
  min-width: 70px;
}

.sourceHeader {
  justify-content: center;
  min-width: 143px;
}

.candActionHeader {
  width: 110px;
}

.updatedAtContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
  width: 80px;
}

.updatedAtHeader {
  display: flex;
  justify-content: center;
  width: 90px;
}

.date {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  font-weight: 500;
}

.iconDanger {
  align-items: center;
  background-color: var(--red-01, rgb(255, 0, 64));
  border-radius: 18px;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 18px;
  justify-content: center;
  user-select: none;
  width: 18px;
}
