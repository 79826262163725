.label {
  font-weight: 600;
  margin-right: 5px;
}

.emailLine {
  font-weight: 500;
  margin-top: 5px;
}

.title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
}

.firstBlock {
  margin-top: 2px;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(21, 218, 212));
    }
  }
}

.block {
  margin-top: 8px;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(21, 218, 212));
    }
  }
}

.collapseButton {
  margin-bottom: 16px;
}

.addSomeCandidatesDropdown {
  border-radius: 0px 2px 2px 0px;
  height: 32px;
  margin-right: 8px;
  > div:nth-child(3) {
    max-width: 60vw;
    min-width: 232px;
    overflow: auto;
  }
}

.detailsContainer {
  display: flex;
  > div > div {
    margin: 0px;
    padding: 8px;
  }
}

.currentMessage {
  min-height: 30px;
}

.messageDetails {
  min-width: 215px;
  padding-top: 8px;
}

.emptyContainer {
  padding: 8px;
}

.messageRecipientColumn {
  border-right: 1px solid var(--gray-hairline, #dbdbdb);
}

.itemDetail {
  font-weight: 500;
  margin-bottom: 4px;
}

.yes {
  color: var(--seafoam-02, rgb(0, 191, 186));
}

.redYes {
  color: var(--red-01, rgb(255, 0, 64));
}

.sendCount {
  align-items: baseline;
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  border-top: 1px solid var(--gray-hairline, #dbdbdb);
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
}

.modalContainer {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.navDetails {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
