.Graphic {
  align-items: center;
  background: var(--gray-01, rgb(247, 247, 247));
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 615px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
}

.Graphic::before {
  background: #fff;
  content: '';
  height: 180px;
  left: 0;
  position: absolute;
  top: -100px;
  transform: skewY(-6deg);
  transform-origin: center;
  width: 100%;
  z-index: -1;
}

.Graphic::after {
  background: #fff;
  content: '';
  height: 900px;
  left: 0;
  position: absolute;
  top: 500px;
  transform: skewY(-6deg);
  transform-origin: center;
  width: 100%;
  z-index: -1;
}

.QuoteContainer {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 24px 0px rgba(0, 0, 0, 0.15), 0 2px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 240px;
  justify-content: space-between;
  min-height: max-content;
  padding: 24px;
  width: 100%;
}

.QuoteContainerOne {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 24px 0px rgba(0, 0, 0, 0.15), 0 2px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: space-between;
  margin-bottom: 36px;
  min-height: max-content;
  padding: 24px;
  width: 100%;
}

.Avatar {
  margin-top: 12px;
}

.Avatar0 img {
  height: 26px;
}

.Avatar img {
  height: 32px;
}

@media only screen and (max-width: 868px) {
  .Graphic {
    display: none;
  }
}
