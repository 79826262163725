.ApproachMessages {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.firstBlock {
  margin-top: 2px;
}

.block {
  margin-top: 8px;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
  word-break: break-word;
}

.empty {
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 200px;
  position: relative;
}

.emailLine {
  font-weight: 500;
  margin-top: 5px;
}

.label {
  font-weight: 600;
  margin-right: 5px;
}

.title {
  margin: 8px 0px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 0px;
  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }
}

.titleTwoBlock {
  margin: 8px;
  margin-bottom: 6px;
  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }
}

.attribute {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 16px;
  > div:nth-child(2) {
    margin-top: 4px;
  }
}

.value {
  margin-top: 4px;
  text-transform: capitalize;
}

.idValue {
  margin-top: 4px;
}

.tier {
  margin-bottom: 16px;
}

.tierRowOne {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -8px;
  margin-left: -16px;
}

.tierRow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
  margin-left: -16px;
  padding-top: 16px;
}

.tierRowFlexStart {
  display: flex;
  flex-wrap: wrap;
  padding-left: 16px;
  padding-top: 16px;
  > div {
    padding-right: 16px;
  }
}

.container {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  width: 208px;
}

.avatarContainer {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 8px;
  overflow: hidden;
  position: relative;
  width: 48px;
}

.avatarContainer img {
  height: 48px;
  object-fit: cover;
  width: 48px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nameContainer {
  align-items: center;
  display: flex;
  font-weight: 600;
}

.jobContainer {
  max-width: 100%;
}

.deleteButton {
  font-size: 10px !important;
  height: 14px !important;
  margin-left: 4px !important;
  max-height: 14px !important;
  max-width: 14px !important;
  min-height: 14px !important;
  min-width: 14px !important;
  padding: 3px !important;
  width: 14px !important;
}

.modal {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  width: 448px;
}

.modalTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 32px;
  text-align: center;
}

.modalButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  margin-top: 16px;
  width: 75%;
}

.empRow {
  align-items: center;
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  border-top: 1px solid #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0px 8px;
}

.employeeTable {
  border: 1px solid var(--gray-02, rgb(247, 247, 247));
  max-height: 226px;
  overflow: scroll;
  width: 100%;
}

.overflow {
  overflow-x: scroll;
}
