.salesBlock {
  margin-bottom: 8px;
}

.rowInputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  > div {
    align-items: center;
    display: flex;
    flex: 1;
    margin-right: 10px;
    max-width: 120px;
    min-width: 120px;
    padding-top: 12px;
  }
}
.columnInputs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: auto;
}

.inputsContainer {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  padding: 0px 12px;
  padding-bottom: 32px;
}

.companyContainer {
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  display: flex;
  height: 110px;
  padding: 24px 0px;
}

.companyLogo {
  height: 32px;
}
.companyLogo > img {
  height: 32px;
}

.companyDetails {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.companyName {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 18px;
  font-weight: 600;
}

.companyPosition {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  margin-bottom: 4px;
}

.companyYears {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
}

.salesPeriodContainer {
  padding-top: 24px;
}

.salesPeriodTitle {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 600;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  padding-top: 24px;
}

.showMoreIcon {
  margin-left: 8px;
}

.salesPeriodMonths {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
}

@media only screen and (max-width: 768px) {
  .rowInputs {
    flex-wrap: wrap;
    > div {
      align-items: flex-start !important;
      > div:nth-child(1) {
        align-items: flex-start !important;
      }
    }
  }
}
