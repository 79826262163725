.Opportunity {
  margin-top: 32px;
  ul {
    font-size: 14px;
    margin-left: 8px;
    margin-top: 8px;
  }
}

.OpportunityContent {
  margin-top: 16px;
}
