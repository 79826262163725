.employerContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-weight: 600;
  width: 170px;
}

.logoWrapper {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 8px;
  width: 48px;
}

.logoWrapper img {
  max-height: 100%;
  max-width: 48px;
}

.statusContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  justify-content: center;
  text-transform: capitalize;
}

.dateContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 14px;
  justify-content: center;
}

.emptyContainerContent {
  align-items: center;
  color: var(--gray-04, rgb(120, 124, 127));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.emptyContainerString {
  margin-bottom: 16px;
  margin-top: 32px;
}
