@import '../../shared/mixins.scss';

.outerWrapper {
  background-color: #ffffff;
  width: 100%;

  .topHeader {
    font-size: 2em;
    font-weight: 700;
    margin: 0;
    text-align: center;
    width: 100%;

    img {
      width: 100%;
    }

    h1 {
      font-size: 30px;
    }

    .verticalDelimiter {
      border-top: 3px solid rgb(219, 219, 219);
      display: block;
      margin: 25px auto;
      width: 40px;
    }
  }

  .wrapper {
    margin: 0 auto;
    max-width: var(--max-width-total, 1152px);
    padding: 8px;
    width: 100%;

    .container {
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      padding: 8px;
      width: 100%;

      .content {
        padding: 8px;
        width: 70%;

        @include tealLinks;

        h2,
        p,
        li {
          font-size: 20px;
        }

        h2 {
          font-weight: 700;
          line-height: 23px;
        }

        li + li {
          margin-top: 24px;
        }
      }

      .applyForm {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 8px 20px;
        padding-top: 120px;
        width: 30%;
      }

      .topMarketsLinksWrapper {
        align-self: stretch;
        border-top: 1px solid rgb(219, 219, 219);
        margin-bottom: 30px;
        padding-top: 30px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .topHeader {
      font-size: 2em;
    }

    .wrapper {
      .container {
        flex-direction: column;

        .content {
          width: 100%;
        }

        .applyForm {
          width: 100%;
        }
      }
    }
  }
}
