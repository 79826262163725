.EditorProfile {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.titleContainer {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-right: 12px;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.companyProfileHeader {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 20px;
}

.companyProfileTitle {
  font-size: 14px;
  font-weight: 600;
}

.containerFlexRow {
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: flex-start;
  padding-bottom: 24px;
}

.containerFlexColumn {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: flex-start;
}

.basicInfoRow {
  display: flex;
  flex-wrap: wrap;
}

.basicInfoTop {
  display: flex;
  flex-direction: column;
}

.basicInfoRowApart {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: max-content;
  > button:nth-child(1) {
    margin-right: 24px;
  }
}

.noPadding {
  padding-top: 0px;
}

.taglineContainer {
  flex: 1;
  margin-right: 48px;
  min-width: 200px;
}

.foundedContainer {
  margin-right: 48px;
  min-width: 80px;
}

.saveContent {
  display: flex;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.autocompleteContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.errorText {
  align-self: center;
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  padding-top: 16px;
  width: max-content;
}

.aboutLabel {
  margin-bottom: 8px;
  margin-top: 24px;
}
.aboutSublabel {
  color: #787c7f;
  font-size: 12px;
  margin-bottom: 8px;
}

.checkbox {
  margin-left: 0px;
  > label {
    align-items: center;
    > span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
