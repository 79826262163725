.InvestorInputs {
  display: flex;
  flex-direction: column;
}

.autocompleteContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}
