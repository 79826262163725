.adminRequestOptionsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: max-content;
  padding: 0px 8px;
  width: 100%;
}

.messageCreator {
  border: 1px solid #ddd;
  border-radius: 3px;
  max-width: calc(100vw - 64px);
  padding: 10px;
  width: 100%;
  > div:nth-child(2) {
    border: none;
    font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif; /* 1 */
    min-height: 200px;
  }
  > div > div {
    font-size: 14px !important;
  }
  > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    min-height: 154px;
  }
}

.requestIntroButtons {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 32px;
  width: 100%;
}

.createNewReqButton {
  align-self: flex-start;
  margin-bottom: 8px;
  margin-left: 28px;
}

.selected {
  color: var(--seafoam-01, rgb(0, 191, 186));
  > div:nth-child(2) {
    font-weight: 700;
  }
}

.avatarContainer {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-bottom: 16px;
  min-height: 48px;
  min-width: 48px;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  width: 48px;
}

.avatar {
  height: 48px;
  object-fit: cover;
  width: 48px;
}

.header {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
}

.subheader {
  margin-bottom: 24px;
  max-width: 448px;
  text-align: center;
  width: 100%;
}

.smallIcon {
  position: relative;
  top: 5px;
}
.reviewButton {
  width: 448px;
}
