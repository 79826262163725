.changeRoleModal {
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
}

.changeRoleContent {
  font-size: 12px;
  margin-bottom: 16px;
  > div {
    margin-bottom: 8px;
  }
}

.changeRoleButtons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  > button {
    margin: 0px 8px;
  }
  > button:last-child {
    margin: 0px;
    margin-left: 8px;
  }
}

.changeRoleTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 8px;
}
