.CandidateEditorPersonal {
  color: var(--gray-05, rgb(60, 63, 70));
  /* height: 100%; */
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw;
}

.EditorPersonal {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
}

.editorButton {
  border: 1px solid #d1d3d4;
  border-radius: 0px !important;
  color: var(--gray-05, rgb(60, 63, 70));
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  padding: 8px;
  padding-top: 2px;
  position: relative;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.resumeDetails {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  margin: 0 25%;
  text-align: center;
}

.resumePresentDetails {
  display: flex;
  flex-direction: column;
  > div {
    justify-content: flex-start;
  }
  & input {
    top: 8px;
    width: 98px;
  }
}

.resumeLabel {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.resumeName {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 600;
}

.resumeUploadButton {
  margin-top: 16px;
}

.resumeUploadNewButton {
  margin-top: 0px;
  padding-left: 0px;
}

.candidateResumeInputContainer {
  display: flex;
  justify-content: flex-start;
}

.phoneEmailInputs {
  display: flex;
  justify-content: flex-start;
  max-width: 520px;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 20px;
  }
}

.nameInputs {
  display: flex;
  justify-content: flex-start;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 10px;
    max-width: 260px;
    min-width: 150px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 260px;
    min-width: 150px;
  }
  > div:nth-child(3) {
    margin-left: 10px;
  }
}

.permPlaceholder {
  position: relative;
}
.permPlaceholder input {
  padding-left: 24px;
  width: 100%;
}

.permPlaceholder > i {
  color: var(--gray-05, rgb(60, 63, 70));
  display: block;
  font-size: 14px;
  font-style: normal;
  left: 9px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 41px;
  width: 18px;
  z-index: 1;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.resumeSaving {
  max-height: 77px;
  min-height: 77px;
}

.profileLocked {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.errorText {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.emailContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.emailContent {
  align-items: baseline;
  display: flex;
  font-size: 14px;
  > div:nth-child(1) {
    margin-left: 8px;
  }
}

.genInfoInputs {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  max-width: 685px;
}

.socialMediaInputs {
  > div:nth-child(1) > div:nth-child(2) {
    padding-top: 0px;
  }
}

.yearMonthContainer {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.yearContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
  width: 100px;
  > label:nth-child(2) {
    margin-left: 16px;
  }
}

.summaryLabel {
  margin-bottom: 5px;
  margin-top: 24px;
}

.summaryInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.summarySublabel {
  margin-bottom: 16px;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.highlight {
  outline: var(--seafoam-01, rgb(21, 218, 212)) auto 3px;
}

@media only screen and (max-width: 768px) {
  .EditorPersonal {
    min-width: 0px;
  }

  .nameInputs {
    flex-direction: column;
    > div:nth-child(2) {
      margin-left: 0px;
    }
    > div:nth-child(3) {
      margin-left: 0px;
    }
  }
  .summaryContainer {
    > div > div {
      font-size: 16px !important;
    }
  }
}
