.errorMessage {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  top: 48px;
  width: 100vw;
  z-index: 3;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-left: 32px;
}

.buttonClass {
  height: 24px;
  margin-right: 12px;
}

.appear {
  opacity: 0;
  transform: translateY(-48px);
}
.appear.appearActive {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.25s ease-out, opacity 0.25s linear;
}
