.EditorReferrals {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.contentBlock {
  margin: 0 8px 8px 8px;
}

.title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
