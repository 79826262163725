.HeaderContainer {
  background-color: #fff;
  display: flex;
  justify-content: center;
  position: fixed;
  transition: box-shadow 0.15s linear;
  width: 100vw;
  z-index: 4;
  .navLink {
    color: var(--gray-05, rgb(60, 63, 70));
  }
}

.HeaderTransparent {
  background-color: #fff;
  .navLink {
    color: var(--gray-03, rgb(155, 161, 167));
    &:hover,
    &:active {
      color: var(--gray-05, rgb(60, 63, 70));
    }
  }
  .activeWelcomeNavLink {
    color: var(--gray-05, rgb(60, 63, 70));
    &:hover,
    &:active {
      color: var(--gray-05, rgb(60, 63, 70));
    }
  }
  .button {
    color: #fff;
    &:hover,
    &:active {
      color: #fff;
    }
  }
  .Logo {
    color: #111;
  }
}

.Header {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  max-width: var(--max-width-total, 1152px);
  min-height: 48px;
  z-index: 4;
}

.headerBoxShadow {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.HeaderFull {
  max-width: 100%;
  padding: 0px 5%;
  z-index: 4;
}

.emptyHeader {
  height: 48px;
}

.LogoBlock {
  align-items: center;
  display: flex;
  flex: 1;
  margin-left: 16px;
  > a {
    height: 20px;
    margin-right: 12px;
  }
}

.welcomeLinksContainer {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-height: 48px;
}

.navLinksContainer {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
}

.navLink {
  font-size: 14px;
  font-weight: 500;
  min-width: max-content;
  padding: 0px 12px;
  transition: color 0.2s linear;
  width: max-content;
}

.navLink:hover {
  color: var(--gray-05, rgb(60, 63, 70));
}

.button {
  align-items: center;
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  border-radius: 2px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  margin-left: 16px;
  min-width: 146px;
  padding: 0px 16px;
  &:hover,
  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  }
}

.activeWelcomeNavLink {
  position: relative;
  &::after {
    border-bottom: 5px solid var(--seafoam-01, rgb(21, 218, 212));
    content: '';
    display: block;
    position: absolute;
    right: 50%;
    top: -16px;
    transform: translateX(50%);
    width: 70%;
  }
}

.activeNavLink {
  color: var(--seafoam-01, rgb(21, 218, 212));
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  position: relative;
  &::after {
    border-bottom: 2px solid var(--seafoam-01, rgb(21, 218, 212));
    bottom: -5px;
    content: '';
    display: block;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    width: 20px;
  }
}

.navAlert {
  background-color: #fff;
  border-radius: 20px;
  height: 40px;
  margin: 12px;
  width: 40px;
}

.navAlertNumber {
  align-items: center;
  background-color: var(--red-01, rgb(255, 0, 64));
  border: solid 1px #ffffff;
  border-radius: 10px;
  display: flex;
  font-size: 12px;
  height: 16px;
  justify-content: center;
  left: 25px;
  position: relative;
  top: 25px;
  width: 16px;
}

.blackout {
  background-color: var(--gray-05, rgb(60, 63, 70));
  display: inline-block;
  height: 14px;
  margin-right: 16px;
  width: 75px;
}

.lightLogo {
  color: #fff;
}

.darkLogo {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 22px;
  width: 176px;
}

.NavDropdown {
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  color: var(--gray-05, rgb(60, 63, 70));
  display: none;
  min-width: 153px;
  position: absolute;
  right: 0px;
  top: 48px;
  z-index: 200;
}

.NavHamburger {
  display: none;
  height: 48px;
  transition: 0.2s all linear;
  width: 32px;
}

.seafoam {
  color: var(--seafoam-01, rgb(21, 218, 212));
}

.hideOnDesktop {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .LogoBlock {
    justify-content: space-between;
  }

  .WelcomeNavBlock {
    display: none;
  }

  .NavDropdown {
    display: flex;
    flex-direction: column;
  }

  .NavHamburger {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .hideOnMobile {
    display: none !important;
  }

  .hideOnDesktop {
    display: inline-flex !important;
  }

  .navLink {
    align-items: center;
    display: flex;
    min-width: max-content;
    padding: 10px 12px;
    width: 100% !important;
    &::after {
      display: none;
    }
  }

  .button {
    height: 32px !important;
    margin-right: 12px;
  }

  .welcomeLinksContainer {
    align-items: center;
    display: flex;
    flex: none;
    justify-content: flex-start;
    max-height: 48px;
  }

  .activeWelcomeNavLink {
    color: var(--seafoam-02, rgb(0, 191, 186)) !important;
    padding: 10px 12px;
  }
}
