.profileHeaderContainer {
  background-color: #fff;
  border-radius: 4px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 36px;
}

.profileName {
  align-items: center;
  color: var(--gray--05, rgb(60, 63, 70));
  display: flex;
  font-size: 24px;
  margin-top: 8px;
  min-height: 33px;
}

.profileStatus {
  background-color: var(--gray-05, rgb(60, 63, 70));
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  margin-left: 10px;
  min-width: max-content;
  padding: 2px 8px;
}

.statusIcon {
  font-size: 16px;
  margin-right: 8px;
}

.profileCompany {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-bottom: 16px;
  margin-top: 3px;
  > span {
    margin-left: 4px;
  }
}

.profileSocial {
  display: flex;
}

.socialMediaButton {
  align-items: center;
  display: flex;
  text-decoration: underline;
}

.socialMediaVideoButton {
  align-items: center;
  display: flex;
  padding: 0px 7px;
}

.socialMedia {
  border-top: 1px solid var(--gray-hairline, #dbdbdb);
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  width: 100%;
  > button {
    padding: 0px 8px;
    &:first-child {
      border-left: none;
      padding-left: 0px;
    }
  }
  > a {
    border-left: 1px solid var(--gray-hairline, #dbdbdb);
    margin-bottom: 8px;
    padding: 0px 8px;
    &:first-child {
      border-left: none;
      padding-left: 0px;
    }
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  width: 100%;
}

.flexRow {
  display: flex;
  flex-wrap: wrap;
  width: 390px;
}

.summary {
  line-height: 1.5;
  margin-top: 24px;
}

@media only screen and (max-width: 768px) {
  .profileHeaderContainer {
    align-items: center;
    background-color: #fff;
    border-radius: 4px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 36px;
    min-height: max-content;
    text-align: center;
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  .flexRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 390px;
    width: unset;
  }

  .profileName {
    justify-content: center;
  }

  .profileCompany {
    justify-content: center;
  }

  .summaryItem {
    justify-content: center;
  }
}
