.addCandidatesOptions {
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.addCandidatesOptionsContainer {
  min-width: 357px;
  padding: 16px;
}

.addCandidatesOption {
  cursor: pointer;
  font-weight: 500;
}

.bottomCandidateOptions {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.addCandidatesTitle {
  width: 100%;
}

.addCandTitle {
  padding-right: 8px;
  padding-top: 8px;
}

.titleText {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  flex: 1;
  margin: 8px;
  max-height: calc(100% - 78px);
}

.candidateContent {
  min-width: 230px;
  width: 230px;
}

.statusContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  min-width: 150px;
  text-transform: capitalize;
}

.statusContainer {
  .lifecycleStatus {
    color: var(--gray-03, rgb(155, 161, 167));
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
  }
}

.greenActive {
  color: var(--green-01, #5cab06);
}

.actionButtons {
  display: flex;
  margin-bottom: 8px;
  margin-top: 4px;
  > button:nth-child(1) {
    margin-right: 16px;
  }
}

.hideButton {
  margin: 8px 0px;
  margin-right: 8px;
  padding: 0px;
}

.info {
  font-size: 12px;
}

.dateNameContainer {
  display: flex;
}

.lifecycleFilter {
  width: 50%;
  margin: 5px 0;
}
