.blockFlex {
  display: flex;
  padding-top: 8px;
}

.inputsContainer {
  flex: 1;
  margin-left: 16px;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
}

.firstInput {
  padding-top: 0px;
}

.nameInputs {
  display: flex;
  justify-content: flex-start;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 8px;
    max-width: 260px;
    min-width: 150px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-left: 8px;
    margin-right: 8px;
    max-width: 260px;
    min-width: 150px;
  }
  > div:nth-child(3) {
    margin-left: 8px;
  }
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.inputContainer {
  display: flex;
  flex-wrap: wrap;
}

.input {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  min-width: 116px;
}

.emailContent {
  align-items: baseline;
  display: flex;
  font-size: 14px;
  > div:nth-child(1) {
    margin-left: 8px;
  }
}
