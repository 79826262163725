.lastItem {
  align-items: center;
  display: flex;
  font-size: 12px;
}

.regularItem {
  align-items: center;
  display: flex;
  font-size: 12px;
}

.regularItem::after {
  background-color: rgba(0, 0, 0, 0.08);
  content: '';
  height: 1px;
  margin: 0px 8px;
  width: 24px;
}

.openItem {
  color: var(--gray-035, rgb(148, 153, 162));
}

.currentItem {
  color: var(--gray-05, rgb(60, 63, 70));
}

.filledItem {
  color: var(--seafoam-02, rgb(0, 191, 186));
  > i {
    color: var(--seafoam-01, rgb(21, 218, 212));
    margin-right: 8px;
  }
}

.openIcon {
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--gray-035, rgb(148, 153, 162));
  border-radius: 9px;
  color: var(--gray-035, rgb(148, 153, 162));
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: 18px;
  justify-content: center;
  margin-right: 8px;
  width: 18px;
}

.currentIcon {
  align-items: center;
  background-color: var(--gray-05, rgb(60, 63, 70));
  border-radius: 9px;
  color: #fff;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: 18px;
  justify-content: center;
  margin-right: 8px;
  width: 18px;
}

.label {
  width: max-content;
}

@media only screen and (max-width: 768px) {
  .label {
    display: none !important;
  }
  .currentIcon {
    margin-right: 0px;
  }
  .openIcon {
    margin-right: 0px;
  }
  .filledItem {
    > i {
      margin-right: 0px;
    }
  }
}
