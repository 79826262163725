.Sidebar {
  background-color: var(--gray-02, rgb(247, 247, 247));
  flex: 1;
  height: 100%;
  max-width: 300px;
  min-height: 540px;
  min-width: 216px;
}

.Content {
  background-color: var(--gray-02, rgb(247, 247, 247));
  flex: 3;
  max-height: calc(100vh - 48px);
}

.right {
  .Sidebar {
    float: left;
  }
}

/* RESPONSIVE */
@media only screen and (max-width: 768px) {
  .Layout {
    flex-direction: column-reverse;
  }

  .Sidebar {
    display: none;
    min-height: 0px;
  }

  .Content {
    height: 80%;
  }
}
