.EmployerData {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  min-height: 48px;
  transition: color 0.07s linear;
}

.EmployerData:hover {
  color: var(--seafoam-02, rgb(0, 191, 186));
}

.employerDataInfoWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 48px;
  min-width: 0;
  padding-left: 12px;
}

.employerDataInfoWrapperShort {
  width: 135px;
}

.employerDataAvatarWrapper {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  width: 48px;
}
.employerDataAvatarLargeWrapper {
  align-items: center;
  display: flex;
  height: 128px;
  justify-content: center;
  position: relative;
  width: 128px;
}
.employerDataAvatarSmallWrapper {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  position: relative;
  width: 32px;
}

.employerDataAvatarWrapper img {
  max-height: 100%;
  max-width: 48px;
}

.employerDataAvatarLargeWrapper img {
  max-height: 100%;
  max-width: 128px;
}
.employerDataAvatarSmallWrapper img {
  max-height: 100%;
  max-width: 32px;
}

.employerDataTitle {
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.employerDataTitleSmall {
  font-size: 12px;
}

.employerName {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.employerDataSubtitle {
  align-items: flex-start;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.employerDataSubtitleBold {
  font-weight: 600;
}

.positionTitle {
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.companyName {
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sideTable {
  padding-bottom: 12px;
}

.verifiedOverlay {
  border-right: 20px solid transparent;
  border-top: 20px solid var(--seafoam-01, rgb(21, 218, 212));
  color: #fff;
  display: flex;
  font-size: 10px;
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  > i {
    color: #fff;
    left: 2px;
    position: relative;
    top: -13px;
  }
}

.archivedOverlay {
  border-right: 20px solid transparent;
  border-top: 20px solid var(--red-01, rgb(218, 21, 21));
  color: #fff;
  display: flex;
  font-size: 10px;
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  > i {
    color: #fff;
    left: 2px;
    position: relative;
    top: -13px;
  }
}
