.imageCropModal {
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 32px;
}

.imageCropTitle {
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
}

.imageCropButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 100%;
}

.slider {
  margin-top: 24px;
  width: 100%;
}

.rotators {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
  > button {
    justify-content: center;
  }
}

.rangeSlider {
  background: var(--gray-025, #d1d3d4);
  border-radius: 10px;
  height: 8px;
  margin: 24px 0;
  position: relative;
  > div:nth-child(1) {
    background-color: var(--seafoam-01, rgb(21, 218, 212));
    border-radius: 10px;
    height: 100%;
    top: 0;
  }
  > div:nth-child(2) {
    background: var(--seafoam-01, rgb(21, 218, 212));
    border-radius: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: inline-block;
    height: 24px;
    margin-left: -12px;
    position: absolute;
    top: -8px;
    width: 24px;
  }
  /**
   * Rangeslider - Labels
   */
  > ul {
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    position: relative;
    text-align: left;
    width: 250px;

    .rangeslider__label {
      position: absolute;

      &::before {
        background: black;
        content: '';
        height: 2px;
        left: -14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        z-index: -1;
      }
    }
    > li:nth-child(1) {
      color: var(--gray-03, rgb(155, 161, 167));
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      min-width: 100px;
      position: absolute;
      top: -36px;
    }
    > li:nth-child(2) {
      color: var(--seafoam-02, rgb(0, 191, 186));
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      min-width: 100px;
      position: absolute;
      top: -36px;
    }
  }
}
