.workDetailContainer {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 16px;
  word-break: break-word;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}
