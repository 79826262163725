.errorMessage {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}

.title {
  font-size: 24px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.body {
  font-size: 14px;
  margin-bottom: 32px;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}

.buttons {
  align-items: center;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
}
