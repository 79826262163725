.contentContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 552px;
  position: relative;
  width: 100%;
  > div:last-child {
    margin-bottom: 32px;
  }
}

.noResumeContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  min-height: 48px;
  width: 100%;
}

.resumeContainer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  min-height: 48px;
  width: 100%;
}

.resumeNameContainer {
  display: flex;
  flex-direction: column;
}

.resumeImage {
  border: 2px solid var(--gray-025, #d1d3d4);
  border-radius: 2px;
  height: 48px;
  margin-right: 16px;
  padding: 6px;
  width: 41px;
}
.resumeRow1 {
  background-color: var(--gray-025, #d1d3d4);
  height: 2px;
  margin-bottom: 4px;
  width: 17px;
}
.resumeRow2 {
  background-color: var(--gray-025, #d1d3d4);
  height: 2px;
  margin-bottom: 4px;
  width: 26px;
}

.resumeName {
  font-size: 12px;
  font-weight: 600;
}

.resumeUploadNewButton {
  margin-top: 0px;
  padding-left: 0px;
}

.candidateResumeInput {
  margin-bottom: 8px;
}

.candidateResumeInputContainer {
  display: flex;
  justify-content: flex-start;
}

.container {
  margin-top: 48px;
  width: 100%;
}

.containerTitle {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 14px;
  padding-left: 16px;
}

.addButton {
  margin-top: 24px;
}

.errorMessage {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.loadingContent {
  display: flex;
  margin-top: 14px;
  width: 100%;
}

.imagePlaceholder {
  background-color: var(--gray-02, rgb(247, 247, 247));
  height: 32px;
  margin-right: 16px;
  width: 48px;
}

.contentPlaceholder {
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: space-between;
  width: 100%;
}

.fakeContent1 {
  background-color: var(--gray-02, rgb(247, 247, 247));
  border-radius: 7px;
  height: 14px;
  max-width: 300px;
  width: 100%;
}

.fakeContent2 {
  background-color: var(--gray-02, rgb(247, 247, 247));
  border-radius: 7px;
  height: 14px;
  max-width: 100px;
  width: 100%;
}

.uploadButton {
  justify-content: center !important;
  max-height: 32px;
  width: 180px;
}

.gray {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
}
