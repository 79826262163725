.CandidateData {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  min-height: 48px;
}

.CandidateData:hover {
  color: var(--seafoam-02, rgb(0, 191, 186));
}

.blur img {
  filter: blur(20px);
}

.candidateDataInfoWrapper {
  display: flex;
  flex-direction: column;
  height: 48px;
  padding-left: 12px;
}

.candidateDataInfoWrapperShort {
  width: 135px;
}

.candidateDataAvatarWrapper {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 48px;
}
.candidateDataAvatarLargeWrapper {
  align-items: center;
  border-radius: 64px;
  display: flex;
  height: 128px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 128px;
}
.candidateDataAvatarSmallWrapper {
  align-items: center;
  border-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 32px;
}

.candidateDataAvatarWrapper img {
  height: 48px;
  object-fit: cover;
  width: 48px;
}

.candidateDataAvatarLargeWrapper img {
  height: 128px;
  object-fit: cover;
  width: 128px;
}
.candidateDataAvatarSmallWrapper img {
  height: 32px;
  object-fit: cover;
  width: 32px;
}

.candidateDataTitle {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.candidateDataTitleSmall {
  font-size: 12px;
}

.candidateName {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.candidateDataSubtitle {
  color: var(--gray-05, rgb(60, 63, 70));
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  max-width: 150px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.candidateDataSubtitleBold {
  font-weight: 600;
}

.positionTitle {
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.companyName {
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sideTable {
  padding-bottom: 12px;
}

.noHighlight {
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: default;
}
