.labelStyle {
  margin-bottom: 16px;
  margin-top: 24px;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.rangeInputs {
  background: #f7f7f7;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 16px 0px;
  padding: 8px 16px 32px 16px;
  > div:nth-child(1) {
    flex: 1;
    max-width: 500px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
}

.textColumn {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  > div {
    font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif; /* 1 */
    min-height: 200px;
  }
  > div > div {
    font-size: 14px !important;
  }
  > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    min-height: 154px;
  }
}

.rangeSpan {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  padding: 16px 5px 0px 5px;
  text-align: center;
}

.rangeInputsContainer {
  align-items: center;
  display: flex;
}

.inputContainerNoPad {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.inputCheckbox {
  background: #f7f7f7;
  margin-bottom: 32px;
  margin-right: 32px;
  padding: 16px;
  padding-left: 0px;
  padding-top: 12px;
  > div:nth-child(1) {
    margin-left: 0px;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    &:before {
      font-size: 12px;
    }
  }
}

.checkbox {
  background: #f7f7f7;
  padding: 16px;
  > div:nth-child(1) {
    margin-left: 0px;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    &:before {
      font-size: 12px;
    }
  }
}

.yearMonthContainer {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.yearContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
  width: 100px;
  > label:nth-child(2) {
    margin: 0px 16px;
  }
  > label:nth-child(4) {
    margin-left: 16px;
  }
}

.inputBulletContainer {
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.rangeSlider {
  background: var(--gray-025, #d1d3d4);
  border-radius: 10px;
  height: 8px;
  margin: 12px 0;
  position: relative;
  width: 190px;
  > div:nth-child(1) {
    background-color: var(--seafoam-01, rgb(21, 218, 212));
    border-radius: 10px;
    height: 100%;
    top: 0;
  }
  > div:nth-child(2) {
    background: #fff;
    border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
    border-radius: 16px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: inline-block;
    height: 24px;
    position: absolute;
    top: -8px;
    transform: translate(-12px);
    width: 24px;
    &:focus {
      outline: none;
    }
  }
  /**
   * Rangeslider - Labels
   */
  > ul {
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    position: relative;
    text-align: left;
    width: 250px;

    .rangeslider__label {
      position: absolute;

      &::before {
        background: black;
        content: '';
        height: 2px;
        left: -14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        z-index: -1;
      }
    }
    > li:nth-child(1) {
      color: var(--gray-03, rgb(155, 161, 167));
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      left: 147px !important;
      position: absolute;
      top: 10px;
    }
    > li:nth-child(2) {
      color: var(--gray-03, rgb(155, 161, 167));
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      left: 68px !important;
      position: absolute;
      top: 10px;
    }
    > li:nth-child(3) {
      color: var(--gray-03, rgb(155, 161, 167));
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      left: -16px !important;
      position: absolute;
      top: 10px;
    }
  }
}

.slider {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  width: 256px;
}

.autocompleteContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.disabled {
  color: var(--gray-03, rgb(155, 161, 167));
  margin-bottom: 16px;
  margin-top: 24px;
  opacity: 0.8;
}

.editor {
  border-radius: 0px;
  color: var(--gray-05, rgb(60, 63, 70));
  &.focus {
    outline: var(--seafoam-01, rgb(21, 218, 212)) auto 3px;
    outline-offset: -2px;
  }
  > div > div {
    margin: 0px;
  }
  > div > div > div > button {
    border: 1px solid #d1d3d4;
    border-radius: 0px !important;
    color: var(--gray-05, rgb(60, 63, 70));
    margin-right: 5px !important;
  }
}
