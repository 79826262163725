.mainContent {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.ReqFormBlocks {
  display: flex;
  flex: 1;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  > div {
    margin: 8px;
    margin-top: 0px;
    /* height: calc(100% - 8px) */
  }
}

.ReqForm {
  display: flex;
  flex: 1;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  > div {
    height: calc(100% - 8px);
    margin: 8px;
    margin-top: 0px;
  }
}

.disabledOverlay {
  background-color: #f7f7f780;
  height: 100%;
  left: 0px;
  margin-top: 80px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.block {
  > div:nth-child(2) {
    padding: 0px 16px 16px 16px;
  }
}

.requirementBlock {
  height: unset !important;
  > div:nth-child(2) {
    padding: 0px 16px 16px 16px;
  }
}

.esQueryBlock {
  word-break: break-all;
}

.suggestionBlock {
  height: calc(100% - 16px);
  margin: 8px;
  overflow: auto;
  > div:nth-child(2) {
    padding: 16px;
  }
}

.titleInput {
  display: flex;
  justify-content: space-between;
}

.titleBlock {
  margin: 8px;
}

.saveAndPublish {
  align-items: center;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.reqActionDropdown {
  padding-left: 16px;
}

.publishButton {
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  width: 136px;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subTitle {
  color: rgb(255, 0, 64);
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
}

.left {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-right: 32px;
}

.text {
  font-weight: 500;
  margin-right: 5px;
}

.nameHeader {
  min-width: 360px;
  padding-left: 64px;
  width: 360px;
}

.statusHeader {
  flex: 1;
  justify-content: center;
}

.newCandButton {
  align-items: center;
  display: flex;
  height: 32px;
  margin-right: 8px;
}

.Table {
  height: calc(100% - 20px);
}

.cancelButton {
  min-width: 0px;
  padding: 0px;
}

.searchBar {
  display: flex;
  padding-right: 8px;
  padding-top: 8px;
  > div {
    flex: 1;
    margin: 0px 4px;
    margin-bottom: 8px;
    max-width: 332px;
    min-width: 210px;
  }
}

.searchBarFilter {
  width: 100%;
}

.searchDate {
  align-items: center;
  display: flex;
}

.checkbox {
  display: flex;
  margin-right: 12px;
  > div:nth-child(1) {
    margin-right: 8px;
  }
  > label {
    align-items: center;
    > span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.disabledOverlay {
  background-color: #f7f7f780;
  height: 1495px;
  left: 0px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.greenhouseJobName {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}
