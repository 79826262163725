.CohortsTable {
  height: 90vh;
  margin-left: 15px;
}

.cohortContent {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  width: 140px;
}

.cohortHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  margin-right: 16px;
  padding: 8px 0px;
  > div:first-child {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }
}
.cohortHeaderBold {
  font-size: 14px;
  font-weight: 600;
}
.cohortHeaderText {
  font-weight: 500;
  margin-right: 5px;
}

.cohortHeaderTextIn {
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
}

.inactiveStatus {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  width: 70px;
}

.activeStatus {
  align-items: center;
  color: var(--green-01, #5cab06);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  width: 70px;
}

.candidatesContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
  width: 100px;
}
.dateContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
  width: 80px;
}

.endDateContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
  width: 90px;
}

.dateHeader {
  display: flex;
  justify-content: center;
  width: 90px;
}

.endDateHeader {
  display: flex;
  justify-content: center;
  width: 100px;
}

.typeContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
  text-transform: capitalize;
  width: 90px;
}

.typeHeader {
  display: flex;
  justify-content: center;
  width: 100px;
}

.tableSmallText {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  width: 80px;
}

.tableSmallTextCenteredDate {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  width: 85px;
}
.tableSmallTextCenteredEnd {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  width: 165px;
}

.tableSmallTextBold {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  width: 55px;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.capitalizeSubtitle {
  text-transform: capitalize;
}
.loader {
  align-items: center;
  clear: both;
  display: flex;
  height: 64px;
  justify-content: center;
}
.TableWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
}

.nameHeader {
  min-width: 150px;
  padding-left: 0px;
  width: 150px;
}

.statusHeader {
  width: 80px;
}

.candidatesHeader {
  display: flex;
  justify-content: center;
  width: 110px;
}

.firstLabel {
  margin-left: 16px;
  width: 240px;
}

.cohortActionHeader {
  min-width: 116px;
  width: 116px;
}
.newCohortButton {
  align-items: center;
  display: flex;
  height: 32px;
  margin-bottom: 4px;
  min-width: 103px;
}

.cohortButtons {
  display: flex;
  flex-direction: column;
}
