.savedSearches {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  padding-bottom: 32px;
  padding-left: 16px;
}

.searchSidebarContent {
  display: flex;
  flex-direction: column;
  min-height: 50%;
  padding: 8px;
  padding-left: 0px;
}

.savedSearchesTitle {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
}

.saveSearchButton {
  margin: 16px 0;
  min-width: 90px;
  padding: 0px;
}

.disabledSaveSearchButton {
  color: var(--gray-025, #d1d3d4);
  cursor: default;
  margin: 24px 0px;
  min-width: 80px;
  padding: 0px;
}
.disabledSaveSearchButton:hover {
  color: var(--gray-025, #d1d3d4);
  cursor: default;
}

@media only screen and (max-width: 768px) {
  .searchSidebarContent {
    min-height: 100%;
    padding: 8px;
    padding-bottom: 0px;
    padding-left: 8px;
  }

  .saveSearchButton {
    margin: 0px;
  }
}
