.profileHeaderContainer {
  align-items: center;
  background-color: #fff;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 212px;
  min-width: 212px;
  position: absolute;
  right: 24px;
  width: 212px;
}

.profileCopyLinkContainer {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
}

.linkNotCopied {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.linkCopied {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.linkCopiedText {
  color: var(--gray-05, rgb(60, 63, 70)) !important;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  padding: 0px 12px;
  transition: all 0.2s linear;
  user-select: none;
}

.linkCopiedTextActive {
  color: var(--gray-05, rgb(60, 63, 70)) !important;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  padding: 0px 12px;
}

.copyLinkIcon {
  font-size: 24px;
  margin-right: 8px;
}

.secureCopyLinkIcon {
  font-size: 24px;
}

.copiedLinkIcon {
  color: var(--seafoam-01, rgb(21, 218, 212));
  font-size: 16px;
  margin-right: 8px;
}

.hidden {
  position: absolute;
  right: 10000px;
}

.showLink {
  border: 1px dotted rgb(60, 63, 70);
  border-radius: 2px;
  font-size: 12px;
  max-width: 256px;
  padding: 8px;
  word-wrap: break-word;
}

.showLinkError {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  margin: 8px 0px;
  max-width: 256px;
}

.button {
  margin-bottom: 8px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .profileHeaderContainer {
    max-width: unset;
    min-width: 0px;
    padding: 8px;
    position: relative;
    right: 0px;
    width: 100%;
  }
}
