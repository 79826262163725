.container {
  margin: 24px;
  margin-top: 32px;
}

.detailContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}

.padded {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
}
