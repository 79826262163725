.TableOverlay {
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0.7;
  padding-top: 15%;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.search {
  height: 200%;
}

.image {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: 0px;
}

.inPlace {
  align-items: center;
  display: flex;
  height: 100%;
  padding-top: 0px;
  position: relative;
}

.appear {
  opacity: 0;
}
.appear.appearActive {
  opacity: 1;
  transition: all 0.2s linear;
}

.savedSearches {
  height: calc(100% - 66px);
  left: 0px;
}

.resume {
  align-items: center;
  height: 77px;
  padding: 0;
  position: relative;
  width: 100%;
}

.candidateLoader {
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 8px;
  opacity: 0.7;
  padding-top: 35%;
  position: absolute;
  width: calc(100% - 16px);
  z-index: 1;
}

.noSidebarLoader {
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0.7;
  padding-top: 35%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.locked {
  background-color: var(--gray-01, rgb(247, 247, 247));
  height: 100%;
  margin-left: -8px;
  margin-top: -2px;
  opacity: 0.4;
  pointer-events: none;
  position: fixed;
  width: 100%;
}

.lockedNotFixed {
  background-color: var(--gray-01, rgb(247, 247, 247));
  height: 100%;
  margin-left: -8px;
  margin-top: -2px;
  opacity: 0.4;
  pointer-events: none;
  width: 102%;
}

.absolute {
  margin-left: 0px;
  position: absolute;
}
