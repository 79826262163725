.EditorPersonal {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.nameInputs {
  display: flex;
  justify-content: flex-start;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 10px;
    max-width: 260px;
    min-width: 150px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 260px;
    min-width: 150px;
  }
  > div:nth-child(3) {
    margin-left: 10px;
  }
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.emailContent {
  align-items: center;
  display: flex;
  font-size: 14px;
  > div:nth-child(1) {
    margin-left: 8px;
  }
}
