.label {
  margin-top: 24px;
}

.labelSubtext {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  margin-right: 10px;
}

.labelSubtextTo {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  margin-right: 10px;
}

.basicInfoRowInputSelects {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.inputSelectsRow {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  min-height: 32px;
  > span {
    flex: 1;
    margin-top: 0px;
    max-width: max-content;
  }
  > button {
    flex: 1;
    margin-top: 0px;
    max-width: max-content;
  }
}

.inputCheckbox {
  margin-left: 220px;
}

.presentLabel {
  font-size: 14px;
  font-weight: 500;
  margin-top: 1px;
}

@media only screen and (max-width: 768px) {
  .inputCheckbox {
    margin-bottom: 16px;
    margin-left: 0px;
  }
  .labelSubtextTo {
    margin-left: 17px;
  }
  .basicInfoRowInputSelects {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
}
