.unverifiedContentContainer {
  font-size: 0.8em;

  .suggestedFieldsContainer {
    display: flex;
    justify-content: space-between;

    .unverifiedImage {
      width: 50%;
    }

    .suggestedField {
      display: block;
    }
  }

  .actions {
    display: flex;

    button {
      height: 20px;
      margin: 5px 5px 15px 0;
      min-height: 0;
      min-width: 0;
      padding: 10px;
    }
  }
}

.noPadding {
  padding-top: 0px;
}

.inputField {
  width: 250px;

  & + & {
    margin-top: 30px;
  }
}

.saveButton {
  align-self: flex-end;
  width: 100px;
}

.containerFlexColumn {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: flex-start;
}

.containerFlexRow {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: flex-start;
  padding-bottom: 24px;
}

.basicInfoTop {
  display: flex;
  flex-direction: column;
}

.inputError {
  align-self: flex-end;
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  margin-top: 10px;
  min-height: 12px;
}
