.player {
  /* max-height: 66vh; */
  /* box-shadow: 0px 0px 60px rgb(60, 63, 70); */
  background-color: #000;
  /* width: 66vw; */
  height: auto;
  max-width: 100%;
}

.player:focus {
  outline: none !important;
}

.selector {
  background-color: transparent;
  border-radius: 20px;
  bottom: 72px;
  color: #fff;
  height: 38px;
  justify-content: center;
  opacity: 0;
  position: absolute;
  right: 4px;
  transition: all 0.2s linear;
  &:hover {
    background-color: rgba(30, 30, 30, 0.7);
  }
  > div > div:nth-child(1) {
    background-color: transparent;
    border: none;
    justify-content: center;
    min-width: 102px;
    transition: all 0.1s linear;
    > div {
      color: #fff;
    }
    > i {
      color: #fff;
      position: relative;
      top: 2px;
    }
  }
  > div > div:nth-child(2) {
    background-color: var(--gray-05, rgb(60, 63, 70));
    > div {
      color: #fff;
      &:hover {
        background-color: rgb(247, 247, 247);
        color: var(--gray-05, rgb(60, 63, 70));
      }
    }
  }
}

.active {
  opacity: 1;
  transition: all 0.2s linear;
}

.videoContainer {
  position: relative;
}
