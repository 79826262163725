.viewCandidate {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex: 1;
  margin: 8px 0px;
  min-height: 470px;
  position: relative;
}

.viewCandidateModal {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex: 1;
  height: 100%;
  min-height: 470px;
  position: relative;
}

.scrollDiv {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: 24px;
  padding-top: 24px;
}

.contentContainer {
  display: block;
  width: calc(100% - 220px);
}

.player {
  height: auto;
  width: 100%;
  > iframe {
    min-height: 75vh;
    width: 100%;
  }
}

.modal {
  height: 100vh;
  justify-content: center;
  max-height: 100vh;
  max-width: 100vw;
  top: 0px;
  width: 100vw;
}

.theaterContainer {
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.closeTheater {
  align-items: center;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  min-width: 0px;
  padding: 0px;
  position: absolute;
  right: 12px;
  top: 12px;
  transition: background 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;
  width: 40px;
}

.closeTheater:hover {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 768px) {
  .viewCandidate {
    min-width: 0px;
  }
  .viewCandidateModal {
    min-width: 0px;
  }
  .scrollDiv {
    background-color: #fff;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px;
    width: 100%;
  }

  .contentContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 8px;
    width: 100%;
  }
}
