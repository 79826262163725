.container {
  display: inline-block;
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px;
  position: relative;
  user-select: none;
  &:focus {
    outline: none;
  }
}

.month {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin: 0 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navBar {
  left: 0;
  padding: 0 0.5rem;
  position: absolute;
  right: 0;
  top: 1rem;
}

.navButtonPrev {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjI2cHgiIGhlaWdodD0iNTBweCIgdmlld0JveD0iMCAwIDI2IDUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zLjIgKDEyMDQzKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5wcmV2PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9InByZXYiIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzLjM5MzE5MywgMjUuMDAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0xMy4zOTMxOTMsIC0yNS4wMDAwMDApIHRyYW5zbGF0ZSgwLjg5MzE5MywgMC4wMDAwMDApIiBmaWxsPSIjNTY1QTVDIj4KICAgICAgICAgICAgPHBhdGggZD0iTTAsNDkuMTIzNzMzMSBMMCw0NS4zNjc0MzQ1IEwyMC4xMzE4NDU5LDI0LjcyMzA2MTIgTDAsNC4yMzEzODMxNCBMMCwwLjQ3NTA4NDQ1OSBMMjUsMjQuNzIzMDYxMiBMMCw0OS4xMjM3MzMxIEwwLDQ5LjEyMzczMzEgWiIgaWQ9InJpZ2h0IiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 1.5rem;
  left: 1rem;
  position: absolute;
  width: 1.5rem;
}

.navButtonNext {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjI2cHgiIGhlaWdodD0iNTBweCIgdmlld0JveD0iMCAwIDI2IDUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zLjIgKDEyMDQzKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5uZXh0PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9Im5leHQiIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuOTUxNDUxLCAwLjAwMDAwMCkiIGZpbGw9IiM1NjVBNUMiPgogICAgICAgICAgICA8cGF0aCBkPSJNMCw0OS4xMjM3MzMxIEwwLDQ1LjM2NzQzNDUgTDIwLjEzMTg0NTksMjQuNzIzMDYxMiBMMCw0LjIzMTM4MzE0IEwwLDAuNDc1MDg0NDU5IEwyNSwyNC43MjMwNjEyIEwwLDQ5LjEyMzczMzEgTDAsNDkuMTIzNzMzMSBaIiBpZD0icmlnaHQiIHNrZXRjaDp0eXBlPSJNU1NoYXBlR3JvdXAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  width: 1.5rem;
}

.navButtonInteractionDisabled {
  display: none;
}

.caption {
  display: table-caption;
  height: 1.5rem;
  text-align: center;
}

.weekdays {
  display: table-header-group;
}

.weekdaysRow {
  display: table-row;
}

.weekday {
  color: var(--gray-04, rgb(120, 124, 127));
  display: table-cell;
  font-size: 0.875em;
  padding: 0.5rem;
  text-align: center;
}

.body {
  display: table-row-group;
}

.week {
  display: table-row;
}

.day {
  border: 1px solid var(--gray-025, #d1d3d4);
  cursor: pointer;
  display: table-cell;
  font-weight: 500;
  padding: 0.5rem;
  text-align: center;
  vertical-align: middle;
  &:focus {
    outline: none;
  }
}

.weekNumber {
  color: var(--gray-04, rgb(120, 124, 127));
  cursor: pointer;
  display: table-cell;
  font-size: 0.75em;
  min-width: 1rem;
  padding: 0.5rem;
  text-align: right;
  vertical-align: middle;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.footer {
  caption-side: bottom;
  display: table-caption;
  padding-top: 0.5rem;
}

.todayButton {
  background-color: rgba(255, 255, 255, 0);
  background-image: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #4a90e2;
  cursor: pointer;
  font-size: 0.875em;
}

/* Default modifiers */

.today {
  background-color: var(--seafoam-04, rgb(219, 252, 251));
  font-weight: 700;
}

.disabled {
  background-color: var(--gray-02, rgb(247, 247, 247));
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: default;
}

.outside {
  color: var(--gray-025, #d1d3d4);
  cursor: default;
}

.selected {
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  color: #fff;
  font-weight: 500;
}
