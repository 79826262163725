@import '../../../../shared/mixins.scss';

.TopQuestions {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0px 24px;
  padding-top: 120px;
  text-align: center;
  width: 100%;

  @include tealLinks;
}

.Title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 36px;
  text-align: center;
}

.Container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 775px;
  width: 100%;
}

.QuestionContainer {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  text-align: left;
  width: 100%;
}

.QuestionContainerNoBorder {
  margin-bottom: 24px;
  text-align: left;
  width: 100%;
}

.Question {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 24px;
}

.Answer {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
}

.FAQLink {
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 600;
}

.Icon {
  font-size: 14px;
  font-weight: 700;
  margin-left: 8px;
}
