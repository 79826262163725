.RequisitionPreview {
  flex: 1;
  min-height: 470px;
  padding: 8px;
  padding-top: 0px;
  position: relative;
}

.scrollDiv {
  background-color: #fff;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: 24px;
  padding-top: 24px;
}

.RequisitionPreviewModal {
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
  flex: 1;
  height: 100%;
  margin: 8px;
  min-height: 470px;
  overflow-y: auto;
  position: relative;
}

.scrollDivModal {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex: 1;
  -webkit-overflow-scrolling: touch;
  padding: 24px;
  padding-top: 24px;
}

.contentContainer {
  display: block;
  height: fit-content;
  width: calc(100% - 220px);
}

.titleBlock {
  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  margin: 8px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  padding: 5px 0;
}

.subTitle {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0;
}

@media only screen and (max-width: 768px) {
  .RequisitionPreview {
    min-width: 0px;
  }
  .RequisitionPreviewModal {
    min-width: 0px;
  }
  .scrollDiv {
    background-color: #fff;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px;
    width: 100%;
  }

  .contentContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: fit-content;
    padding: 8px;
    width: 100%;
  }
}

@media print {
  .contentContainer {
    box-shadow: none;
    color-adjust: exact;
    height: 9in;
    max-height: 9in;
    max-width: 7.5in;
    -webkit-print-color-adjust: exact;
    width: 7.5in;
  }
}
