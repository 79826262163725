.AboutRole {
  margin-top: 32px;
  ul {
    font-size: 14px;
  }
}

.AboutContent {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.BottomContent {
  display: flex;
}

.OteContent {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  display: flex;
  height: 44px;
  margin-bottom: 16px;
  padding: 0px 32px;
}

.LeftContent {
  display: flex;
  flex-direction: column;
}
