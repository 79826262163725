.makeOfferButton {
  height: 40px;
  margin-bottom: 8px;
  width: 100%;
}

.passButton {
  background: #fff;
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;
}
