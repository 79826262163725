.CandidateBookmarks {
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw;
}

.searchMainContent {
  height: 100%;
  padding: 8px;
}

.searchSidebarContent {
  display: flex;
  flex-direction: column;
  min-height: 30%;
  padding: 8px;
  padding-left: 0px;
}

.searchSecondHeader {
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  display: flex;
  justify-content: space-between;
  min-height: 64px;
}

.emptyContainer {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 14px;
  margin-top: 50px;
  text-align: center;
}

.searchResultsContainer {
  flex: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: relative;
}
