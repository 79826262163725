.PassForm {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.firstBlock {
  margin-top: 2px;
}

.block {
  margin-top: 8px;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
  word-break: break-word;
}

.empty {
  display: flex;
  flex: 1;
  justify-content: center;
}

.emailLine {
  font-weight: 500;
  margin-top: 5px;
}

.label {
  font-weight: 600;
  margin-right: 5px;
}

.title {
  margin: 8px 0px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.attribute {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-left: 8px;
}

.value {
  margin-top: 4px;
  text-transform: capitalize;
}

.submit {
  margin-left: 8px;
}
.input {
  margin-bottom: 48px;
  > div > div > div {
    flex: 1;
  }
}

.mainTitle {
  font-size: 24px;
  margin-bottom: 8px;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  margin-bottom: 8px;
  text-align: center;
}

.text {
  margin-bottom: 32px;
}

.reasonText {
  margin-top: 12px;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
