.adminRequestOptionsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-height: max-content;
  width: 100%;
}

.requestIntroButtons {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 32px;
  min-height: 40px;
  width: 100%;
}

.createNewReqButton {
  align-self: flex-start;
  margin-bottom: 8px;
  margin-left: 28px;
}

.header {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
}

.subheader {
  margin-bottom: 24px;
  max-width: 448px;
  text-align: center;
  width: 100%;
}

.nextButton {
  max-width: 448px;
  width: 100%;
}

.createReqInputContainer {
  padding-top: 0px;
}

.createReqInput {
  margin-bottom: 16px;
  margin-right: 0px;
  max-width: 448px;
  position: relative;
  width: 100%;
  > div:nth-child(2) {
    > div {
      width: 100%;
      > div:nth-child(1) {
        height: 40px;
        width: 100%;
      }
    }
  }
  > div:nth-child(3) {
    margin-top: 4px;
  }
  > div:nth-child(4) {
    margin-top: 4px;
    > div:nth-child(1) {
      > div:nth-child(3) {
        > div {
          > div:nth-child(1) {
            width: 100%;
          }
        }
      }
    }
  }
  > div {
    width: 100%;
  }
}

.rangeInputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 24px;
}

.rangeSpan {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  padding: 0px 5px;
  text-align: center;
}

.rangeInputsContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.inputContainerNoPad {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
