.EditorSettings {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.title {
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.titleContainer {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.blockTitle {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding-left: 16px;
}

.showExcludeTitle {
  display: flex;
}

.showTitle {
  align-items: center;
  border-bottom: 4px solid #fff;
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 48px;
  margin-left: 16px;
  padding-top: 4px;
  transition: color 0.2s;
}

.activeTitle {
  border-bottom: 4px solid var(--gray-05, rgb(60, 63, 70));
  color: var(--gray-05, rgb(60, 63, 70));
  font-weight: 600;
}

.noTitlePadding {
  border: none;
  margin: 0px;
  padding: 0px;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.tableHeader {
  background-color: var(--gray-01, rgb(247, 247, 247));
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 56px;
  padding-right: 6px;
}

.companyHeader {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 16px;
  padding-left: 56px;
  width: 180px;
}

.checkboxHeader {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding-right: 10px;
}

.cohortBlockTitle {
  display: flex;
}

.titleContent {
  align-items: center;
  display: flex;
  margin-right: 32px;
}

.cohortFilterContainer {
  align-items: center;
  border: 1px solid var(--gray-03, rgb(155, 161, 167));
  border-radius: 2px;
  display: flex;
  div:nth-child(1) {
    border-radius: 2px 0px 0px 2px;
  }
  div:nth-child(3) {
    border-radius: 0px 2px 2px 0px;
  }
  div:nth-child(2) {
    border-left: 1px solid var(--gray-03, rgb(155, 161, 167));
    border-right: 1px solid var(--gray-03, rgb(155, 161, 167));
  }
}

.cohortFilter {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  width: 84px;
}

.activeFilter {
  background-color: var(--gray-03, rgb(155, 161, 167));
  color: #fff;
}

.activeInputContainer {
  min-width: 184px;
  padding-left: 16px;
}

.topContainer {
  display: flex;
}

.tabContainer {
  background-color: #fff;
  display: flex;
  flex: 1;
  height: 48px;
}

.mainTab {
  align-items: center;
  color: var(--gray-025, #d1d3d4);
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
}

.mainTabActive {
  border-bottom: 1px solid var(--gray-05, rgb(60, 63, 70));
  color: var(--gray-05, rgb(60, 63, 70));
}

.promptModal {
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
}

.promptTitle {
  font-size: 20px;
  padding-bottom: 16px;
}

.promptContent {
  font-size: 14px;
  margin: 0 auto;
  padding: 32px 0px;
}

.confirmationContent {
  font-size: 14px;
  margin: 0 auto;
  padding-bottom: 32px;
  padding-top: 16px;
}

.promptButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.sendConfirmation {
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  font-size: 14px;
  height: 40px;
  justify-content: center;
  margin-right: 8px;
  transition: all 0.2s linear;
  user-select: none;
  width: 100%;
}

.sendConfirmationSent {
  background-color: var(--gray-05, rgb(60, 63, 70)) !important;
  color: #fff !important;
  font-size: 14px;
  height: 40px;
  width: 100%;
  &:hover {
    background-color: var(--gray-05, rgb(60, 63, 70));
    color: #fff;
  }
}

.requisitionHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 16px;
  width: 50%;
}

.reqHeaderBold {
  font-size: 14px;
  font-weight: 600;
}
.reqHeaderText {
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  margin-top: 16px;
}

.linkNotCopied {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.linkCopied {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.linkCopiedText {
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  transition: all 0.2s linear;
  user-select: none;
  width: 136px;
}

.linkCopiedActive {
  background-color: var(--gray-05, rgb(60, 63, 70)) !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  width: 136px;
}
.linkCopiedActive:hover {
  background-color: var(--gray-05, rgb(60, 63, 70));
  color: #fff;
}

.copyLinkIcon {
  font-size: 24px;
  margin-right: 8px;
}

.copiedLinkIcon {
  color: #fff;
  font-size: 16px;
  margin-right: 8px;
}

.hidden {
  position: absolute;
  right: 10000px;
}
