.EditorMain {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 0px;
}

.title {
  align-items: center;
  display: flex;
  flex: 1;
  font-weight: 600;
  justify-content: space-between;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: 16px;
  min-width: 82px;
}

.errorText {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.dateChooser {
  align-items: center;
  display: flex;
  > div {
    margin-right: 8px;
    margin-top: 0px;
  }
}

.dateFromText {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  font-weight: 600;
  margin-right: 12px;
}

.dateFromText::before {
  color: var(--red-01, rgb(255, 0, 64));
  content: '*';
  margin-right: 6px;
  position: relative;
  top: 2px;
}

.dateToText {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
  margin-right: 12px;
}

.candidateContent {
  min-width: 230px;
  width: 230px;
}

.removeCandContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  font-size: 28px;
  transition: color 0.2s linear;
  &:hover {
    color: var(--red-01, rgb(255, 0, 64));
  }
}

.statusContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  min-width: 150px;
  text-transform: capitalize;
}

.greenActive {
  color: var(--green-01, #5cab06);
}

.addCandidatesTitle {
  width: 100%;
}

.addCandTitle {
  align-items: center;
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0px 16px;
}
.candidateSearch {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding-left: 16px;
  width: 100%;
  > div {
    flex: 0.5;
    margin-right: 16px;
  }
  > div > div {
    padding-top: 0px;
  }
}

.locked {
  align-items: baseline;
  background-color: var(--red-01, rgb(255, 0, 64));
  border: 1px solid var(--red-01, rgb(255, 0, 64));
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  padding: 0px 8px;
  text-transform: capitalize;
  user-select: none;
}

.unlocked {
  display: none;
}

.active {
  align-items: baseline;
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  padding: 0px 8px;
  text-transform: capitalize;
  user-select: none;
}

.inactive {
  align-items: baseline;
  background-color: var(--gray-05, rgb(60, 63, 70));
  border: 1px solid var(--gray-05, rgb(60, 63, 70));
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  padding: 0px 8px;
  text-transform: capitalize;
  user-select: none;
}

.category {
  align-items: baseline;
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 8px;
  text-transform: capitalize;
  user-select: none;
}

.titleInputContainer {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0px;
}

.backButtonContainer {
  align-items: center;
  display: flex;
  margin-top: 8px;
}

.backButton {
  background-color: var(--gray-02, rgb(247, 247, 247));
  padding-left: 8px;
}

.addCandidatesOptionsContainer {
  min-width: 357px;
  padding: 16px;
}

.addCandidatesOptions {
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.addCandidatesOption {
  cursor: pointer;
  font-weight: 500;
}

.bottomCandidateOptions {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.addButtons {
  display: flex;
  > div:nth-child(1) {
    margin-right: 16px;
  }
}

.content {
  display: flex;
  flex: 1;
  margin: 8px;
}
