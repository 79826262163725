.EditorPersonal {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
}

.block {
  margin-bottom: 8px;
}

.blockFlex {
  display: flex;
}

.inputsContainer {
  flex: 1;
  margin-left: 16px;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.firstInput {
  padding-top: 0px;
}

.nameInputs {
  display: flex;
  justify-content: flex-start;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 10px;
    max-width: 260px;
    min-width: 150px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 260px;
    min-width: 150px;
  }
  > div:nth-child(3) {
    margin-left: 10px;
  }
}

.backButton {
  background-color: inherit;
  padding-left: 0px;
}

.backButtonContainer {
  margin-left: 16px;
  margin-top: 8px;
}
