.HowItWorks {
  background: #fff;
  padding-bottom: 128px;
}

.Container {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 1132px;
  overflow: hidden;
  padding: 0px 24px;
  position: relative;
  width: 100%;
}

.Title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 64px;
  text-align: center;
}

.Column {
  display: inline-block;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  width: 33.33333333333333%;
}

.Image {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }
}

.StepTitle {
  font-size: 20px;
  margin-bottom: 16px;
  text-align: center;
}

.Description {
  font-size: 18px;
  line-height: 1.75;
  padding: 0 8px;
}

.Underline {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .HowItWorks {
    padding-bottom: 0px;
  }
  .Column {
    margin-bottom: 48px;
    width: 100%;
  }
}
