.overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 300;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  height: 100%;
  left: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100%;
  > span {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}

.backdropActive {
  background-color: rgba(0, 0, 0, 0.38);
}
