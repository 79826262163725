.Graphic {
  align-items: center;
  background: var(--gray-01, rgb(247, 247, 247));
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 568px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
}

.Graphic::before {
  background: #fff;
  content: '';
  height: 180px;
  left: 0;
  position: absolute;
  top: -123px;
  transform: skewY(-6deg);
  transform-origin: center;
  width: 100%;
  z-index: -1;
}

.Graphic::after {
  background: #fff;
  content: '';
  height: 900px;
  left: 0;
  position: absolute;
  top: 468px;
  transform: skewY(-6deg);
  transform-origin: center;
  width: 100%;
  z-index: -1;
}

.QuoteContainer {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 24px 0px rgba(0, 0, 0, 0.15), 0 2px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
  min-height: max-content;
  padding: 24px;
  width: 100%;
}

.QuoteContainerOne {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 24px 0px rgba(0, 0, 0, 0.15), 0 2px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
  margin-bottom: 36px;
  min-height: max-content;
  padding: 24px;
  width: 100%;
}

.Person {
  display: flex;
}

.Avatar {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 48px;
}

.Avatar img {
  height: 48px;
  object-fit: cover;
  width: 48px;
}

.Info {
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: space-around;
  padding-left: 12px;
}

.Name {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: 700;
  margin: 5px 0px;
  text-align: left;
  width: 100%;
}

.Role {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .Graphic {
    display: none;
  }
}
