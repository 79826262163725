.profileSalesContainer {
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-left: 20px;
}

.showMoreContainer {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  margin-left: 82px;
  margin-top: 48px;
}

.showMoreIcon {
  margin-left: 8px;
}

.marginBottom {
  margin-bottom: 48px;
}

.showMoreSales {
  font-weight: 500;
}

@media print {
  .workHistoryTitle {
    break-after: avoid;
  }
}
