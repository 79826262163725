.candidateSearch {
  align-items: center;
  display: flex;
  flex: 1;
  height: 40px;
  justify-content: space-between;
  margin-right: 8px;
  max-width: 328px;
  > div {
    flex: 1;
    margin-right: 16px;
    padding-top: 0px;
  }
  > div > div {
    padding-top: 0px;
  }
}
