.FeaturedPage {
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw !important;
}

.featuredMainContent {
  height: 100%;
  padding: 8px;
}

.featuredCandidatesContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  > span {
    flex: 1;
  }
}

.featuredCandidatesContainer::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  content: '';
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: 16px;
  z-index: 1;
}

.featuredCandidatesContainer::before {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
  content: '';
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 16px;
  z-index: 1;
}

.candidatesGroup {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-around;
}

.enterRight {
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(100%, 0);
}

.enterActiveRight {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.75s ease;
}

.leaveRight {
  opacity: 1;
  transform: translate(0, 0);
}

.leaveActiveRight {
  opacity: 0;
  transform: translate(-100%, 0);
  transition: all 0.75s ease;
}

.enterLeft {
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-100%, 0);
}

.enterActiveLeft {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.75s ease;
}

.leaveLeft {
  opacity: 1;
  transform: translate(0, 0);
}

.leaveActiveLeft {
  opacity: 0;
  transform: translate(100%, 0);
  transition: all 0.75s ease;
}

.buttonRight {
  margin: 16px;
}

.emptyContainer {
  align-items: center;
  color: var(--gray-04, rgb(120, 124, 127));
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  min-height: 300px;
  padding-top: 15%;
}

.emptyTableString {
  margin-bottom: 16px;
}

.autocomplete {
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 64px;
  padding-bottom: 16px;
  width: 100%;
  > div:nth-child(1) {
    margin-left: 12px;
    width: 60%;
  }
  > div:nth-child(1) > div:nth-child(1) {
    padding-top: 16px;
  }
}

.employerResultsContainer {
  align-items: center;
  display: flex;
  margin-left: 12px;
  margin-top: 16px;
}

.emptyProfile {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
}

.titleLeft {
  align-items: center;
  background-color: #f7f7f7;
  border: 1px solid #d1d3d4;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  padding: 0px 8px;
}

.titleMiddle {
  align-items: center;
  background-color: #f7f7f7;
  border: 1px solid #d1d3d4;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  padding: 0px 8px;
}

.titleRight {
  align-items: center;
  background-color: #f7f7f7;
  border: 1px solid #d1d3d4;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  padding: 0px 8px;
}

.highlightTitleLeft {
  align-items: center;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 0px 0px 0px 0px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  padding: 0px 8px;
}

.highlightTitleMiddle {
  align-items: center;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 0px 0px 0px 0px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  padding: 0px 8px;
}

.highlightTitleRight {
  align-items: center;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 0px 0px 0px 0px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  padding: 0px 8px;
}

.titleContainer {
  align-items: center;
  display: flex;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .featuredMainContent {
    height: calc(100vh - 110px);
  }
}
