.EditorSales {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.firstBlock {
  margin-top: 8px;
}
.salesBlock {
  margin-bottom: 8px;
}

.salesBlockEmpty {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  min-height: 350px;
}
.content {
  font-size: 14px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.resumeDetails {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  margin: 0 25%;
  text-align: center;
}

.salesInputEmptyContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.rowInputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  > div {
    flex: 1;
    margin-right: 10px;
    max-width: 120px;
    min-width: 90px;
    padding-top: 12px;
  }
}
.columnInputs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inputsContainer {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  padding: 0px 12px;
  padding-bottom: 32px;
}

.companyContainer {
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  display: flex;
  height: 110px;
  padding: 24px 0px;
}

.companyLogo {
  height: 32px;
}
.companyLogo > img {
  height: 32px;
}

.companyDetails {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.companyName {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 18px;
  font-weight: 600;
}

.companyPosition {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  margin-bottom: 4px;
}

.companyYears {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
}

.salesPeriodContainer {
  padding-top: 24px;
}

.salesPeriodTitle {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 600;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  padding-top: 24px;
}

.showMoreIcon {
  margin-left: 8px;
}
