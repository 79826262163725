.SearchBarOne {
  flex: 1;
  margin-right: 32px;
  max-width: 332px;
  min-width: 210px;
  > div {
    margin-bottom: 4px;
    min-height: 40px;
  }
}

.SearchBarTwo {
  flex: 1;
  max-width: 332px;
  min-width: 210px;
  > div {
    margin-bottom: 4px;
    min-height: 40px;
  }
}

.checkbox {
  margin-left: 0px;
}
