.EditorIntro {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 0px;
}

.title {
  font-weight: 600;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.mainBlock {
  margin: 8px;
  margin-bottom: 0px;
  > div:nth-child(1) {
    > div {
      > iframe {
        max-height: 462px;
        width: 100%;
      }
    }
  }
}

.instructionsBlock {
  margin-top: 16px;
}

.instructionsHead {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
}

.player {
  background-color: #000;
  height: auto;
  width: 100%;
}

.player:focus {
  outline: none !important;
}

.delete {
  margin-left: auto;
  > i {
    margin-right: 5px;
  }
}

.modal {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
}

.modalContent {
  font-size: 14px;
  margin-bottom: 16px;
  width: 85%;
}

.modalButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.emptyVideo {
  height: 462px;
}
