.modalContainer {
  align-items: center;
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 486px;
  padding: 16px;
  width: calc(100vw - 48px);
}

.nonModalContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-height: 486px;
  width: 100%;
}

.adminRequestOptionsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-height: max-content;
  width: 100%;
}

.table {
  border: 1px solid var(--gray-hairline, #dbdbdb);
  width: 100%;
  > .row:nth-child(1) {
    border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
    cursor: default;
    padding: 8px 0px;
  }
}

.tableMain {
  height: 240px;
  max-height: 240px;
  min-height: 150px;
  overflow-y: scroll;
}

.row {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 4px 0px;
  > div:nth-child(2) {
    flex: 2;
    padding-right: 8px;
  }
}

.mainRow:hover {
  background-color: var(--gray-01, rgb(247, 247, 247));
}

.selectedCell {
  color: var(--seafoam-01, rgb(21, 218, 212));
  display: flex;
  justify-content: center;
  margin-left: 8px;
  min-width: 36px;
}

.cell {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.requestIntroButtons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 32px;
  max-width: 600px;
  min-height: 40px;
  width: 100%;
}

.buttonsRight {
  align-items: center;
  display: flex;
}

.selected {
  color: var(--seafoam-01, rgb(0, 191, 186));
  > div:nth-child(2) {
    font-weight: 700;
  }
}

.disabled {
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: default;
}

.header {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
}

.subheader {
  margin-bottom: 24px;
  text-align: center;
}
