.mainContainer {
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
}

.SearchResult {
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin: 16px;
  a {
    color: var(--gray-05, rgb(60, 63, 70));
  }
}

.SearchResult:hover {
  color: var(--seafoam-02, rgb(0, 191, 186));
  a {
    color: var(--seafoam-02, rgb(0, 191, 186));
  }
}

.blur img {
  filter: blur(20px);
}

.hidden {
  display: none;
}

.resultImage {
  align-items: center;
  border-radius: 32px;
  display: flex;
  height: 64px;
  justify-content: center;
  margin: 16px;
  margin-left: 0px;
  margin-top: 0px;
  overflow: hidden;
  position: relative;
  width: 64px;
}
.resultImage > img {
  max-height: 100%;
  min-height: 64px;
}

.resultDetails {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.resultHeader {
  align-content: center;
  align-items: center;
  display: flex;
  padding-top: 8px;
}

.resultName {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  transition: color 0.07s linear;
  &:hover {
    color: inherit;
  }
}

.resultPosition {
  align-items: center;
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  font-size: 14px;
  line-height: 20px;
}

.resultStatus {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
}

.markdownSummary {
  font-size: 14px;
  line-height: 20px;
  margin: 16px 0px;
  word-break: break-word;
  > div {
    margin-bottom: 12px;
  }
  > div:last-child {
    margin-bottom: 0px;
  }
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}

.resultSummary {
  line-height: 1.5;
}

.recentRole {
  border-top: 1px solid var(--gray-02, rgb(247, 247, 247));
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  margin-bottom: 8px;
  padding-top: 8px;
}

.resultNumbers {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 600;
}

.resultNumberDetails {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  line-height: 20px;
  margin-right: 24px;
}

.numberLabel {
  color: var(--gray-03, rgb(155, 161, 167));
  font-weight: 500;
  padding-left: 5px;
}

.resultMatch {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  justify-content: flex-end;
  min-width: 96px;
  padding-top: 16px;
}

.resultPercent {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 600;
  padding-right: 8px;
}

.percentLabel {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  font-weight: 500;
}

.resultBookmark {
  cursor: pointer;
  font-size: 24px;
  height: 24px;
  transition: fill 0.07s linear, color 0.07s linear;
  width: 24px;
}

.resultBookmarked {
  color: var(--seafoam-01, rgb(21, 218, 212));
}
.resultUnbookmarked {
  color: var(--seafoam-01, rgb(21, 218, 212));
  /*  color: var(--gray-02, rgb(247, 247, 247)); */
}

.newContent {
  color: var(--orange-01, rgb(255, 167, 0));
  /* display: flex; */
  display: none;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.hiringStatus {
  background-color: var(--gray-05, rgb(60, 63, 70));
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  margin-left: 10px;
  padding: 2px 8px;
}

.hideContainer {
  background-color: var(--gray-05, rgb(60, 63, 70));
  border-radius: 32px;
  height: 64px;
  min-width: 64px;
  opacity: 0.8;
  position: absolute;
  width: 64px;
}

.profileCompany {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  margin-top: 5px;
  > span {
    margin-left: 4px;
  }
}

.analysisRow {
  align-items: flex-start;

  display: flex;
  gap: 10px;
  margin-top: 15px;

  .linkToAdmin {
    align-items: center;
    display: flex;
    font-size: 0.9em;

    font-weight: bold;
    gap: 5px;
    margin-top: -5px;
  }
}
