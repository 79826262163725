.root {
  padding-bottom: 0px;
  position: relative;

  i.closeIcon {
    position: absolute;

    right: 7px;
    top: 4px;
    font-size: 12px;

    height: 32px;

    display: flex;
    align-items: center;

    cursor: pointer;

    color: var(--gray-03, rgb(155, 161, 167));
    &:hover {
      color: var(--gray-05, rgb(60, 63, 70));
    }
  }
}

.input {
  border: 1px solid #d1d3d5;
  color: var(--gray-05, rgb(60, 63, 70));
  display: inline-block;
  font-family: Inter;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}

.input.withCloseButton {
  padding-right: 30px;
}

.autocompleteContainer {
  background-color: #fff;
  border: none;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 11;
}
.autocompleteItem {
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  z-index: 11;
}
.autocompleteItemActive {
  background-color: var(--gray-01, rgb(247, 247, 247));
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  z-index: 11;
  z-index: 11;
}
.googleLogoContainer {
  align-items: center;
  background-color: #fff;
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: flex-end;
  padding: 1px;
}
.googleLogoImage {
  height: 14px;
  width: 112px;
}

.hasError {
  border: 1px solid var(--red-01, rgb(255, 0, 64));
}

.hasError:focus {
  outline: var(--red-01, rgb(255, 0, 64)) auto 3px;
}

.error {
  color: var(--red-01);
  display: block;
  font-size: 0.8em;
  left: 0;
  position: absolute;
  top: -15px;
}
