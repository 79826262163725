.ManageReqs {
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw;
}
.requisitionsTable {
  height: 90vh;
  margin-left: 15px;
}

.requisitionFilters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 100%;
}

.newReqButton {
  align-items: center;
  display: flex;
  height: 32px;
  margin-right: 8px;
}

.manageReqsMainContent {
  height: 100%;
  padding: 8px;
}

.requisitionHeader {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  height: 48px;
  justify-content: space-between;
  width: 100%;
}
.reqHeaderBold {
  font-size: 14px;
  font-weight: 600;
}
.reqHeaderText {
  font-weight: 500;
  margin-right: 5px;
}
