.Summary {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.SummaryContent {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  margin-right: -4px;
  margin-top: 16px;
}

.SummaryItem {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  display: flex;
  flex: 1 1 180px;
  height: 44px;
  justify-content: center;
  margin-bottom: 4px;
  margin-right: 4px;
}
