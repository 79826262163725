.CandidatesTable {
  height: 90vh;
  margin-left: 15px;
}

.candidateContent {
  color: inherit;
  width: 210px;
}

.candidateHeader {
  align-items: baseline;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  margin-right: 16px;
  padding: 8px 0px;
}

.candidateHeaderTextIn {
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
}

.statusContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  width: 150px;
}

.statusPassedContent {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 48px;
  width: 150px;
}

.typeContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  text-transform: capitalize;
  width: 150px;
}

.span {
  margin: 0px 5px;
}

.statusHiredContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  width: 50px;
}

.tableSmallText {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  margin-top: 5px;
}

.tableText {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  height: 19px;
}

.tableSmallTextCenteredTimeActive {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  width: 75px;
}
.tableSmallTextCenteredDate {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  width: 85px;
}
.tableSmallTextCenteredEnd {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  width: 165px;
}

.tableSmallTextBold {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  width: 55px;
}

.trashContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.capitalizeSubtitle {
  text-transform: capitalize;
}

.nameHeader {
  padding-left: 60px;
  width: 220px;
}

.statusHeader {
  width: 160px;
}

.hiredStatusHeader {
  width: 60px;
}

.centered {
  display: flex;
  justify-content: center;
  width: 85px;
}

.hiredHeader {
  width: 95px;
}

.hiredEndHeader {
  width: 175px;
}

.typeHeader {
  margin-right: 6px;
  width: 160px;
}

.candActionHeader {
  width: 110px;
}
