.adminRequestOptionsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 448px;
  min-height: max-content;
  width: 100%;
}

.requestIntroButtons {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 32px;
  min-height: 40px;
  width: 100%;
}

.createNewReqButton {
  align-self: flex-start;
  margin-bottom: 8px;
  margin-left: 28px;
}

.selected {
  color: var(--seafoam-01, rgb(0, 191, 186));
  > div:nth-child(2) {
    font-weight: 700;
  }
}

.avatarContainer {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-bottom: 16px;
  min-height: 48px;
  min-width: 48px;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  width: 48px;
}

.avatar {
  height: 48px;
  object-fit: cover;
  width: 48px;
}

.header {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
}

.subheader {
  margin-bottom: 24px;
  max-width: 448px;
  text-align: center;
  width: 100%;
}

.subheadertext {
  color: var(--gray-04, rgb(120, 124, 127));
  font-size: 12px;
  margin: 24px 0px;
  width: 100%;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
  }
}

.smallIcon {
  position: relative;
  top: 5px;
}

.avatarContainerTiny {
  align-items: center;
  border-radius: 12px;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 24px;
  min-height: 24px;
  min-width: 24px;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  width: 24px;
}

.avatarTiny {
  height: 24px;
  object-fit: cover;
  width: 24px;
}

.container {
  font-size: 14px;
  margin-bottom: 24px;
  padding-top: 24px;
  width: 90%;
}

.offerItem {
  display: flex;
  font-size: 14px;
  margin-bottom: 16px;
  p {
    margin-top: 0px;
  }
  > div:nth-child(1) {
    margin-right: 36px;
  }
  > i:nth-child(1) {
    align-items: flex-start;
    margin-right: 36px;
    padding-top: 2px;
    width: 24px;
  }
}
.companyOfferItem {
  display: flex;
  font-size: 14px;
  margin-bottom: 16px;
  > div:nth-child(1) {
    margin-right: 36px;
    width: 78px;
  }
  > i:nth-child(1) {
    margin-right: 36px;
    width: 24px;
  }
}

.sendRequest {
  max-width: 448px;
  width: 100%;
}

.salutation {
  margin-bottom: 8px;
}
