@import '../../vars.scss';

.educationSectionContainer {
  .educationData {
    display: grid;
    gap: 0px 20px;
    grid-template-columns: 30px auto;

    img {
      border-radius: $iconBorderRadius;
      width: $iconSize;
    }

    .companyDelimiter {
      margin-bottom: 20px;
    }
  }

  .centered {
    align-items: center;
    display: flex;
  }
}
