.workNameContainer {
  display: flex;
  flex: 1;
  min-width: 225px;
}

.workNameDetailContainer {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 16px;
}

.workNameDetailTop {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.workNameDetail {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.workPosition {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 700;
}

.workName {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
}

.workDate {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  font-weight: 500;
}

.workLogoContainer {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: center;
  margin-right: 24px;
  width: 64px;
}

.workLogo {
  margin-top: 4px;
  max-height: 100%;
  max-width: 64px;
}

.hoverIcon {
  display: flex;
  justify-content: center;
  line-height: 19px;
  margin-left: 8px;
  min-height: 22px;
  min-width: 22px;
  padding-top: 2px;
  position: relative;
}

.hoverContainer {
  background-color: #fff;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.15);
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: default;
  font-size: 14px;
  font-weight: 500;
  left: -23px;
  max-width: 380px;
  padding: 16px;
  position: absolute;
  top: 28px;
  width: max-content;
  z-index: 2;
}

.hoverHeader {
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 5px;
}

.hoverContent {
  padding-bottom: 15px;
}

.hoverDetails {
  display: flex;
  justify-content: flex-start;
}

.hoverDetailsEmpty {
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: center;
  margin-bottom: 15px;
}

.hoverDetail {
  flex: 1;
}

.hoverDetailLabel {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
}

.hoverTriangle {
  background-color: #fff;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15);
  height: 10px;
  left: 33px;
  position: absolute;
  top: -5px;
  transform: rotate(135deg);
  width: 10px;
}
