.CandidateEditorPreview {
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw;
}

.viewCandidate {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 736px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.middleBlock {
  margin: 0px 8px;
}

.mainBlock {
  margin-top: 8px;
}

.scrollDiv {
  flex: 1;
  padding: 8px;
  padding-top: 2px;
}

.dataVisualsRow {
  display: flex;
  flex-direction: row;
  height: 188px;
  margin-bottom: 8px;
}

.dataVisualFirstBlock {
  flex: 2;
}

.dataVisualSecondBlock {
  flex: 1;
  margin-left: 8px;
}

.visualsSubtitle {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  margin-left: 5px;
}

.emptyDataVisual {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 50px;
}

.dataVisualsMostRecent {
  display: flex;
  justify-content: space-around;
}
