.CompaniesTable {
  height: 90vh;
  margin-left: 15px;
}

.companyContent {
  color: inherit;
  width: 200px;
}

.companyHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  margin-right: 16px;
  padding: 8px 0px;
}

.companyBottom {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  padding-bottom: 8px;
  width: 100%;
}

.candidateHeaderLeft {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.unverifiedStatus {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 98px;
}

.iconExplode {
  align-items: center;
  background-color: var(--red-01, rgb(255, 0, 64));
  border-radius: 12px;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 17px;
  justify-content: center;
  padding-left: 1px;
  user-select: none;
  width: 17px;
}
.iconDanger {
  align-items: center;
  background-color: var(--orange-01, rgb(255, 167, 0));
  border-radius: 18px;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 18px;
  justify-content: center;
  padding-left: 1px;
  user-select: none;
  width: 18px;
}

.verifiedStatus {
  align-items: center;
  color: var(--seafoam-01, rgb(21, 218, 212));
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 98px;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.nameHeader {
  min-width: 210px;
  padding-left: 64px;
  width: 210px;
}

.statusHeader {
  justify-content: center;
  min-width: 108px;
}

.candActionHeader {
  min-width: 110px;
  width: 110px;
}
