.tertiaryContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 48px;
  margin-top: 8px;
  padding-bottom: 24px;
  width: 100%;
}

.containerTitle {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
  width: 100%;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.yearContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
  width: 100px;
  > label:nth-child(2) {
    margin-left: 16px;
  }
}

.yearMonthContainer {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
