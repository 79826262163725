.employeesTable {
  height: 90vh;
  margin-left: 15px;
}

.employeeFilters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 100%;
}

.newEmployeeButton {
  align-items: center;
  display: flex;
  height: 32px;
  margin-right: 8px;
}

.employeesMainContent {
  height: 100%;
  min-height: 540px;
  min-width: 524px;
  padding: 8px;
}

.employeesHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  width: 100%;
}

.employeesHeaderText {
  font-size: 14px;
  font-weight: 500;
  margin: 12px 5px 5px 0px;
}

.employeesText {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}

.checkbox {
  margin-left: 0px;
}
