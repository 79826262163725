.Admin {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
}

.block {
  margin: 8px;
  > div:nth-child(1) {
    > div:nth-child(2) {
      height: calc(100vh - 88px);
    }
  }
}

.statusHeader {
  flex: 1;
  justify-content: center;
}

.nameHeader {
  flex: 1;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
}

.left {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-right: 32px;
}

.text {
  font-weight: 500;
  margin-right: 5px;
}
