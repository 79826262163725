.emptyTable {
  align-items: center;
  color: var(--gray-04, rgb(120, 124, 127));
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  min-height: 300px;
  padding-top: 15%;
}

.emptyTableString {
  margin-bottom: 16px;
}
