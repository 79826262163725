.User {
  cursor: pointer;
  display: flex;
  height: 48px;
  margin: 0px 16px 0px 12px;
  position: relative;
}

.Avatar {
  cursor: pointer;
  max-height: 100%;
  max-width: 64px;
}

.userOptionsContainer {
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: absolute;
  right: -16px;
  top: 49px;
  width: 153px;
  z-index: 200;
}

.adminOption {
  height: 46px;
}
.adminOption a {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  height: 46px;
  &:visited {
    color: var(--gray-05, rgb(60, 63, 70));
  }
}

.signOutOption {
  color: var(--red-01, rgb(255, 0, 64));
  height: 46px;
}

.Option {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 46px;
  justify-content: flex-start;
  padding: 0px 12px;
}

.Option:hover {
  background-color: var(--gray-01, rgb(247, 247, 247));
}

.avatarContainer {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  justify-content: space-between;
}

.avatarContainerDark {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
}

.userCaret {
  color: var(--seafoam-01, rgb(21, 218, 212)) !important;
  margin-left: 5px;
}

.companyName {
  max-width: 118px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
