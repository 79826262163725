.Tag {
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  border-radius: 14px;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: default;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-right: 12px;
  min-height: 28px;
  padding: 0 8px;
  position: relative;
  user-select: none;
  &.hasDelete {
    padding-right: 24px;
  }
}

.disabledButton {
  align-items: center;
  border-radius: 16px;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: default;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: 1px;
  top: -3px;
  transition: fill 0.07s linear;
  width: 24px;
}

.deleteButton {
  align-items: center;
  border-radius: 16px;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: 1px;
  top: -3px;
  transition: fill 0.07s linear;
  width: 24px;
}

.deleteButton:hover {
  color: var(--red-01, rgb(255, 0, 64));
}

.grayTag {
  background-color: var(--gray-01, rgb(247, 247, 247));
  border: 1px solid var(--gray-03, rgb(155, 161, 167));
}
