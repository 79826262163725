.extraFiltersContainer {
  padding: 20px 0px;
  width: 220px;

  .inputGroup {
    margin: 15px 20px 15px 0;
  }

  .inputRow {
    align-items: baseline;
    display: flex;
    gap: 3px;
  }

  .inputClassName {
    padding-top: 10px;
  }

  .radiusSelectContainer {
    width: 100%;
  }

  .radiusSelect {
    background: white !important;
    font-size: 14px !important;
    height: 38px !important;
    width: 100%;
  }

  .optionsContainer {
    right: 0;
    top: 38px;
  }

  .keywordContainer {
    min-width: unset !important;

    input {
      margin-left: 0 !important;
      width: 100% !important;
    }
  }

  .tagClassName {
    background: var(--gray-01, rgb(247, 247, 247));
    border-radius: 0;
    height: 30px;
    width: 120px;

    & > div {
      color: var(--gray-03, rgb(155, 161, 167));
      top: unset;

      &:hover {
        color: var(--gray-05, rgb(60, 63, 70));
      }
    }
  }

  .noMargin {
    margin-left: 0;
  }

  .distancePlaceholder {
    color: var(--gray-03, rgb(155, 161, 167));
    font-size: 12px;
    font-style: oblique;
    font-weight: 600;
  }
}
