.notesBoxContainer {
  background: aliceblue;
  border: 3px solid lightgray;
  padding: 10px;
  position: fixed;
  right: 15vw;
  top: 90px;
  width: 500px;
  z-index: 200;

  .notesBoxHeader {
    align-items: center;

    display: flex;
    height: 50px;
    justify-content: center;

    position: relative;

    .notesButton {
      align-items: center;

      background: #fff;
      border-radius: 50%;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
      color: var(--gray-05, rgb(60, 63, 70));
      cursor: pointer;

      display: flex;
      font-size: 16px;

      height: 50px;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 50px;
    }
  }

  .notesArea {

    height: 60vh;
    margin-top: 20px;

    .editorWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;

      .titleInput {
        width: 100% !important;
      }

      .richTextEditor {
        flex: 1;
        height: 100%;
        overflow: auto;

        .myInputRichTextContainer {
          /*  height: 100%; */

          > div:nth-child(1)
            > div:nth-child(2)
            > div:nth-child(1)
            > div:nth-child(1)
            > div:nth-child(1) {
            min-height: 300px;
          }
        }
      }

      & > button {
        align-self: flex-end;
        height: 30px;
      }
    }
  }
}

.height100 {
  height: 100%;
}

@media (max-width: 600px) {
  .notesBoxContainer {
    display: none;
  }
}
