.WelcomePage {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px;
}

.welcome {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-height: 725px;
}

@media only screen and (max-width: 768px) {
  .sidebarClass {
    display: none !important;
  }

  .welcome {
    min-height: 0px;
  }
}
