@import '@csstools/normalize.css';

.Main {
  display: flex;
  height: 100vh;
  min-height: -webkit-fill-available;
  min-width: -webkit-fill-available;
}

.Main.fullHeight {
  height: unset;
}

.whiteCanvas {
  background-color: #fff !important;
}

.blackCanvas {
  background-color: var(--gray-05, rgb(60, 63, 70)) !important;
}

.overflowScrolling {
  -webkit-overflow-scrolling: touch;
}

.canvasFooter {
  width: 100%;
}

.canvasContent {
  align-items: center;
  background-color: var(--gray-02, rgb(247, 247, 247));
  display: flex;
  flex: 1;
  flex-direction: column;
  /* height: calc(100vh - 48px); */
  /* max-height: calc(100vh - 48px); */
  margin-top: 48px;
  position: relative;
  width: 100%;
}

.canvasContentPrint {
  align-items: flex-start;
  margin-top: 0;
}

.canvasContentApplyNow {
  margin-top: 0;
}

.unsupportedContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 15%;
}

.unsupported {
  width: 50%;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}
