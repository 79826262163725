.Extras {
  margin-top: 32px;
  > ul {
    font-size: 14px;
    margin-left: 8px;
    margin-top: 8px;
  }
}

.ExtrasContent {
  margin-top: 16px;
}

.pressLink {
  color: var(--seafoam-02, rgb(0, 191, 186));
  max-width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pressLink a {
  color: var(--seafoam-02, rgb(0, 191, 186));
  &:visited {
    color: var(--seafoam-02, rgb(0, 191, 186));
  }
  &:hover {
    color: var(--seafoam-01, rgb(12, 218, 212));
  }
}
