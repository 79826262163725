.candidateContent {
  color: inherit;
  width: 210px;
}

.dashboardTitleContainer {
  padding-left: 16px;
}

.candidateHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  margin-right: 16px;
  padding: 8px 0px;
}

.candidateHeaderLeft {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.nameHeader {
  padding-left: 60px;
  width: 220px;
}

.candActionHeader {
  width: 110px;
}

.lastActivityContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  width: 100px;
}

.stateContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  width: 100px;
}

.lastActivityHeader {
  display: flex;
  justify-content: center;
  width: 110px;
}

.okay {
  color: var(--green-01, #5cab06);
}

.warning {
  color: var(--red-01, rgb(255, 0, 64));
  font-weight: 600;
}

.notice {
  color: var(--orange-01, rgb(255, 167, 0));
  font-weight: 600;
}

.iconDanger {
  align-items: center;
  background-color: var(--red-01, rgb(255, 0, 64));
  border-radius: 18px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 18px;
  justify-content: center;
  margin-bottom: 2px;
  margin-left: 8px;
  position: relative;
  user-select: none;
  width: 18px;
  &:hover,
  &:active {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  }
}

.iconActive {
  align-items: center;
  background-color: var(--red-01, rgb(255, 0, 64));
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 17px;
  justify-content: center;
  margin-bottom: 2px;
  margin-left: 8px;
  position: relative;
  user-select: none;
  width: 17px;
}

.reasonsModal {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  color: var(--gray-05, rgb(60, 63, 70));
  left: 30px;
  min-width: max-content;
  padding: 8px;
  position: absolute;
  top: -8px;
}

.messagesCount {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
}
