.notesButton {
  height: 6vw;
  width: 6vw;
  min-height: 32px;
  min-width: 32px;
  max-height: 64px;
  max-width: 64px;
  margin-bottom: 16px;
  transition: background 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;

  background: #fff;
  font-size: 16px;
  border-radius: 30px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  transition: background 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:hover {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &_label {
    font-size: min(1.2vw, 12px);
  }
}

@media (max-width: 600px) {
  .notesButton {
    display: none;
  }
}
