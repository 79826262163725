.profileEducationContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  > div {
    padding-bottom: 32px;
  }
  > div:last-child {
    padding-bottom: 0px;
  }
}

.emptyContainer {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
  min-height: 120px;
  width: 100%;
}

.marginBottom {
  margin-bottom: 48px;
}

@media print {
  .educationTitle {
    break-after: avoid;
  }
}
