.candidateHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  margin-right: 16px;
  padding: 8px 0px;
}

.candidateHeaderLeft {
  align-items: baseline;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.locationsCell {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  max-height: 64px;
  overflow: auto;
  width: 127px;
}

.location {
  min-height: 16px;
  overflow: hidden;
  padding-bottom: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.oteRangeCell {
  align-content: center;
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  justify-content: flex-start;
  width: 90px;
}

.oteMax {
  padding-left: 3px;
}
.oteMin {
  padding-right: 3px;
}

.oteRange {
  align-items: center;
  display: flex;
}

.reqName {
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin-left: 16px;
  transition: color 0.07s linear;
  width: 175px;
}

.regularReqCell {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  width: 80px;
}

.candidateReqCell {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  width: 62px;
}

.actionsCell {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}
.statusReqCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 110px;
}

.headerStatus {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 3px;
}

.subStatus {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  padding-bottom: 3px;
}

.fullStatus {
  color: var(--red-01, rgb(255, 0, 64));
  font-weight: 600;
}

.pendingStatus {
  color: var(--gray-05, rgb(60, 63, 70));
  font-weight: 600;
}

.openStatus {
  color: var(--green-01, #5cab06);
  font-weight: 600;
}

.reqStatusHeader {
  width: 120px;
}

.reqLocationHeader {
  width: 137px;
}

.reqOteHeader {
  width: 100px;
}

.reqCandidatesHeader {
  width: 72px;
}

.reqPublishedHeader {
  width: 90px;
}

.reqCreatorHeader {
  width: 90px;
}

.actionHeader {
  width: 110px;
}

.firstLabel {
  margin-left: 16px;
  width: 185px;
}

.emptyContainerContent {
  display: flex;
  justify-content: center;
  padding-top: 15%;
}
