.Label {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  position: relative;
}

.required::after {
  color: var(--red-01, rgb(255, 0, 64));
  content: '*';
  margin-left: 4px;
  position: relative;
}

.standalone {
  margin-bottom: 16px;
}

.disabled {
  color: var(--gray-03, rgb(155, 161, 167));
}
