.Form {
  width: 100%;
  > button:last-child {
    margin: 0 auto;
    margin-bottom: 48px;
    width: 192px;
  }
}

.formRow {
  display: flex;
  margin-top: 24px;
  width: 100%;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 12px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-left: 24px;
  }
}

.salesExperience {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  padding-top: 16px;
}

@media only screen and (max-width: 768px) {
  .Form {
    min-width: 390px;
    padding: 0px 16px;
    padding-bottom: 32px;
    width: 100%;
  }

  .formRow {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    > div:nth-child(1) {
      flex: 1;
      margin-right: 0px;
    }
    > div:nth-child(2) {
      flex: 1;
      margin-left: 0px;
    }
  }
}
