.Disclaimer {
  color: #3c3f46;
  font-size: 14px;
  margin: 32px 0;
}

.inlineLink a {
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-weight: 600;
  &:visited {
    color: var(--seafoam-02, rgb(0, 191, 186));
  }
  &:hover {
    color: var(--seafoam-01, rgb(12, 218, 212));
  }
}
