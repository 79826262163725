.ActionDropdown {
  width: 100%;
}

.promptModal {
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 325px;
  padding: 16px;
}

.promptContent {
  font-size: 18px;
  padding-bottom: 16px;
  padding-top: 16px;
}

.promptButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.button {
  height: 32px;
  justify-content: center !important;
  min-width: 100px;
  width: 100px;
}

.confirm {
  height: 32px;
  margin-left: 8px;
}

.candidatePassedModal {
  min-width: 550px !important;
  top: unset !important;
}

.input {
  margin-bottom: 32px;
  > div > div > div {
    flex: 1;
  }
}

.subtitle {
  font-size: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.mainTitle {
  font-size: 24px;
  margin-bottom: 32px;
  text-align: center;
}

.passedOnDate {
  margin-top: 12px;
}
