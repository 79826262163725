.EditorSkills {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.skillsBlock {
  margin-bottom: 8px;
}

.candReqBlock {
  margin-bottom: 8px;
}

.qAndABlock {
  margin-bottom: 8px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.basicInfoContainer {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  padding-bottom: 32px;
  padding-top: 24px;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.aggroQuestionContainer {
  width: 70%;
}

.aggroAnswerContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.checkboxContainer {
  padding-top: 24px;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}
