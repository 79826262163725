.Input {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.InputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 24px;
  &.isSearch {
    padding-top: 0px;
  }
}

.inputStyle {
  border: 1px solid rgba(255, 255, 255, 0);
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 24px;
  font-weight: 600;
  height: 40px;
  padding-left: 12px;
  transition: border 0.2s linear, color 0.2s, opacity 0.2s;
  width: 100%;
}

.inputStyle:placeholder-shown {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 24px;
}

.ultraDisabledInput {
  color: var(--gray-03, rgb(155, 161, 167));
  opacity: 0.8;
}
.ultraDisabledLabel {
  color: var(--gray-03, rgb(155, 161, 167));
  opacity: 0.8;
}

.hasError {
  border: 1px solid var(--red-01, rgb(255, 0, 64));
}

.hasError:focus {
  outline: var(--red-01, rgb(255, 0, 64)) auto 3px;
}

.hasErrorTitle {
  color: var(--red-01, rgb(255, 0, 64));
}

.inputErrors {
  margin: 0px 0px 4px 0px;
  max-height: 12px;
  padding-left: 0px !important;
}

.flexColumn {
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
}

.titleLabel {
  align-items: center;
  height: 32px;
  justify-content: flex-start;
  margin-left: 0px;
}

.regularLabel {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
