.content {
  flex: 1;
  max-width: 100%;
  overflow-x: auto;
  position: relative;
}

.contentPrint {
  flex: 1;
  max-width: 100%;
  position: relative;
}

.contentModal {
  position: relative;
}
