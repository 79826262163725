.Footer {
  background: #fff;
  display: flex;
  justify-content: center;
}

.Container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  max-width: 1132px;
  padding: 0px 24px;
  position: relative;
  width: 100%;
  a {
    color: #9ba1a7;
    display: block;
    font-size: 14px;
    margin: 8px 0;
    &:visited {
      color: #9ba1a7;
    }
  }
  a:hover {
    color: #00c0ba;
    cursor: pointer;
  }
}

.Image {
  opacity: 0.5;
  width: 156px;
}

.sovrenLogo {
  opacity: 0.4;
  width: 64px;
}

.Column {
  display: inline-block;
  flex: 1;
  float: left;
  line-height: 1.5;
  margin-bottom: 20px;
  min-width: 180px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
}

.SecondaryTitle {
  color: #9ba1a7;
  font-size: 14px;
  text-transform: uppercase;
}

.Legal {
  align-items: center;
  border-top: 1px solid #ebebeb;
  color: #9ba1a7;
  display: flex;
  font-size: 12px;
  height: 64px;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}

.leftlegal {
  align-items: center;
  display: flex;
  height: 64px;
}

.rightLegal {
  align-items: center;
  display: flex;
  height: 64px;
}

.rightLegal > img {
  margin-left: 5px;
}

@media only screen and (max-width: 768px) {
  .Column {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 100%;
  }
}
