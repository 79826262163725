.profileHeaderContainer {
  align-items: center;
  background-color: #fff;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 212px;
  min-width: 212px;
  position: absolute;
  right: 24px;
  width: 212px;
}

.profileStatus {
  align-items: center;
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  border-top: 1px solid var(--gray-hairline, #dbdbdb);
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  margin-bottom: 16px;
  padding: 16px 0px;
  width: 100%;
}

.statusIcon {
  font-size: 16px;
  margin-right: 8px;
}

.socialMediaButton {
  align-items: center;
  display: flex;
}

.profileSocialButton {
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  font-weight: 500;
  height: 32px;
}
.profileResumeButton {
  background-color: #fff;
  border: 1px solid var(--gray-025, #d1d3d4);
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  margin-right: 12px;
  padding: 6px 16px;
}

.profileCopyLinkContainer {
  width: 100%;
}

.adminCopyLinkContainer {
  flex-direction: row;
  > button:nth-child(1) {
    border-radius: 2px 0px 0px 2px;
    flex: 3;
    min-width: max-content;
  }
  > button:nth-child(2) {
    border-left: none;
    border-radius: 0px 2px 2px 0px;
    flex: 1;
  }
}

.linkNotCopied {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.linkCopied {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.linkCopiedText {
  color: var(--gray-05, rgb(60, 63, 70)) !important;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  margin: 0 auto;
  padding: 0px 12px;
  transition: all 0.2s linear;
  user-select: none;
}

.linkCopiedTextActive {
  color: var(--gray-05, rgb(60, 63, 70)) !important;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  padding: 0px 12px;
}

.copyLinkIcon {
  font-size: 24px;
  margin-right: 8px;
}

.secureCopyLinkIcon {
  font-size: 24px;
}

.copiedLinkIcon {
  color: var(--seafoam-01, rgb(21, 218, 212));
  font-size: 16px;
  margin-right: 8px;
}

.seafoam {
  color: var(--seafoam-01, rgb(21, 218, 212));
  font-size: 16px;
  margin-bottom: 1px;
  margin-right: 8px;
}

.contactedNote {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  margin-bottom: 8px;
}

.linksContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fakeContact {
  height: 40px;
  margin-bottom: 8px;
  max-height: 40px;
}

.hidden {
  position: absolute;
  right: 10000px;
}

.showLink {
  border: 1px dotted rgb(60, 63, 70);
  border-radius: 2px;
  font-size: 12px;
  max-width: 256px;
  padding: 8px;
  word-wrap: break-word;
}

.showLinkError {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  margin: 8px 0px;
  max-width: 256px;
}

.passButton {
  background: #fff;
  border: 1px solid var(--red-01, rgb(255, 0, 64));
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;
}

.passedButton {
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .profileHeaderContainer {
    max-width: unset;
    min-width: 0px;
    padding: 8px;
    position: relative;
    right: 0px;
    width: 100%;
  }
  .linksContainer {
    flex-direction: row;
    justify-content: space-around;
  }
}
