.EditorEmpty {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.emptyTitleContainer {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 12px;
}

.title {
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.emptyTitle {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  display: flex;
  height: 24px;
  justify-content: space-between;
  width: 150px;
}

.previewButton {
  background-color: var(--gray-01, rgb(247, 247, 247));
  height: 32px;
  width: 100px;
}

.emptyResumeDetails {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 115px;
  justify-content: center;
  margin: 0 25%;
  text-align: center;
}

.resumeUploadButton {
  margin-top: 32px;
}

.uploadImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 128px;
}

.uploadImageContainer > img {
  border-radius: 64px;
  width: 128px;
}

.candidateImagePlaceholder {
  background-color: var(--gray-01, rgb(247, 247, 247));
  border-radius: 64px;
  height: 128px;
  width: 128px;
}

.candidateImageInput {
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-size: 14px;
  font-weight: 600;
}

.emptyGenInfoInputs {
  display: flex;
  height: 500px;
  justify-content: flex-start;
  > div {
    flex: 1;
    margin-right: 20px;
    max-width: 250px;
    min-width: 150px;
  }
}
