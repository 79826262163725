.CandidateEditorVideo {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw;
}

.EditorIntro {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 0px;
}

.title {
  font-weight: 600;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.mainBlock {
  margin: 8px;
  margin-bottom: 0px;
  > div:nth-child(1) {
    > div {
      > iframe {
        max-height: 462px;
        width: 100%;
      }
    }
  }
}

.instructionsBlock {
  margin-top: 16px;
}

.delete {
  margin-left: auto;
  > i {
    margin-right: 5px;
  }
}

.modal {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
}

.modalContent {
  font-size: 14px;
  margin-bottom: 16px;
  width: 85%;
}

.modalButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.instructionsHead {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
}

.emptyVideo {
  height: 462px;
}

.saveContent {
  display: flex;
}

.profileLocked {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}
