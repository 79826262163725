.addCandTitle {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0px 16px;
}

.candidateContent {
  min-width: 230px;
  width: 230px;
}

.statusContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  min-width: 150px;
  text-transform: capitalize;
}

.statusContainer {
  .lifecycleStatus {
    color: var(--gray-03, rgb(155, 161, 167));
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
  }
}

.greenActive {
  color: var(--green-01, #5cab06);
}

.removeCandContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  font-size: 28px;
  transition: color 0.2s linear;
  &:hover {
    color: var(--red-01, rgb(255, 0, 64));
  }
}

.content {
  display: flex;
  flex: 1;
  margin: 8px;
  max-height: calc(100% - 78px);
}
