.candidateOpportunities {
  height: 100%;
  padding: 8px;
  > div:nth-child(1) {
    padding-bottom: 8px;
    padding-left: 16px;
  }
  > div:nth-child(2) {
    height: calc(100% - 56px);
  }
}
