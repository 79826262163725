.secondaryContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 48px;
  width: 100%;
}

.containerTitle {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
  width: 100%;
}

.containerTitleRole {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  font-size: 14px;
  font-weight: 700;
  margin-top: 48px;
  padding-bottom: 8px;
  width: 100%;
}

.oteInputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0;
}

.formRow {
  display: flex;
  width: 100%;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 12px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-left: 12px;
  }
  > label:nth-child(1) {
    flex: 1;
    margin-right: 12px;
  }
  > label:nth-child(2) {
    flex: 1;
    margin-left: 12px;
  }
}

.checkbox {
  margin-top: 16px;
  > label {
    align-items: center;
    > span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.labelWorkModel {
  display: block;
  margin-bottom: 16px;
}

.workModelContainer {
  margin-top: 24px;
  max-width: 507px;
  width: 100%;
}

.searchBarFilter {
  max-width: 507px;
  width: 100%;
}

.regionsContainer {
  width: 100%;
}
