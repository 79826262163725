.InfiniteScrollLoader {
  align-items: center;
  background: rgba(255, 0, 0, 0);
  display: flex;
  font-weight: 600;
  height: 64px;
  justify-content: center;
  width: 100%;
}

.smallTable {
  align-items: flex-start;
  font-size: 12px;
  height: 48px;
}

@keyframes pulse {
  50% {
    background: var(--seafoam-01, rgb(21, 218, 212));
  }
}

.loadingPulse {
  animation: pulse 1s infinite;
  animation-delay: 0.3s;
  background: rgba(255, 0, 0, 0);
  border-radius: 2px;
  height: 24px;
  position: relative;
  transition: background 0.1s ease-in;
  width: 4px;

  &:before,
  &:after {
    animation: pulse 1s infinite;
    background: #fff;
    border-radius: 2px;
    content: '';
    display: block;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background 0.3s ease-in;
    width: 4px;
  }
  &:before {
    left: -12px;
  }
  &:after {
    animation-delay: 0.6s;
    left: 12px;
  }
}
