.candidateContent {
  color: inherit;
  width: 210px;
}

.dashboardTitleContainer {
  padding-left: 16px;
}

.candidateHeader {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  padding: 8px 0px;
  padding-right: 8px;
  width: 100%;
}

.candidateHeaderLeft {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.candidateHeaderBold {
  font-size: 14px;
  font-weight: 600;
}

.candidateHeaderText {
  font-weight: 500;
  margin-right: 5px;
}

.candidateHeaderTextIn {
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.nameHeader {
  padding-left: 60px;
  width: 220px;
}

.candActionHeader {
  width: 110px;
}

.lastActivityContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  width: 100px;
}

.lastActivityHeader {
  display: flex;
  justify-content: center;
  width: 110px;
}

.okay {
  color: var(--green-01, #5cab06);
}

.warning {
  color: var(--red-01, rgb(255, 0, 64));
  font-weight: 600;
}

.notice {
  color: var(--orange-01, rgb(255, 167, 0));
  font-weight: 600;
}
