.DialogueModal {
  display: flex;

  left: 50%;
  max-width: 448px;

  opacity: 0;
  position: absolute;
  top: 20%;
  transform: translateX(-50%);
  width: 100%;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms;
}

.enterDone {
  opacity: 1;
}
