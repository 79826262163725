.award {
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 2px 4px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  font-size: 14px;
  height: 40px;
  margin-bottom: 16px;
  margin-right: 16px;
  min-width: max-content;
  padding: 0px 16px;
  width: 176px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}

.marginBottom {
  margin-top: 24px;
}

.showMoreIcon {
  margin-left: 8px;
}
