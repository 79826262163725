.main {
  display: flex;
  flex-wrap: wrap;
}

.right {
  flex: 2;
  > div:nth-child(1) {
    margin-top: 0px;
    > label {
      margin-bottom: 8px;
    }
  }
  > div:nth-child(2) {
    margin: 8px 0px;
  }
}

.left {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 24px;
}

.block {
  flex: 1;
  margin: 8px;
}

.templates {
  color: var(--gray-03, rgb(155, 161, 167));
  height: 100%;
  margin: 8px 0px;
  max-height: 250px;
  overflow-y: scroll;
  padding-right: 10px;
  width: calc(100% + 10px);
}

.saveButton {
  height: 32px;
  margin-top: auto;
  min-width: 80px;
}

.savedButton {
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: default;
  height: 32px;
  margin-top: auto;
  min-width: 80px;
}

.savedButton:hover {
  color: var(--gray-03, rgb(155, 161, 167));
}

.delete {
  align-items: center;
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 20px;
  transition: all 0.1s linear;
}

.delete:hover {
  color: var(--red-01, rgb(255, 0, 64));
}

.edit {
  align-items: center;
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: auto;
  min-width: 20px;
  transition: all 0.1s linear;
}

.edit:hover {
  color: var(--gray-05, rgb(60, 63, 70));
}

.template {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-right: 8px;
  > button {
    height: unset;
    justify-content: flex-start;
    min-height: max-content;
    padding: 10px 16px;
    text-align: left;
    transition: none;
  }
}

.selectedTemplateContainer {
  align-items: center;
  background-color: var(--gray-01, #f7f7f7);
  display: flex;
  justify-content: flex-start;
  padding-right: 8px;
  > button {
    height: unset;
    justify-content: flex-start;
    min-height: max-content;
    padding: 10px 16px;
    text-align: left;
    transition: none;
  }
}

.selectedTemplate {
  background-color: var(--gray-01, #f7f7f7);
  color: var(--gray-05, rgb(60, 63, 70));
  font-weight: 700;
}

.empty {
  margin-top: 32px;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
