.EditorStats {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.title {
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.statsColumn {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.statsHeader {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  height: 32px;
  justify-content: center;
}

.statsContainer {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.statsContent {
  display: flex;
  height: 140px;
  width: 100%;
}

.stat {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.statSubtext {
  color: var(--gray-025, #d1d3d4);
  font-size: 12px;
  text-align: center;
}

.tableHeader {
  background-color: var(--gray-01, rgb(247, 247, 247));
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 56px;
  padding-left: 16px;
  padding-right: 6px;
}

.companyHeader {
  align-items: center;
  display: flex;
  margin-right: 10px;
  width: 170px;
}

.otherHeader {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-right: 10px;
}

.contentTop {
  margin-top: 2px;
}

.content {
  margin-top: 8px;
}
