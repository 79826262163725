.LoginContainer {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  justify-content: flex-start;
  margin: 8px;
}

.block {
  flex: 1;
  width: 100%;
}
