.HiringForm {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
}

.form {
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1), 0 15px 15px 0 rgba(0, 0, 0, 0.12),
    0 5px 20px 0 rgba(0, 0, 0, 0.08);
  width: 893px;
  z-index: 0;
}

.header {
  align-items: center;
  background-color: var(--gray-05, rgb(60, 63, 70));
  color-adjust: exact;
  display: flex;
  height: 48px;
  padding-left: 48px;
  -webkit-print-color-adjust: exact;
  > img {
    height: 24px;
  }
}

.title {
  padding: 0px 48px;
}

.titleHeader {
  font-size: 1.5em;
}

.titleContentOne {
  font-size: 18px;
  margin: 0px 24px;
  max-width: 700px;
  text-align: center;
}

.titleContent {
  font-size: 18px;
}

.footer {
  align-items: center;
  background-color: var(--gray-05, rgb(60, 63, 70));
  color: #fff;
  color-adjust: exact;
  display: flex;
  height: 72px;
  padding: 0 48px;
  -webkit-print-color-adjust: exact;
  text-align: center;
}

.mainForm {
  background: #f7f7f7;
  display: flex;
  margin-top: 48px;
  padding: 0px 48px;
  position: relative;
}

.mainForm::before {
  background: #f7f7f7;
  content: '';
  height: 300px;
  left: 0;
  position: absolute;
  top: -25px;
  transform: skewY(-3deg);
  transform-origin: center;
  width: 100%;
  z-index: -1;
}

.leftColumn {
  flex: 1;

  > div {
    margin-bottom: 16px;
  }
}

.rightColumn {
  flex: 1;
  margin-left: 16px;
  textarea {
    overflow-y: scroll;
    resize: none;
  }
  div {
    display: block;
  }

  > div {
    margin-bottom: 16px;
  }
}

.rangeInputsContainer {
  align-items: flex-end;
  display: flex;
  div {
    display: block;
  }
  i {
    top: 1px;
  }
  > div {
    flex: 1;
    margin-right: 16px;
  }
}

.topHeader {
  color: var(--seafoam-02, rgb(0, 191, 186));
  margin-top: 48px;
}

.printButton {
  height: 32px;
  margin: 16px 0px;
  max-height: 32px;
  min-height: 32px;
}

.headerOne {
  margin-bottom: 8px;
  margin-top: 24px;
}

.formContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerContainer {
  margin-top: 64px;
}

.label {
  margin-bottom: 8px;
  margin-top: 32px;
}

@media print {
  @page {
    margin: 0px auto;
  }
  .form {
    box-shadow: none;
    color-adjust: exact;
    height: 9in;
    margin-top: 20px;
    max-height: 9in;
    max-width: 8in;
    -webkit-print-color-adjust: exact;
    width: 8in;
  }
}
