.EditorEmpty {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  width: var(--max-width-content, 952px);
}

.titleBlock {
  margin: 8px;
}
