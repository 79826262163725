.unverifiedContentContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 200px;

  .imageLinkContainer {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    .unverifiedImage {
      width: 50%;
    }

    .linkIcon {
      font-size: 24px;
      margin-right: 8px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    button {
      height: 30px;
      min-height: 0;
      min-width: 0;
      padding: 10px;
    }
  }
}
