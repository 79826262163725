.container {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  max-width: var(--max-width-total, 1152px);
  padding-top: 48px;
  width: 100vw;
}

.imageWrapper > img {
  max-height: 40vh;
  max-width: 100vw;
}

.errorText {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 24px;
  font-weight: 600;
  margin-top: 48px;
}

.subtext {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin-top: 32px;
}

.mailLink {
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-size: 14px;
  font-weight: 500;
  &:visited {
    color: var(--seafoam-02, rgb(0, 191, 186));
    font-size: 14px;
  }
  &:focus {
    color: var(--seafoam-02, rgb(0, 191, 186));
    font-size: 14px;
  }
  &:hover {
    color: var(--seafoam-01, rgb(12, 218, 212));
    font-size: 14px;
  }
}
