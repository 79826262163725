.SearchBar {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 500;
  > div:nth-child(1) {
    border-bottom: 1px solid #d1d3d4;
    margin-right: auto;
  }
}

.SearchBarRow {
  display: flex;
  flex-wrap: wrap;
  > div:last-child {
    margin-right: 0px !important;
  }
}

.searchHeaderText {
  margin-right: 8px;
}

.searchHeaderBold {
  font-size: 14px;
  font-weight: 600;
}

.searchBarFilter {
  font-size: 14px;
  height: 32px;
  margin: 8px 0px;
  margin-right: 16px;
  min-width: 146px;
}

/* RESPONSIVE */
@media only screen and (max-width: 768px) {
  .SearchBar {
    display: flex;
    flex-direction: column;
    min-width: 184px;
  }
}
