.SearchChips {
  display: flex;
  flex-wrap: wrap;
  margin-left: 12px;
  min-height: 64px;
  padding-top: 16px;
  > div {
    margin-bottom: 10px;
  }
}

.chipsContainer {
  align-items: baseline;
  display: flex;
}

.chipsHolder {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  & div {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.chipsLabel {
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  text-align: right;
  width: 75px;
}
