.container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-height: 1000px;
}

.optionsContainer {
  background-color: #fff;
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-height: 0;
  max-width: 0;
  overflow: hidden;
  padding: 0;
}

.optionsContainer[aria-expanded='true'] {
  max-height: 1000px;
  max-width: 1000px;
  padding: 10px 0 0 10px;
  transition: max-height 1s ease-in;
}

.option {
  color: inherit;
  padding: 5px;
}

.option:hover {
  color: var(--gray-05, rgb(60, 63, 70));
}

.title {
  max-width: 118px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
