@import '../../vars.scss';

.ProfessionalSummaryContainer {
  .professionalSummaryData {
    display: grid;
    gap: 0 20px;
    grid-template-columns: 30px auto 120px;

    img {
      border-radius: $iconBorderRadius;
      width: $iconSize;
    }

    .companyDelimiter {
      margin-bottom: 20px;
    }
  }

  .centered {
    align-items: center;
    display: flex;
  }
}

@media (max-width: 430px) {
  .ProfessionalSummaryContainer {
    .professionalSummaryData {
      grid-template-columns: 30px auto auto;
    }
  }
}
