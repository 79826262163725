.container {
  .fieldTitle {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 10px;

    span {
      color: red;
    }
  }

  .applyHeader {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
  }

  .applyHeaderEmployer {
    font-size: 2em;
    font-weight: 800;
    margin-bottom: 20px;
  }

  .applySubheader {
    color: #8c8e92;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1.3em;
    margin-bottom: 10px;
  }

  .titleWithError {
    height: 2.1em;
    position: relative;
  }

  .fieldInput {
    border: 1px solid #d1d3d5;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1.2em;
    padding: 11px 10px;
    width: 100%;
  }

  .fieldInput:focus {
    border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  }

  .fieldInput.hasError {
    border: 1px solid var(--red-01, rgb(255, 0, 64));
  }

  .fieldInput.hasError:focus {
    outline: var(--red-01, rgb(255, 0, 64)) auto 3px;
  }

  .errorMessage {
    bottom: 0;
    color: red;
    display: block;
    font-size: 0.7em;
    left: 0;
    margin-bottom: 3px;
    position: absolute;
  }

  .submitBtn {
    background: rgb(21, 218, 212);
    border: none;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1.1em;
    margin: 0 auto 30px auto;
    outline: none;
    padding: 8px 60px;
  }

  .submitBtn:disabled {
    background: rgb(247, 247, 247);
    color: rgb(155, 161, 167);
  }

  .disclaimer {
    line-height: 1.5;
    text-align: justify;
  }

  .oneLineName {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1090px) {
  .container {
    .oneLineName {
      display: block;
    }
  }
}
