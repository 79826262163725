.BecomeRainmaker {
  align-items: center;
  background: url(../../assets/sf-bridge.jpg) top center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 900px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
}
.BecomeRainmaker::before {
  background: #fff;
  content: '';
  height: 300px;
  left: 0;
  position: absolute;
  top: -123px;
  transform: skewY(8deg);
  transform-origin: center;
  width: 100%;
  z-index: -1;
}
.BecomeRainmaker::after {
  background: #fff;
  content: '';
  height: 900px;
  left: 0;
  position: absolute;
  top: 690px;
  transform: skewY(8deg);
  transform-origin: center;
  width: 100%;
  z-index: -1;
}

.Title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 0px;
  text-align: center;
}

.Description {
  font-size: 16px;
  line-height: 1.75;
  width: 33%;
}

.Request {
  background-color: #15dad5;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0 0;
  min-width: 92px;
  padding: 12px 24px;
  width: auto;
  &:hover,
  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  }
}

.row4 {
  font-size: 13px;
  margin: 30px 0 10px;
}

.row5 {
  color: #00c0ba;
}

@media only screen and (max-width: 768px) {
  .BecomeRainmaker {
    background: #fafafa;
  }
  .Description {
    padding: 0 16px;
    width: 100%;
  }
}
