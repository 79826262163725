.Profile {
  flex: 1;
  padding: 8px;
  > div {
    border-radius: 0px !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1) !important;
    > div {
      border-radius: 0px !important;
    }
  }
}

.viewReq {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.editReq {
  flex: 1;
  padding: 8px 0px;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.title {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.titleBlock {
  margin: 0 8px;
  margin-bottom: 6px;
}

.scrollDiv {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding-top: 2px;
}
