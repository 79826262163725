.AboutUs {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
  > div:nth-child(1) {
    margin: 0 auto;
    margin-bottom: 128px;
    max-width: 744px;
    width: 100%;
  }
  > div:nth-child(2) {
    margin: 0 auto;
    max-width: 1132px;
    width: 100%;
  }
}

.headerTwo {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  font-size: 14px;
  margin-bottom: 24px;
  padding-bottom: 8px;
  text-transform: uppercase;
}

.Content {
  margin-bottom: 48px;
  max-width: 648px;
}

.rainmaker {
  align-items: center;
  display: flex;
  margin-bottom: 48px;
}

.founderLogo {
  align-items: center;
  border-radius: 64px;
  display: flex;
  height: 128px;
  justify-content: center;
  min-height: 128px;
  min-width: 128px;
  overflow: hidden;
  position: relative;
  width: 128px;
}

.founderLogo > img {
  height: 128px;
  object-fit: cover;
  width: 128px;
}

.founderLogoVlad {
  align-items: center;
  border-radius: 64px;
  display: flex;
  height: 128px;
  justify-content: center;
  min-height: 128px;
  min-width: 128px;
  overflow: hidden;
  position: relative;
  width: 128px;
}

.founderLogoVlad > img {
  height: 128px;
  object-fit: cover;
  position: relative;
  right: 20px;
}

.founderLogoMikeT {
  align-items: center;
  border-radius: 64px;
  display: flex;
  height: 128px;
  justify-content: center;
  min-height: 128px;
  min-width: 128px;
  overflow: hidden;
  position: relative;
  width: 128px;
}

.founderLogoMikeT > img {
  object-fit: cover;
  position: relative;
  top: 28px;
  width: 128px;
}

.founderDetail {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  min-height: 100px;
}

.founderName {
  font-size: 18px;
  margin-bottom: 16px;
}

.founderDesc {
  max-width: 592px;
}
