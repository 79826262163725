.EditorStats {
  display: flex;
  flex-direction: column;
  height: 100vh;
  > div {
    margin: 8px;
    margin-top: 0px;
  }
  > div:nth-child(1) {
    margin-top: 8px;
  }
  > div:nth-child(3) {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 0px;
  }
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.statsColumn {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.statsHeader {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  height: 32px;
  justify-content: center;
}

.statsContainer {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.statsContent {
  display: flex;
  height: 140px;
  width: 100%;
}

.stat {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.statSubtext {
  color: var(--gray-025, #d1d3d4);
  font-size: 12px;
  text-align: center;
}

.content {
  margin-top: 8px;
}
