.Timer {
  color: #fff;
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.panic {
  color: var(--red-01, rgb(255, 0, 64));
}

.recording {
  &:before {
    animation: blinking 1.25s infinite;
    background-color: var(--red-01, rgb(255, 0, 64));
    border-radius: 12px;
    bottom: 12px;
    content: '';
    height: 20px;
    position: relative;
    right: 2px;
    width: 20px;
  }
}
