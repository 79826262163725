.BatchRequestsTable {
  height: 90vh;
  margin-left: 15px;
}

.batchRequestContent {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  width: 200px;
  > div:nth-child(1) {
    margin-right: 11px;
  }
}

.declineContent {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  width: 130px;
  > div:nth-child(1) {
    margin-right: 11px;
  }
}

.batchRequestHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  margin-right: 16px;
  padding: 8px 0px;
}

.candidateHeaderLeft {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-right: 32px;
  > div {
    flex: 1;
    margin-bottom: 4px;
    max-width: 332px;
    min-width: 210px;
  }
}

.batchRequestHeaderBold {
  font-size: 14px;
  font-weight: 600;
}

.batchRequestHeaderText {
  font-weight: 500;
  margin-right: 5px;
}

.dateContent {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  width: 88px;
}

.sendEmailContent {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding-left: 18px;
  width: 88px;
}

.nameHeader {
  min-width: 210px;
  padding-left: 60px;
  width: 210px;
}

.dateHeader {
  min-width: 98px;
}

.declineHeader {
  min-width: 144px;
}
