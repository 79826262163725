.welcomeTop {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid var(--gray-01, rgb(247, 247, 247));
  display: flex;
  flex: 0.5;
  justify-content: center;
  min-height: max-content;
  padding-bottom: 24px;
  width: 100%;
}

.approvedContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 438px;
  width: 438px;
}

.approvedMessage {
  font-size: 14px;
  line-height: 20px;
  margin: 16px 72px 24px 72px;
  text-align: center;
}

.approvedHeader {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}
