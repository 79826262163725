.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: 32px;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
}

.detailContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 24px;
}

.highlighted {
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-weight: 600;
}

.highlightedRed {
  color: var(--red-01, rgb(255, 0, 64));
  font-weight: 600;
}
