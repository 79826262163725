.BlogRoll {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 256px;
  padding-top: 120px;
}

.Container {
  display: flex;
  height: auto;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 1132px;
  overflow: hidden;
  padding: 0px 24px;
  padding-bottom: 24px;
  position: relative;
  width: 100%;
}

.Title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 64px;
  text-align: center;
}

.BlogTitle {
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.Column {
  color: var(--gray-05, rgb(60, 63, 70)) !important;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  transition: 0.2s all linear;
  &:hover {
    color: var(--seafoam-02, rgb(0, 191, 186)) !important;
  }
}

.Image {
  height: auto;
  margin-bottom: -5px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;

  img {
    width: 100%;
  }
}

.StepTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
}

.Description {
  font-size: 20px;
  line-height: 1.75;
  padding: 0 8px;
}

.Underline {
  text-decoration: underline;
}

.SeeMore {
  align-items: center;
  border: 1px solid var(--gray-05, rgb(60, 63, 70));
  border-radius: 24px;
  color: var(--gray-05, rgb(60, 63, 70)) !important;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  transition: 0.2s all linear;
  width: 168px;
  &:hover {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
    color: var(--seafoam-02, rgb(0, 191, 186)) !important;
  }
}

@media only screen and (max-width: 768px) {
  .Container {
    flex-direction: column;
  }
  .Column {
    margin-bottom: 48px;
    max-height: 300px;
    width: 100%;
  }
}
