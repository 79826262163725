.SearchBarOne {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 4px;
    margin-right: 32px;
    max-width: 332px;
    min-width: 210px;
    > div {
      height: min-content;
      min-height: 42px;
    }
  }
}

.searchBarFilter {
  width: 100%;
}
