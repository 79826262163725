.Container {
  display: flex;
  width: 100%;
  min-height: 100vh;

  .marketingContainer {
    flex: 1;
  }

  .applyFormContainer {
    align-items: center;

    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    padding: 50px 0 20px 0;

    position: relative;
    width: 100%;

    .formWrapper {
      /* scale the form */
      font-size: 0.9em;
      max-width: 570px;
      min-width: 390px;
      padding: 20px 90px;
    }

    .logoWrapper {
      padding: 10px;
      text-align: center;

      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%);

      .logo {
        width: 250px;
      }
    }
  }
}

@media only screen and (max-height: 900px) {
  .Container {
    .applyFormContainer {
      display: block;

      .formWrapper {
        margin: 0 auto;
      }

      .logoWrapper {
        position: static;
        transform: unset;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .Container {
    height: 100%; /* remove? */
    min-height: unset; /* remove? */
    flex-direction: column;

    .marketingContainer {
      flex-grow: 0;
    }

    .applyFormContainer {
      flex-grow: 1;

      .logoWrapper {
        position: static;
        transform: none;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .Container {
    background: linear-gradient(#bccdff, #cfedff);
    height: unset;

    .marketingContainer {
      background: white;
      padding: 20px 20px 0 20px;
    }

    .applyFormContainer {
      padding: 10px 0 0 0;

      .formWrapper {
        background: white;
        margin-top: 20px;
        padding: 20px 30px 20px 30px;
      }

      .logoWrapper {
        background: white;
        padding-bottom: 20px;
        width: 100%;
      }
    }
  }
}
