.CandidateResume {
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100%;
}

.CandidateResumeModal {
  height: 100%;
  max-width: var(--max-width-content, 952px);
  overflow-x: auto;
  width: 100%;
}

.viewCandidate {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  height: 100%;
  min-height: 470px;
  position: relative;
}

.profileHeaderContainer {
  align-items: center;
  background-color: #fff;
  border-radius: 0px 4px 0px 0px;
  border-right: 1px solid var(--gray-025, #d1d3d4);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  max-width: 212px;
  padding: 24px 16px 0px 16px;
  width: 212px;
}

.profileImagePlaceholder {
  background-color: var(--gray-01, rgb(247, 247, 247));
  border-radius: 64px;
  height: 128px;
  min-height: 128px;
  min-width: 128px;
  width: 128px;
}

.profileHeaderDetails {
  display: flex;
  flex: 1;
  margin: 24px;
}

.profileNameContainer {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.profileName {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 24px;
  font-weight: 600;
  height: 24px;
  margin-bottom: 8px;
  width: 176px;
}

.profileNameSubtitle {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  display: flex;
  flex-wrap: wrap;
  height: 18px;
  height: 16px;
  margin-bottom: 8px;
  width: 212px;
}

.profileSubtitleDetails {
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  justify-content: flex-end;
}

.profileLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.scrollDiv {
  background-color: #fff;
  border-radius: 4px 0px 0px 0px;
  flex: 1;
  > div:nth-child(1) > div:nth-child(1) {
    border-radius: 4px 0px 0px 0px;
  }
}

.emptyButton {
  background-color: var(--gray-01, rgb(247, 247, 247));
  height: 40px;
  margin-bottom: 8px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .viewCandidate {
    min-width: 0px;
  }
  .scrollDiv {
    background-color: #fff;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    > div:nth-child(1) > div:nth-child(1) {
      border-radius: 4px 0px 0px 0px;
    }
  }
}
