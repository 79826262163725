.Dropdown {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  border: 1px solid var(--gray-025, #d1d3d4);
  border-radius: 2px;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  min-height: 32px;
  padding: 3px 8px;
  position: relative;
  transition: fill 0.2s ease-in, box-shadow 0.2s ease-in, color 0.2s ease-out,
    background-color 0.2s ease-in;
  user-select: none;
}

.Dropdown:hover {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  &.disabled {
    box-shadow: none;
  }
}

.OptionsContainer {
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: default;
  min-width: 100%;
  position: absolute;
  top: 32px;
  z-index: 100;
  &.searchContainer {
    top: 24px;
  }
  &.dropLeft {
    right: 0px;
  }
  &.dropRight {
    left: 0px;
  }
}

.inPlaceDefaultLabel {
  font-size: 12px;
  font-weight: 600;
  padding-right: 8px;
}

.disabled {
  background-color: var(--gray-01, rgb(247, 247, 247));
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: default;
}

.active {
  background-color: var(--gray-05, rgb(60, 63, 70));
  color: #fff;
  .OptionsContainer {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
    z-index: 200;
  }
}

.borderWrapper {
  padding: 0 16px;
}

.borderWrapper:hover {
  background-color: var(--gray-01, rgb(247, 247, 247));
}

.topBorderReq {
  border-top: 1px solid var(--gray-02, rgb(247, 247, 247));
}

.topBorderAction {
  border-top: 1px solid var(--gray-02, rgb(247, 247, 247));
}

.addBottomPadding {
  padding-bottom: 23px;
}

.addTopPadding {
  padding-top: 22px;
}
.largeContainer {
  min-width: 180px;
}
.mediumContainer {
  min-width: 150px;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
  padding: 0px 16px 16px 16px;
}
