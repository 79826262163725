.avatarSectionContainer {
  background: #f2632f;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 400px;
  padding: 16px;

  .avatarImageBlock {
    display: flex;
    justify-content: center;
    position: relative;

    .avatarImage {
      background: gray;
      border-radius: 50%;
      height: 196px;
      width: 196px;
    }

    .socialLabels {
      align-items: center;
      background: white;
      border-radius: 32px;
      bottom: -14px;

      display: flex;
      height: 32px;
      justify-content: center;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 80px;

      & > div {
        display: flex;
        gap: 16px;

        a {
          align-items: center;

          display: flex;
          height: 16px;
          justify-content: center;
          width: 16px;

          img {
            background: white; /* #3C3F46 */
          }
        }

        a.disabled {
          cursor: default;
        }
      }
    }
  }

  .avatarTextSection {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;

    h1 {
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      width: 100%;
    }

    .location {
      font-style: italic;
      min-height: 25px;
      text-align: center;
    }

    .positionCompanyName {
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 768px) {
  .avatarSectionContainer {
    flex-direction: row;
    height: 100%;
    position: relative;

    .avatarImageBlock {
      position: static;

      .avatarImage {
        height: 128px;
        width: unset;
      }

      .socialLabels {
        bottom: unset;
        left: unset;
        right: 16px;

        top: 16px;
        transform: unset;
      }
    }

    .avatarTextSection {
      align-items: flex-start;
      gap: 10px;
      justify-content: center;

      h1 {
        text-align: left;
      }

      .location {
        text-align: left;
      }

      .positionCompanyName {
        flex: 0;
        flex-direction: row;
        gap: 5px;
        justify-content: flex-start;
      }
    }
  }
}

@media (max-width: 430px) {
  .avatarSectionContainer {
    align-items: center;
    flex-direction: column;

    .avatarImageBlock {
      position: relative;

      .avatarImage {
        height: 128px;
        width: unset;
      }

      .socialLabels {
        bottom: -14px;
        left: 50%;
        right: unset;
        top: unset;
        transform: translateX(-50%);
      }
    }

    .avatarTextSection {
      align-items: center;

      h1 {
        text-align: center;
      }

      .location {
        text-align: center;
      }

      .positionCompanyName {
        align-items: center;
        flex-direction: column;
        text-align: center;
      }
    }
  }
}
