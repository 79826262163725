.Table {
  height: calc(100% - 20px);
}

.TableWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 150vh;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  position: relative;
}

.TableContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 300px;
}
