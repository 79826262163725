.locationContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  width: 160px;
}

.locationHeader {
  width: 170px;
}

.nameContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  width: 74px;
}

.nameHeader {
  display: flex;
  width: 84px;
}

.emailContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: flex-start;
  width: 150px;
}

.emailHeader {
  display: flex;
  width: 160px;
}

.leadsTitle {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  width: 100%;
}

.createdAtContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  width: 115px;
}

.createdAtHeader {
  display: flex;
  width: 131px;
}
