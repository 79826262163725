@media print {
  .pe-no-print {
    display: none !important;
  }

  .pe-preserve-print {
    overflow: visible !important;
  }

  .pe-preserve-ancestor {
    border: none !important;
    box-shadow: none !important;
    display: block !important;
    margin: 0 !important;
    overflow: visible !important;
    padding: 0 !important;
    position: relative !important;
  }
}
