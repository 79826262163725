.EditorCandidates {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
  position: relative;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 0px;
}

.title {
  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: flex-start;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: 16px;
  min-width: 82px;
}

.errorText {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.active {
  align-items: baseline;
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  padding: 0px 8px;
  text-transform: capitalize;
}

.inactive {
  align-items: baseline;
  background-color: var(--gray-05, rgb(60, 63, 70));
  border: 1px solid var(--gray-05, rgb(60, 63, 70));
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  padding: 0px 8px;
  text-transform: capitalize;
}

.category {
  align-items: baseline;
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 14px;
  margin-left: 8px;
  padding: 0px 8px;
  text-transform: capitalize;
}

.backButtonContainer {
  align-items: center;
  display: flex;
  margin-top: 8px;
}

.backButton {
  background-color: var(--gray-02, rgb(247, 247, 247));
  padding-left: 8px;
}
