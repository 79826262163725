.cohortNameCell {
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin-left: 16px;
  transition: color 0.07s linear;
  width: 110px;
}

.cohortHeader {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
  > div {
    flex: 1;
    margin-right: 16px;
  }
}

.regularCohortCell {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  width: 80px;
}

.actionsCell {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.cohortStatusCell {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 90px;
}

.activeStatus {
  color: var(--green-01, #5cab06);
}
.inactiveStatus {
  color: var(--red-01, rgb(255, 0, 64));
}

.cohortTypeCell {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
  width: 90px;
}

.cohortDate {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  width: 90px;
}

.cohortStatusHeader {
  display: flex;
  justify-content: center;
  width: 100px;
}

.actionHeader {
  width: 110px;
}

.firstLabel {
  margin-left: 16px;
  width: 120px;
}

.emptyContainerContent {
  align-items: center;
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  font-size: 12px;
  height: 64px;
  justify-content: center;
}

.searchBarFilter {
  width: 100%;
}
