.ActionBlock {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CreateAccount {
  display: flex;
  justify-content: flex-end;
}

.SignIn {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 12px;
  height: 36px;
  justify-content: space-between;
  margin-right: 5px;
  margin-top: 16px;
}

.SignIn a {
  color: var(--seafoam-02, rgb(0, 191, 186));
}

.signInText {
  color: #3c3f46;
  font-size: 14px;
  font-weight: 600;
}

.signInLink {
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  transition: color 0.2s linear;
  &:visited {
    color: var(--seafoam-02, rgb(0, 191, 186));
  }
  &:hover {
    color: var(--seafoam-01, rgb(21, 218, 212));
  }
}
