.InputCheckboxSlider {
  align-items: center;
  display: flex;
  height: 40px;
}

.labelStyle {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 600;
  height: 12px;
  width: 30px;
}

.thinLabel {
  font-size: 12px;
  font-weight: 500;
}

.labelContent {
  left: 40px;
  position: absolute;
  top: -4px;
  transition: none;
  width: max-content;
}

.required::before {
  color: var(--red-01, rgb(255, 0, 64));
  content: '*';
  margin-right: 6px;
  position: relative;
  top: 2px;
}

.inputStyle + .labelStyle {
  background-color: var(--gray-025, #d1d3d4);
  border-radius: 34px;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  position: relative;
  transition: all 0.2s;
  &:before {
    background-color: var(--gray-02, rgb(247, 247, 247));
    border-radius: 50%;
    bottom: -3px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
    content: '';
    cursor: pointer;
    height: 20px;
    left: -3px;
    position: absolute;
    transition: all 0.2s;
    width: 20px;
    &:hover {
      background-color: #fff;
    }
  }
}

.inputStyle:checked + .labelStyle {
  &:before {
    background-color: var(--seafoam-01, rgb(21, 218, 212));
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
    content: '';
    cursor: pointer;
    height: 15px;
    height: 20px;
    margin-right: 5px;
    transition: all 0.2s;
    width: 15px;
    width: 20px;
  }
}

.inputStyle:checked + .labelStyle:before {
  transform: translateX(16px);
}

.inputStyle {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 40px;
  left: -9999px;
  margin-right: 8px;
  padding-left: 12px;
  position: absolute;
}

.disabledLabel {
  color: var(--gray-035, rgb(148, 153, 162)) !important;
  cursor: default !important;
}

.disabled {
  cursor: default !important;
}
