.Terms {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  justify-content: flex-start;
  & li {
    margin-bottom: 8px;
  }
  > div:nth-child(1) {
    max-width: 1132px;
  }
  > div:nth-child(2) {
    max-width: 1132px;
    width: 100%;
  }
}

.termsContent {
  margin-bottom: 24px;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}

.termsContentIndented {
  margin-left: 24px;
  margin-top: 24px;
}
.termsContentDoubleIndented {
  margin-left: 48px;
  margin-top: 24px;
}
.termsContentTripleIndented {
  margin-left: 72px;
  margin-top: 24px;
}

.termsBold {
  font-weight: 600;
}

.termsUnderline {
  text-decoration: underline;
}

.termsBoldUnderline {
  font-weight: 600;
  margin: 0px 8px;
  text-decoration: underline;
}

.termsBoldCentered {
  font-weight: 600;
  text-align: center;
}

.termsText {
  margin: 0 12%;
  margin-bottom: 128px;
  margin-top: 48px;
}
