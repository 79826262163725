.Sidebar {
  background-color: var(--gray-02, rgb(247, 247, 247));
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 165px;
  position: absolute;
  width: max-content;
  z-index: 2;
  > div {
    width: 100%;
  }
  a {
    align-items: center;
    color: var(--gray-04, rgb(120, 124, 127));
    display: flex;
    font-size: 14px;
    height: 32px;
    margin-top: 8px;
    transition: color 0.2s;
    width: 100%;
  }
  a:hover {
    color: var(--gray-05, rgb(60, 63, 70));
  }
}

.FakeSidebar {
  background-color: var(--gray-02, rgb(247, 247, 247));
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 165px;
  visibility: hidden;
  width: max-content;
  > div {
    width: 100%;
  }
  a {
    align-items: center;
    color: var(--gray-04, rgb(120, 124, 127));
    display: flex;
    font-size: 14px;
    height: 32px;
    margin-top: 8px;
    transition: color 0.2s;
    width: 100%;
  }
  a:hover {
    color: var(--gray-05, rgb(60, 63, 70));
  }
}

.secondaryLinkContainer {
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  margin-left: 16px;
  margin-right: 8px;
  min-height: 32px;
  padding: 0 8px;
  transition: background-color 0.2s, color 0.2s, fill 0.2s;
  width: 100%;
}

.sidebarIcon {
  display: none;
}

.secondaryLinkActive {
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  font-weight: 700;
}

.secondaryLinkIcon {
  height: 10px;
  margin-right: 10px;
  width: 14px;
}

.secondaryLinkIconActive {
  color: #fff;
}

.borderDiv {
  background-color: var(--gray-05, rgb(60, 63, 70));
  height: 1px;
  margin-left: 48px;
  margin-top: 12px;
  width: 50%;
}

.disabledLink {
  color: var(--gray-03, rgb(155, 161, 167));
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: default;
  transition: all 0.3s linear;
  user-select: none;
}

.disabledLink:hover {
  background-color: var(--gray-02, rgb(247, 247, 247)) !important;
  color: var(--gray-03, rgb(155, 161, 167));
  color: var(--gray-03, rgb(155, 161, 167));
}

.linkTitle {
  align-items: center;
  display: flex;
}

.linkTitleMobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .Sidebar {
    flex-direction: row;
    flex-wrap: wrap;
    height: unset;
    justify-content: center;
    min-height: max-content;
    padding-bottom: 6px;
    position: relative;
    width: 100%;
    z-index: 2;
    a {
      justify-content: center;
    }
    > div {
      flex: 1;
      height: 56px;
      min-width: 64px;
    }
  }
  .FakeSidebar {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    height: unset;
    justify-content: center;
    min-height: max-content;
    visibility: hidden;
    width: 100%;
    a {
      justify-content: center;
    }
    > div {
      flex: 1;
      height: 56px;
      min-width: 64px;
    }
  }
  .linkTitleMobile {
    align-items: center;
    display: flex;
    font-size: 12px;
    min-height: 20px;
    min-width: max-content;
    position: absolute;
    top: 34px;
  }
  .linkTitle {
    display: none;
  }
  .secondaryLinkContainer {
    align-items: center;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    height: 32px;
    position: relative;
    width: 32px;
    &:hover {
      box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.22);
    }
  }
  .sidebarIcon {
    display: inline-block;
    line-height: 32px;
    margin-right: 0px;
    position: absolute;
    /* margin-left: 8px; */
    z-index: 2;
  }
  .sidebarIcon:before {
    font-size: 17px;
  }
  .secondaryLinkIconActive {
    color: var(--seafoam-01, rgb(21, 218, 212));
    position: absolute;
  }
  .borderDiv {
    display: none;
  }
}

.new {
  background-color: var(--gray-05, rgb(60, 63, 70));
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  margin-left: 6px;
  padding: 4px 6px;
}

.newDisabled {
  background-color: var(--gray-03, rgb(155, 161, 167));
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  margin-left: 6px;
  padding: 4px 6px;
}
