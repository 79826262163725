.EmployersTable {
  height: 90vh;
  margin-left: 15px;
}

.employerContent {
  color: inherit;
  display: flex;
  width: 240px;
}

.employerHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  margin-right: 16px;
  padding: 8px 0px;
}

.dashboardTitleContainer {
  padding-left: 16px;
}

.employerHeaderLeft {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.employerHeaderBold {
  font-size: 14px;
  font-weight: 600;
}

.lastActivityContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  width: 100px;
}

.lastActivityHeader {
  display: flex;
  justify-content: center;
  width: 110px;
}

.locationContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  text-transform: capitalize;
  width: 100px;
}
.saasContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
  width: 74px;

  i {
    color: var(--seafoam-01, rgb(21, 218, 212));
    margin-left: 5px;
  }
}

.saasHeader {
  display: flex;
  justify-content: center;
  width: 84px;
}
.creatorContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
  width: 150px;
}

.creatorHeader {
  display: flex;
  justify-content: center;
  width: 160px;
}
.oteContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  width: 70px;
}

.tableSmallText {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  width: 80px;
}

.tableSmallTextCenteredTimeActive {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  width: 75px;
}
.tableSmallTextCenteredLastContact {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  width: 75px;
}
.tableSmallTextCenteredInterview {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  width: 75px;
}
.tableSmallTextCenteredDate {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  width: 85px;
}
.tableSmallTextCenteredEnd {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  width: 165px;
}

.tableSmallTextBold {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  width: 55px;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.capitalizeSubtitle {
  text-transform: capitalize;
}

.loader {
  align-items: center;
  clear: both;
  display: flex;
  height: 64px;
  justify-content: center;
}
.TableWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
}

.nameHeader {
  min-width: 240px;
  padding-left: 76px;
  width: 240px;
}
.statusHeader {
  min-width: 98px;
}
.oteHeader {
  min-width: 80px;
}

.locationHeader {
  width: 110px;
}

.candActionHeader {
  min-width: 110px;
  width: 110px;
}

.updatedAtContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
  width: 80px;
}

.updatedAtHeader {
  display: flex;
  justify-content: center;
  width: 90px;
}

.requestConfirmationModal {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-width: 398px;
  padding: 32px;
}

.requestConfirmationTitle {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 16px;
  text-align: center;
}

.requestConfirmationContent {
  font-size: 14px;
  padding-bottom: 24px;
  width: 75%;
}

.requestConfirmationButtons {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 75%;
}
