.Thanks {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  justify-content: space-between;
  overflow-x: auto;
  width: 100vw;
  & li {
    margin-bottom: 8px;
  }
  > div:nth-child(1) {
    display: flex;
    justify-content: center;
    margin-top: 144px;
    max-width: 300px;
  }
  > div:nth-child(2) {
    max-width: 1132px;
    width: 100%;
  }
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}

.thanksContent {
  margin-top: 16px;
  text-align: center;
}

.header {
  font-size: 18px;
}
