.container {
  h4 {
    font-weight: 600;
    margin: 0 0 10px 0;
    text-transform: uppercase;
  }

  a {
    color: #999999;
    font-size: 0.9em;
    line-height: 1.5;
  }

  .itemLink {
    color: #999999;
    font-size: 0.9em;
    line-height: 1.5;
    &:hover,
    &:active {
      color: var(--gray-05, rgb(60, 63, 70));
      text-decoration: underline;
    }
  }
}
