.SearchBarOne {
  flex: 1;
  margin-right: 32px;
  max-width: 332px;
  min-width: 210px;
  > div {
    margin-bottom: 4px;
    min-height: 40px;
  }
}

.SearchBarTwo {
  flex: 1;
  margin-right: 32px;
  max-width: 332px;
  min-width: 210px;
  > div {
    margin-bottom: 4px;
  }
}

.searchBarFilter {
  width: 100%;
}

.checkbox {
  margin-left: 0px;
  > label {
    align-items: center;
    > span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
