.Container {
  align-items: center;
  flex-direction: column;
}

.CandidateRegistration {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  > div:nth-child(1) {
    margin: 0px 48px;
    max-width: 100%;
    min-width: 350px;
    padding: 0px 48px;
    width: 50%;
  }
  > form:nth-child(2) {
    margin: 0px 48px;
    max-width: 390px;
    min-width: 390px;
    width: 390px;
  }
}

.Title {
  font-size: 24px;
  font-weight: 700;
  margin-top: 32px;
  text-align: center;
}

.errorMessage {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  margin-top: 24px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .CandidateRegistration {
    justify-content: flex-start;
    > form:nth-child(2) {
      margin: 0px;
      max-width: 390px;
      min-width: 0px;
      width: 100vw;
    }
  }

  .Title {
    font-size: 22px;
    font-weight: 700;
    margin: 32px 16px 0px 16px;
    text-align: center;
  }
}
