.approvedCandidateDate {
  align-items: center;
  display: flex;
  margin-right: 8px;
  min-width: max-content;
  width: 160px;
}

.addCandidatesOptionsContainer {
  min-width: 357px;
  padding: 16px;
}
