.profileRecruitmentContainer {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}

.question {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  margin-bottom: 13px;
}

.answer {
  display: flex;
  font-size: 14px;
  margin-bottom: 32px;
}

.locAnswer {
  display: flex;
  font-size: 14px;
  margin-bottom: 16px;
}

.blankAnswer {
  font-style: oblique;
  margin-bottom: 34px;
}

.emptyContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 120px;
}

.candReqContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}

.requirement {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 24px;
  > icon {
    margin-right: 8px;
  }
  span {
    margin-right: 5px;
  }
}

.requirementContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  max-width: 50%;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;

  > icon {
    margin-right: 8px;
  }

  > span {
    margin-right: 5px;
  }
}

.marginBottom {
  margin-bottom: 36px;
}

.workModel {
  color: var(--gray-04, rgb(120, 124, 127));
  padding-bottom: 4px;
}

.locNotes {
  background-color: var(--gray-01, rgb(247, 247, 247));
  color: var(--gray-04, rgb(120, 124, 127));
  font-weight: 500;
  margin-top: 8px;
  word-break: break-word;
}

.locNotesHead {
  background-color: var(--gray-01, rgb(247, 247, 247));
  color: var(--gray-04, rgb(120, 124, 127));
  font-weight: 700;
  padding: 8px;
  padding-left: 16px;
  word-break: break-word;
}

.icon {
  margin-right: 5px;
}
