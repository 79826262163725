.Attribute {
  margin-top: 10px;
  max-width: 448px;
  padding: 10px 0px;
  width: 100%;
  > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
    height: 0px;
  }
}

.Attribute label {
  margin-bottom: 8px;
}

.Title {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 12px;
}

.Label::after {
  color: var(--red-01, rgb(255, 0, 64));
  content: '*';
  margin-left: 4px;
  position: relative;
}

.subTitle {
  margin-bottom: 8px;
}
