.SocialProof {
  align-items: center;
  background: linear-gradient(90deg, #2aceef 0%, #18ddd8 52.59%, #00fff9 100%);
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 900px;
  justify-content: center;
  min-height: 900px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
}

.SocialProof::before {
  background: #fff;
  content: '';
  height: 300px;
  left: 0;
  position: absolute;
  top: -123px;
  transform: skewY(-6deg);
  transform-origin: center;
  width: 100%;
  z-index: -1;
}

.SocialProof::after {
  background: #fff;
  content: '';
  height: 900px;
  left: 0;
  position: absolute;
  top: 690px;
  transform: skewY(-6deg);
  transform-origin: center;
  width: 100%;
  z-index: -1;
}

.TopQuoteContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  padding: 0px 2%;
  > div:nth-child(1) {
    margin-bottom: 80px;
    margin-right: 1%;
    max-width: 552px;
    min-width: 244px;
    width: 49%;
  }
  > div:nth-child(2) {
    margin-left: 1%;
    margin-top: 80px;
    max-width: 552px;
    min-width: 244px;
    width: 49%;
  }
}

.BottomQuoteContainer {
  margin-right: 192px;
  max-width: 552px;
  width: 66%;
}

.QuoteContainer {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 12px 24px 0px rgba(0, 0, 0, 0.15), 0 2px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 244px;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
}

.QuoteOne {
  color: #4971ff;
  font-size: 20px;
}

.QuoteTwo {
  color: #00bfba;
  font-size: 20px;
}

.QuoteThree {
  color: #008cdc;
  font-size: 20px;
}

.Person {
  display: flex;
}

.Avatar {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 48px;
}

.Avatar img {
  height: 48px;
  object-fit: cover;
  width: 48px;
}

.Info {
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: space-around;
  padding-left: 12px;
}

.Name {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0px;
  text-align: left;
  width: 100%;
}

.Role {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .SocialProof {
    display: block;
  }
  .SocialProof::before {
    top: -240px;
  }
  .SocialProof::after {
    top: 840px;
  }
  .TopQuoteContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    padding: 0px 2%;
    > div:nth-child(1) {
      margin: 0 auto;
      margin-bottom: 24px;
      max-width: 552px;
      min-width: 244px;
      width: 100%;
    }
    > div:nth-child(2) {
      margin: 0 auto;
      margin-top: 0px;
      max-width: 552px;
      min-width: 244px;
      width: 100%;
    }
  }

  .BottomQuoteContainer {
    margin-right: 0px;
    max-width: 100% !important;
    padding: 0px 2%;
    width: 100% !important;
    > div:nth-child(1) {
      margin: 0 auto;
      max-width: 552px;
    }
  }
  .QuoteOne {
    color: #4971ff;
    font-size: 18px;
  }

  .QuoteTwo {
    color: #00bfba;
    font-size: 18px;
  }

  .QuoteThree {
    color: #008cdc;
    font-size: 18px;
  }
}
