.whatSoldContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 48px;
  width: 100%;
}

.container {
  margin-top: 48px;
  width: 100%;
}

.containerTitle {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
  width: 100%;
}

.label {
  margin-bottom: 12px;
  margin-top: 24px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.autocompleteContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}
