.CandidateSearch {
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw;
}

.searchMainContent {
  height: calc(100vh - 48px);
  min-width: 292px;
  padding: 8px;
}

.searchSecondHeader {
  align-items: center;
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  display: flex;
  justify-content: space-between;
  min-height: 64px;
}

.searchResultsContainer {
  flex: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: relative;
}

.searchBarContainer {
  align-items: flex-start;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.searchOverlay {
  background-color: #fff;
  height: calc(100% - 113px);
  opacity: 0.7;
  position: absolute;
  width: 100%;
}

.emptySearchResultsContainer {
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  flex: 1;
  font-size: 14px;
  justify-content: center;
  padding-top: 50px;
}

.basicFiltersContainer {
  flex: 2;
}

.sidebarClasses {
  min-width: 220px;
  overflow: auto;
}

.buttonsContainer {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  width: max-content;
}

.autocomplete {
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 64px;
  padding-bottom: 16px;
  width: 100%;
  > div:nth-child(1) {
    margin-left: 12px;
    width: 60%;
  }
  > div:nth-child(1) > div:nth-child(1) {
    padding-top: 16px;
  }
}

.employerResultsContainer {
  align-items: center;
  display: flex;
  margin-bottom: -16px;
  margin-left: 12px;
}

.applyFilters {
  font-size: 14px !important;
  height: 32px;
  justify-content: center !important;
  margin: 8px 16px 8px 0px;
}

.moreFilters {
  font-size: 14px !important;
  height: 32px !important;
  justify-content: center !important;
  margin: 8px 16px 8px 0px;
}

.layoutContainer {
  padding-left: 7px;
}

/* RESPONSIVE */
@media only screen and (max-width: 768px) {
  .searchMainContent {
    height: calc(100vh - 110px);
  }

  .moreFilters {
    font-size: 14px !important;
    justify-content: center !important;
    margin: 8px 8px 8px 0px;
  }
}
