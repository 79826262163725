.EditorSettings {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
  > div {
    margin-bottom: 8px;
  }
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.input {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  min-width: 116px;
}

.inputContainer {
  display: flex;
  flex-wrap: wrap;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.label {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 8px;
  padding-top: 24px;
}

.errorText {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.emailContent {
  align-items: center;
  display: flex;
  font-size: 14px;
  > div:nth-child(1) {
    margin-left: 8px;
  }
}

.backButton {
  background-color: inherit;
  padding-left: 0px;
}

.backButtonContainer {
  margin-left: 16px;
  margin-top: 8px;
}
