.welcomeBottom {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: max-content;
  padding-top: 24px;
  width: 100%;
}

.greetingContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 438px;
  width: 438px;
}

.mikeGreeting {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 24px;
  font-weight: 500;
  margin: 26px 0px 16px 0px;
}

.mikeWelcome {
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  margin: 0px 72px 32px 72px;
  text-align: center;
}

.mikeImage {
  align-items: center;
  border-radius: 64px;
  display: flex;
  height: 128px;
  justify-content: center;
  min-height: 128px;
  min-width: 128px;
  overflow: hidden;
  position: relative;
  width: 128px;
}
.mikeImage img {
  height: 128px;
  object-fit: cover;
  width: 128px;
}

.mikeEmail a {
  color: var(--seafoam-02, rgb(0, 191, 186));
}
