.AdminData {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  min-height: 48px;
}

.AdminData:hover {
  color: var(--seafoam-02, rgb(0, 191, 186));
}

.adminDataInfoWrapper {
  display: flex;
  flex-direction: column;
  height: 48px;
  padding-left: 12px;
}

.adminDataInfoWrapperShort {
  width: 135px;
}

.adminDataAvatarWrapper {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 48px;
}
.adminDataAvatarLargeWrapper {
  align-items: center;
  border-radius: 64px;
  display: flex;
  height: 128px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 128px;
}
.adminDataAvatarSmallWrapper {
  align-items: center;
  border-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 32px;
}

.adminDataAvatarWrapper img {
  height: 48px;
  object-fit: cover;
  width: 48px;
}

.adminDataAvatarLargeWrapper img {
  height: 128px;
  object-fit: cover;
  width: 128px;
}
.adminDataAvatarSmallWrapper img {
  height: 32px;
  object-fit: cover;
  width: 32px;
}

.adminDataTitle {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.adminDataTitleSmall {
  font-size: 12px;
}

.adminName {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adminDataSubtitle {
  align-items: flex-start;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.adminDataSubtitleBold {
  font-weight: 600;
}

.positionTitle {
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.companyName {
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sideTable {
  padding-bottom: 12px;
}
