.container {
  margin: 24px;
  margin-top: 8px;
}

.inputContainer {
  margin-top: 24px;
}

.checkboxContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}

.checkboxLabel {
  font-size: 18px;
}

.inputDescription {
  max-width: 472px;
  width: 60%;
}

.contentContainer {
  position: relative;
}

.profileLocked {
  font-weight: 600;
  margin-left: 8px;
}
