.CandidateEditorWork {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw;
}

.EditorWork {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.workHistoryBlock {
  margin-bottom: 8px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  padding: 8px;
  padding-top: 2px;
  position: relative;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  align-items: center;
  display: flex;
}

.profileLocked {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  margin-right: 12px;
}

.highlight {
  outline: var(--seafoam-01, rgb(21, 218, 212)) auto 3px;
}
