.TableCell {
  align-items: center;
  color: inherit;
  display: flex;
  padding-right: 10px;
}

.centerText {
  justify-content: center;
  text-align: center;
}

.noFlex {
  flex: 0 0;
}

.flex {
  flex: 1;
}
