.dateContent {
  align-items: center;
  display: flex;
  > div {
    margin-right: 8px;
  }
}

.date {
  font-size: 14px;
  font-weight: 600;
  margin-right: 16px;
}

.placeholderDate {
  align-items: center;
  display: flex;
  width: 84px;
}

.copy {
  font-size: 12px;
}
