.requestIntroModal {
  align-items: center;
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 48px);
  max-height: max-content;
  overflow-y: scroll;
  padding: 16px;
  position: relative;
}

.requestIntroTitle {
  font-size: 18px;
  margin-bottom: 16px;
  text-align: center;
}

.requestIntroContentAnd {
  font-size: 14px;
  margin: 0px 12px;
}
.requestIntroContent {
  font-size: 14px;
  margin-bottom: 24px;
}

.requestOptionsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  > div:nth-child(1) {
    margin-bottom: 8px;
  }
  > div:nth-child(2) {
    margin-bottom: 8px;
  }
}

.adminRequestOptionsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  > div:nth-child(1) {
    margin-bottom: 8px;
    margin-top: 0px;
  }
  > div:nth-child(2) {
    margin-bottom: 8px;
  }
}

.createNewReqButton {
  margin-bottom: 24px;
}

.requestIntroButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.inputContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  > div {
    margin-top: 0px;
  }
}

.messageModal {
  max-width: 90vw;
  top: 48px;
  width: 952px;
}

.top {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 64px;
  padding-bottom: 16px;
  width: 100%;
}
.close {
  font-size: 24px;
}

.emptyBackButton {
  font-size: 24px;
  width: 75px;
}
.backButton {
  font-size: 24px;
}

.errorMessage {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  text-align: center;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}
