.Form {
  width: 100%;
  > button:last-child {
    margin: 0 auto;
    margin-bottom: 48px;
    width: 192px;
  }
}

.regHeader {
  font-weight: 600;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 16px;
  text-align: center;
}

.rowInputs {
  display: flex;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 12px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-left: 12px;
  }
}

.autocompleteContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.label {
  display: flex;
  margin-top: 24px;
}

.locErrors {
  min-height: 16px !important;
}

.checkbox {
  margin-left: 0px;
  margin-top: 0px;
  > label {
    align-items: center;
    > span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.registrationFormContainer {
  display: flex;
  flex-direction: column;
}

.applyHeader {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .Form {
    min-width: 390px;
    padding: 0px 16px;
    padding-bottom: 32px;
    width: 100%;
  }

  .rowInputs {
    display: flex;
    flex-direction: column;
    > div:nth-child(1) {
      flex: 1;
      margin-right: 0px;
    }
    > div:nth-child(2) {
      flex: 1;
      margin-left: 0px;
    }
  }
}
