.wrapper {
  background: linear-gradient(#bccdff, #cfedff);
  height: 100%;
  margin: 0;
  padding: 0;

  position: relative;
  width: 100%;

  svg {
    .st0,
    .st1,
    .st2 {
      fill: #d4e0ff;
    }

    .st3,
    .st4,
    .st5 {
      fill: #cef7ff;
    }

    .st3,
    .st4,
    .st1,
    .st2 {
      isolation: isolate;
    }

    .st3,
    .st1 {
      opacity: 0.3;
    }

    .st4,
    .st2 {
      opacity: 0.5;
    }
  }

  .mainPic {
    background: white;
    border-radius: 32px;
    box-shadow: 5px 5px 30px #000dc833, -5px -5px 30px #000dc833;
    left: 50%;
    max-height: 90%;
    max-width: 600px;

    overflow: hidden;

    padding: 40px;
    position: absolute;

    top: 50%;
    transform: translate(-50%, -50%);

    width: 90%;

    img {
      display: block;
      margin: 0 auto;
      width: 180px;
    }

    .textContainer {
      .picTitle {
        font-size: 40px;
        font-weight: 800;
        margin: 10px 0 20px;
        text-align: center;
      }

      .picText {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .wrapper {
    height: auto;
    overflow: hidden;
    padding: 0;

    .mainPic {
      align-items: center;

      box-shadow: none;

      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      margin: 10px auto;
      padding: 20px;
      position: static;
      transform: none;
    }

    svg {
      display: none;
    }
  }
}

@media only screen and (max-width: 450px) {
  .wrapper {
    .mainPic {
      border-radius: 0;
      height: unset;
      margin: 0;
      padding: 10px;
      width: unset;

      img {
        max-width: 100px;
      }

      .textContainer {
        .picTitle {
          font-size: 30px;
          font-weight: 800;
          margin: 10px 0 15px;
        }

        .picText {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
      }
    }
  }
}
