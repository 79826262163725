.Candidates {
  background: #fff;
  padding-bottom: 256px;
}

.Container {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 1132px;
  overflow: hidden;
  padding: 0px 24px;
  position: relative;
  width: 100%;
}

.Column1 {
  display: inline-block;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  width: 41.66666666666666%;
}

.Column2 {
  display: inline-block;
  float: left;
  margin-top: -45px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  width: 58.33333333333333%;
}

.Image {
  width: 100%;
}

.SecondaryTitle {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 16px;
  text-transform: uppercase;
}

.Description {
  font-size: 18px;
  line-height: 1.75;
}

@media only screen and (max-width: 768px) {
  .Candidates {
    padding-bottom: 128px;
  }
  .Column1 {
    width: 100%;
  }
  .Column2 {
    display: none !important;
  }
  .SecondaryTitle {
    text-align: center;
  }
}
