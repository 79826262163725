.EducationBlob {
  align-items: flex-start;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 14px;
}

@media print {
  .EducationBlob {
    break-inside: avoid;
  }
}

.educationPicture {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: center;
  width: 64px;
}

.educationPicture img {
  margin-top: 4px;
  max-height: 100%;
  max-width: 64px;
}

.secondaryContainer {
  display: flex;
}

.secondaryDetailsOne {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 20px;
  width: 64px;
}
.secondaryDetailsTwo {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-left: 24px;
  margin-top: 20px;
}

.educationDetails {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
}

.educationName {
  font-size: 14px;
  font-weight: 700;
}

.educationDegree {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
}

.educationYears {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  font-weight: 500;
}

.educationTitle {
  padding-bottom: 5px;
}

.gpa {
  text-align: center;
}
