.EmployerEditorPreviewContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
}

.previewMainContainer {
  display: flex;
  padding: 8px;
  padding-top: 0px;
}

.previewAboutContainer {
  flex: 3;
  padding-right: 8px;
}

.previewSideContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
  > div:last-child {
    padding-bottom: 0px;
  }
}

.previewSideSmallContainer {
  padding-bottom: 8px;
}

.titleBlock {
  margin: 8px;
}
