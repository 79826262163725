.CandidateRegistration {
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 1152px;
  position: relative;
  width: 100vw;
}

.mainContainer {
  align-self: flex-start;
  margin-top: 24px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
.contentContainer {
  margin-bottom: 48px;
  margin-top: 16px;
  max-width: 368px;
}

.prevButtonContainer {
  height: 50%;
  margin: 0px 16px;
  min-width: 64px;
}

.nextButtonContainer {
  height: 50%;
  margin: 0px 16px;
  min-width: 64px;
}

.errorMessage {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  margin-top: 24px;
  text-align: center;
}

.buttonContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 64px;
  margin-top: 64px;
}

.actionButton {
  align-items: center;
  height: 40px;
  /* position: fixed !important; */
  margin: 4px;
}

@media only screen and (max-width: 768px) {
  .nextButtonContainer {
    margin: 0px 8px;
    min-width: 32px;
    width: 32px;
  }
  .prevButtonContainer {
    margin: 0px 8px;
    min-width: 32px;
    width: 32px;
  }
  .contentContainer {
    max-width: calc(100vw - 96px);
    text-align: center;
  }
  .CandidateRegistration {
    flex-direction: row !important;
  }
}
