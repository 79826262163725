.TalentConcierge {
  align-items: center;
  display: flex;
  flex: 1;
}

.bottom {
  align-items: center;
  display: flex;
}

.adminDataInfoWrapper {
  display: flex;
  flex-direction: column;
  height: 56px;
  padding-left: 12px;
}

.dataInfoWrapper {
  display: flex;
  flex-direction: column;
  height: 48px;
  padding-left: 12px;
}

.adminDataAvatarWrapper {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 48px;
}

.adminDataAvatarWrapper img {
  height: 48px;
  object-fit: cover;
  width: 48px;
}

.smallText {
  font-size: 12px;
  font-weight: 500;
  margin-left: 12px;
  & a {
    color: var(--gray-05, rgb(60, 63, 70));
    text-decoration: underline;
    &:visited {
      color: var(--gray-05, rgb(60, 63, 70));
      text-decoration: underline;
    }
    &:hover {
      color: var(--gray-05, rgb(60, 63, 70));
      text-decoration: underline;
    }
  }
}

.adminName {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adminDataSubtitle {
  color: var(--gray-04, rgb(120, 124, 127));
  font-size: 12px;
  font-weight: 500;
  min-height: 14px;
}
