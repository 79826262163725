@import './variables.scss';

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-rendering: optimizeLegibility;
}
html {
  background: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif; /* 1 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}
body {
  backface-visibility: hidden;
  color: var(--gray-05, rgb(60, 63, 70));
  font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif; /* 1 */
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  margin: 0;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
figcaption,
figure,
main {
  /* 1 */
  display: block;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
pre {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}
a {
  background-color: rgba(255, 255, 255, 0); /* 1 */
  cursor: pointer;
  text-decoration: none;
  -webkit-text-decoration-skip: objects; /* 2 */
}
a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: 600;
}
code,
kbd,
samp {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}
label:focus {
  outline: none;
}
input:focus {
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  outline: none;
}
input:placeholder-shown {
  font-style: oblique;
}
input::placeholder {
  color: var(--gray-03, rgb(155, 161, 167));
}
textarea:placeholder-shown {
  font-style: oblique;
}

textarea::placeholder {
  color: var(--gray-03, rgb(155, 161, 167));
}

button,
input {
  font-weight: 500;
  /* 1 */
  overflow: visible;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
button,
select {
  /* 1 */
  text-transform: none;
}
button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* 2 */
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
textarea {
  overflow: auto;
}
textarea:focus {
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  outline: none;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
[type='checkbox']:focus {
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  outline: none;
}
[type='radio']:focus {
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  outline: none;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
details, /* 1 */
menu {
  display: block;
}
summary {
  display: list-item;
}
canvas {
  display: inline-block;
}
template {
  display: none;
}
[hidden] {
  display: none;
}

@media print {
  @page {
    margin: 0.5in;
  }
}
