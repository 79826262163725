.profileAvatarWrapper {
  align-items: center;
  border-radius: 64px;
  display: flex;
  height: 128px;
  justify-content: center;
  min-height: 128px;
  min-width: 128px;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  width: 128px;
}

.profileAvatar {
  height: 128px;
  object-fit: cover;
  width: 128px;
}

.blur {
  filter: blur(20px);
}
