.PrivacyPolicy {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  justify-content: flex-start;
  & li {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  > div:nth-child(1) {
    max-width: 1132px;
  }
  > div:nth-child(2) {
    max-width: 1132px;
    width: 100%;
  }
}

.privacyContent {
  margin-bottom: 24px;
  > span:nth-child(1) {
    margin-right: 8px;
  }
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(21, 218, 212));
    }
  }
}

.firstPrivacyContent {
  margin-bottom: 24px;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(21, 218, 212));
    }
  }
}

.privacyContentAddress {
  margin-bottom: 24px;
  margin-left: 24px;
  > span:nth-child(1) {
    margin-right: 8px;
  }
}

.privacyContentIndented {
  margin-left: 24px;
  margin-top: 24px;
  > span:nth-child(1) {
    margin-right: 8px;
  }
}

.privacyBold {
  font-weight: 600;
}

.privacyUnderline {
  text-decoration: underline;
}

.privacyText {
  margin: 0 12%;
  margin-bottom: 128px;
  margin-top: 48px;
}

.emailLink {
  word-break: break-word;
}
