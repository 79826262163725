.checklistContainer {
  min-height: 174px;
  position: relative;
}

.checklistItemContainer {
  display: flex;
}

.contentContainer {
  margin-left: 12px;
  > span:nth-child(1) {
    align-items: center;
    display: flex;
    height: 24px;
  }
}

.danger {
  color: var(--red-01, rgb(255, 0, 64));
  margin-right: 5px;
}

.normal {
  margin-right: 5px;
}

.optional {
  color: var(--gray-05, rgb(60, 63, 70));
  margin-right: 5px;
}

.profileCardsContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0px;
}

.secondary {
  margin: 8px 0px;
}

.padded {
  margin-top: 24px;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}

.toggle {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: 2px;
  width: max-content;
  > icon {
    color: var(--gray-035, rgb(148, 153, 162));
  }
}

.gray {
  color: var(--gray-035, rgb(148, 153, 162));
  margin-right: 5px;
}
