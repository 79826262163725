.addEmpTitle {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0px 16px;
}

.employerContent {
  min-width: 230px;
  width: 230px;
}

.statusContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  min-width: 80px;
  text-transform: capitalize;
}

.greenActive {
  color: var(--green-01, #5cab06);
}

.removeEmpContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  font-size: 28px;
  transition: color 0.2s linear;
  &:hover {
    color: var(--red-01, rgb(255, 0, 64));
  }
}

.content {
  display: flex;
  flex: 1;
  margin: 8px;
  max-height: calc(100% - 78px);
}

.tableHeader {
  background-color: var(--gray-01, rgb(247, 247, 247));
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 56px;
  padding-right: 6px;
}

.statusHeader {
  align-items: center;
  display: flex;
  width: 90px;
}

.companyHeader {
  align-items: center;
  display: flex;
  margin-left: 16px;
  padding-left: 56px;
  width: 240px;
}

.checkboxHeader {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: 10px;
  width: 100px;
}

.employerCheckbox {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 90px;
}

.removeHeader {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding-right: 16px;
}

.removeEmpContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 28px;
  justify-content: center;
  transition: color 0.2s linear;
  &:hover {
    color: var(--red-01, rgb(255, 0, 64));
  }
}

.headerText {
  padding-right: 8px;
}
