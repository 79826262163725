.saveSearchModal {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
}

.saveSearchTitle {
  font-size: 24px;
  text-align: center;
}

.saveSearchInput {
  padding-bottom: 24px;
  width: 75%;
}

.saveSearchButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 75%;
}
