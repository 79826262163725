.basicInfoContainer {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  padding: 0px 16px;
  padding-bottom: 32px;
}

.basicInfoColumnInputs {
  display: flex;
  flex-direction: column;
}

.autocompleteContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.oteInputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0;
}

.formRow {
  display: flex;
  width: 80%;
  > div:nth-child(1) {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 12px;
  }
  > div:nth-child(2) {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;
  }
  > label:nth-child(1) {
    flex: 1;
    margin-right: 12px;
  }
  > label:nth-child(2) {
    flex: 1;
    margin-left: 12px;
  }
}

.checkbox {
  margin-top: 16px;
  > label {
    align-items: center;
    > span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.searchBarFilter {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .formRow {
    flex-wrap: wrap;
    width: 100%;
  }
}
