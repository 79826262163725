.profileContactButton {
  height: 40px;
  margin-bottom: 8px;
  width: 100%;
}

.seafoam {
  color: var(--seafoam-01, rgb(21, 218, 212));
  font-size: 16px;
  margin-bottom: 1px;
  margin-right: 8px;
}

.contactedNote {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  margin-bottom: 8px;
}

.passButton {
  background: #fff;
  border: 1px solid var(--red-01, rgb(255, 0, 64));
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;
}

.passedButton {
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;
}

.fakeContact {
  background-color: var(--gray-01, rgb(247, 247, 247));
  border-radius: 4px 4px 4px 4px;
  height: 40px;
  margin-bottom: 8px;
  max-height: 40px;
  opacity: 0.6;
  width: 100%;
}

.fakeContact2 {
  background-color: var(--gray-01, rgb(247, 247, 247));
  border-radius: 4px 4px 4px 4px;
  height: 40px;
  margin-bottom: 8px;
  max-height: 40px;
  opacity: 0.6;
  width: 100%;
}
