.LinkInputs {
  display: flex;
}

.link {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 262px;
}

.deleteLink {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  font-size: 18px;
  padding: 0 8px;
  padding-top: 16px;
  transition: color 0.2s;
}

.deleteLink:hover {
  color: var(--red-01, rgb(255, 0, 64));
}

.addLink {
  font-size: 12px;
  padding-left: 0px;
}

.disableAddLink {
  color: var(--gray-02, rgb(247, 247, 247));
  cursor: default;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 12px;
  transition: color 0.2s;
}

.linkLabel {
  margin-bottom: 16px;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.linksArray {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
