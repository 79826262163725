.flexColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 40px;
  min-width: min-content;
  padding-right: 16px;
  > span {
    white-space: nowrap;
  }
}

.flexColumnList {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-right: 8px;
  min-width: min-content;
  width: 200px;
  > span {
    margin-bottom: 8px;
  }
}

.flexRow {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-top: 24px;
  width: 100%;
}

.salesInfoItem {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-right: 48px;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.salesLabel {
  text-align: center;
}

.salesInfoAmount {
  color: var(seafoam-01, #15dad4);
  font-size: 24px;
  text-align: center;
}

.summary {
  line-height: 1.5;
  margin-top: 24px;
}

.marginBottom {
  margin-bottom: 36px;
}
