.Dashboard {
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw;
}
.ListHeader {
  background-color: #f0f3f5;
  color: #6e6f70;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  padding: 16px 10px 8px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  width: 100%;
}

.talentConciergeContent {
  height: 54px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-top: 8px;
}

.dashboardMainContent {
  height: calc(100% - 54px);
  padding: 8px;
}

.dashboardRightTables {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px;
  padding-left: 0px;
}

.dashboardRightTopTable {
  display: flex;
  flex: 1 0px;
  flex-direction: column;
}
.dashboardRightBottomTable {
  display: flex;
  flex: 1 0px;
  flex-direction: column;
  margin-top: 8px;
}

.requestIntroModal {
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
}

.requestIntroContent {
  flex: 1;
  font-size: 14px;
  margin-bottom: 16px;
}

.requestIntroButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.normalText {
  font-weight: 500 !important;
}

.SearchBarOne {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  font-size: 14px;
  > div {
    flex: 1;
    justify-content: space-between;
    margin-bottom: 4px;
  }
  > span:nth-child(1) {
    margin-left: 6px;
  }
  > div:nth-child(2) {
    margin-left: 6px;
    margin-right: 6px;
    max-width: 150px;
    min-height: 40px;
    min-width: 150px;
    > div:nth-child(1) {
      min-width: 117px;
    }
  }
  > span > div:nth-child(1) {
    margin-bottom: 4px;
    margin-left: 6px;
    margin-right: 23px;
    max-width: 267px;
    min-height: 40px;
    min-width: 210px;
  }
  > div:nth-child(4) {
    margin-right: 16px;
    max-width: 307px;
    min-height: 40px;
    min-width: 220px;
    width: 100%;
  }
}

.searchBarFilter {
  width: 100%;
}

.checkbox {
  margin-left: 0px;
  > label {
    align-items: center;
    > span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.reqLabel {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.reqDetailMainClick {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  max-width: 192px;
  min-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reqDetailClick {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  border: 1px solid var(--gray-025, #d1d3d4);
  border-radius: 2px;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  transition: box-shadow 0.1s ease-in, color 0.2s ease-out, background-color 0.2s ease-in;
  width: 65px;
}

.reqDetailClick:hover {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

.reqDetailClickActive {
  background-color: var(--gray-05, rgb(60, 63, 70));
  border: none;
  color: #fff;
}
.reqDetailClickActive:hover {
  background-color: var(--gray-05, rgb(60, 63, 70));
  border: none;
  color: #fff;
}

.reqDetails {
  margin-left: 19px;
  margin-top: 23px;
}

.reqLineItem {
  color: var(--gray-04, rgb(120, 124, 127));
  font-weight: 500;
  margin-bottom: 16px;
}

.reqLineItemLast {
  margin-bottom: 0px;
}

.reqDetailBold {
  color: var(--gray-05, rgb(60, 63, 70));
  font-weight: 500;
  margin-right: 3px;
}

.buttonContent {
  margin-right: 8px;
}
