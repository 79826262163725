.profileSummarySectionContainer {
  background: #3e4042;

  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  overflow: hidden;
  padding: 16px;

  h2 {
    margin-bottom: 5px;
  }

  .moneyRangeData {
    display: flex;

    .moneyRangeTitle {
      font-weight: 500;
      width: 50px;
    }
  }

  .recruiterInsights {
    max-height: 215px;
    overflow: hidden;
  }

  .recruiterInsights:not(.screenshotView) {
    max-height: unset;
    overflow: visible;
  }
}

@media (max-width: 768px) {
  .profileSummarySectionContainer {
    flex-direction: row;
    height: 100%;
  }
}

@media (max-width: 430px) {
  .profileSummarySectionContainer {
    flex-direction: column;

    h2 {
      margin-bottom: 10px;
    }
  }
}
