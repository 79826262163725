.checklistIconContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.line {
  background-color: #cecfd1;
  flex: 1;
  margin: 5px 0px;
  min-height: 16px;
  width: 1px;
}

.circle {
  align-items: center;
  border: 1px solid var(--gray-035, rgb(148, 153, 162));
  border-radius: 12px;
  color: var(--gray-035, rgb(148, 153, 162));
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.icon {
  color: #cecfd1;
}

.iconDanger {
  align-items: center;
  background-color: var(--red-01, rgb(255, 0, 64));
  border-radius: 12px;
  color: #fff;
  display: flex;
  font-size: 18px;
  height: 24px;
  justify-content: center;
  user-select: none;
  width: 24px;
}
