.EmployerStatusPage {
  display: flex;
  max-width: 1152px;
  position: relative;
  width: 100vw;
}

.WelcomePage {
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 8px;
  max-width: var(--max-width-content, 752px);
  min-width: 534px;
}

.title {
  margin: 24px;
}

@media only screen and (max-width: 768px) {
  .WelcomePage {
    min-height: 0px;
    min-width: 0px;
    width: calc(100vw - 16px);
  }
}
