.profileCard {
  align-items: flex-end;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  height: 100px;
  max-width: 305px;
  width: 100%;
  &:hover {
    .linkContent {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
  }
}

.profileCardContent {
  flex: 1;
  height: 64px;
  margin-left: 16px;
}

.linkContent {
  color: var(--gray-035, rgb(148, 153, 162));
  font-size: 32px;
  height: 70px;
  line-height: 14px;
  margin: 0px 16px;
  width: 24px;
  > a {
    color: var(--gray-035, rgb(148, 153, 162));
    &:visited {
      color: var(--gray-035, rgb(148, 153, 162));
    }
    &:hover {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
  }
}

.warning {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.optional {
  color: var(--gray-035, rgb(148, 153, 162));
  font-size: 12px;
}

.profileCardContainer {
  margin-bottom: 16px;
  margin-right: 16px;
  max-width: 305px;

  position: relative;
  width: 100%;

  .checkbox {
    bottom: 15px;
    left: 15px;
    position: absolute;
  }
}
