.EditorSettings {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.firstBlock {
  margin-top: 8px;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
  > div:nth-child(2) {
    > div:nth-child(2) {
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        max-height: 50vh;
      }
    }
  }
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.featuresContainer {
  margin: 16px;
}

.titlePadding {
  padding-left: 16px;
}

.cohortBlockTitle {
  display: flex;
  margin-bottom: 8px;
  padding: 8px 0px;
}

.titleContent {
  align-items: center;
  display: flex;
  margin-right: 32px;
}

.tabContainer {
  background-color: #fff;
  display: flex;
  flex: 1;
  height: 48px;
}

.mainTab {
  align-items: center;
  color: var(--gray-025, #d1d3d4);
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 16px;
}

.mainTabActive {
  border-bottom: 1px solid var(--gray-05, rgb(60, 63, 70));
  color: var(--gray-05, rgb(60, 63, 70));
}
