.marginBottom {
  > div:nth-child(1) {
    > div:nth-child(1) {
      > span {
        display: flex;
      }
    }
  }
}

.showMore {
  color: var(--gray-05, rgb(60, 63, 70));
  font-weight: 700;
  padding-left: 0px;
  &:hover {
    color: var(--gray-05, rgb(60, 63, 70));
    font-weight: 700;
  }
}

.showMoreIcon {
  color: var(--seafoam-01, rgb(21, 218, 212));
  margin-left: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-top: 12px;
}

.row {
  display: flex;
  > div {
    flex: 1;
  }
}

.jsonContainer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
  margin-top: 12px;
  max-width: 520px;
  overflow: scroll;
  > div:nth-child(1) {
    min-width: 100%;
    width: min-content;
  }
}
