.candidateContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  width: 170px;
}

.candidateHeader {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  min-height: 48px;
  width: 100%;
}

.candidateHeaderText {
  font-weight: 500;
  margin-right: 5px;
}

.logoWrapper {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 8px;
  width: 48px;
}

.logoWrapper img {
  max-height: 100%;
  max-width: 48px;
}

.statusContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
}

.dateContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  justify-content: center;
}

.companyHeader {
  align-items: center;
  display: flex;
  margin-right: 10px;
  padding-left: 64px;
  width: 170px;
}

.otherHeader {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.blockTitle {
  display: flex;
  margin-bottom: 8px;
  padding: 8px 0px;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.candActionHeader {
  width: 110px;
}

.statusHired {
  color: var(--green-01, #5cab06);
}

.statusPassed {
  color: var(--red-01, rgb(255, 0, 64));
}

.stack {
  flex: 0.5;
  min-width: 277px;
  padding: 8px 0px;
}
