.Input {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.inputCentered {
  align-items: center;
}

.InputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 16px;

  &.noPaddingTop {
    padding-top: 0;
  }
}

.SearchContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 40px;
  padding-top: 0px;
  transition: box-shadow 0.2s ease-in, color 0.2s ease-out, background-color 0.2s ease-in;
}

.SearchContainer:hover {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

.inputStyle {
  border: 1px solid #d1d3d5;
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  height: 40px;
  padding-left: 12px;
  position: relative;
  transition: border 0.2s linear, color 0.2s, opacity 0.2s;
  width: 50%;
}

.inputNumber {
  padding: 0;
  text-align: center;
}

.inputPercent {
  padding: 0;
  padding-right: 32px;
  text-align: right;
}

.inputDays {
  padding: 0;
  padding-right: 56px;
  text-align: right;
}

.numInput {
  min-width: 50px;
}

.inputStyleSearch {
  align-items: center;
  border: 1px solid var(--gray-025, #d1d3d4);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding-left: 8px;
  transition: box-shadow 0.2s ease-in, color 0.2s ease-out, background-color 0.2s ease-in;
}

.inputStyleSearch:placeholder-shown {
  color: var(--gray-025, #d1d3d4);
  font-size: 12px;
  font-weight: 600;
}

.inputPhone {
  width: 125px;
}

.inputSmall {
  width: 67px;
}

.inputMedium {
  margin-right: 25%;
  width: 25%;
}

.inputLarge {
  margin-right: 12.5%;
  width: 37.5%;
}

.inputExtraLarge {
  max-width: 470px;
  width: 100%;
}

.inputExtraExtraLarge {
  width: 70%;
}

.inputFull {
  width: 100%;
}

.inputHugeFont {
  font-size: 24px;
}

.inputHugeFont:placeholder-shown {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 24px;
}

.inputBold {
  font-weight: 600;
}

.textArea {
  height: inherit;
  max-width: 100%;
  padding: 12px;
}

.ultraDisabledInput {
  color: var(--gray-03, rgb(155, 161, 167));
  opacity: 0.8;
}
.ultraDisabledLabel {
  color: var(--gray-03, rgb(155, 161, 167));
  opacity: 0.8;
}

.hasError {
  border: 1px solid var(--red-01, rgb(255, 0, 64));
}

.hasError:focus {
  border: 1px solid var(--red-01, rgb(255, 0, 64));
  outline: var(--red-01, rgb(255, 0, 64)) auto 3px;
}

.hasErrorTitle {
  color: var(--red-01, rgb(255, 0, 64));
}

.inputErrors {
  margin: 0px 0px 4px 0px;
  max-height: 12px;
  padding-left: 0px !important;
}

.moneyInput {
  padding-left: 17px;
  padding-right: 12px;
  text-align: right;
}

.inputSalaryErrorsFirst {
  bottom: 42px;
  position: absolute;
}
.inputSalaryErrorsSecond {
  bottom: 42px;
  position: absolute;
}

.inputOteErrorsFirst {
  bottom: 42px;
  position: absolute;
}
.inputOteErrorsSecond {
  bottom: 42px;
  position: absolute;
}

.flexColumn {
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
}

.titleLabel {
  align-items: center;
  height: 32px;
  justify-content: flex-start;
  margin-left: 0px;
}

.titleInput {
  border: none;
  padding-left: 12px;
}

.regularLabel {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.smallFake {
  height: 8px;
}

.fakeErrors {
  height: 16px;
}

.centeredLabel {
  justify-content: center;
}

.flexLabel {
  flex: 1;
}

.focus {
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  outline: var(--seafoam-01, rgb(21, 218, 212)) auto 3px !important;
  outline-offset: -2px;
}

.currencyPlaceholder {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
}

.currencyPlaceholder > i {
  align-items: center;
  background: #f7f7f7;
  border-right: 1px solid #d1d3d5;
  color: #9ba1a7;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 38px;
  justify-content: center;
  left: 1px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  width: 24px;
  z-index: 1;
}

.typePlaceholder {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
  width: 67px;
}

.typePlaceholder > i {
  align-items: center;
  background: #f7f7f7;
  border-left: 1px solid #d1d3d5;
  color: #9ba1a7;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 38px;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 1px;
  text-align: center;
  width: 24px;
}

.daysPlaceholder {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
}

.daysPlaceholder > i {
  align-items: center;
  background: #f7f7f7;
  border-left: 1px solid #d1d3d5;
  color: #9ba1a7;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 38px;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 1px;
  text-align: center;
  width: 48px;
}

.characterCounter {
  bottom: 12px;
  position: absolute;
  right: 5px;
}

.characterCounterGap {
  padding-right: 50px;
}

@media only screen and (max-width: 768px) {
  .inputStyle {
    font-size: 16px;
  }
}
