.employerContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
  width: 170px;
}

.candidateHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  padding: 8px 0px;
  padding-right: 8px;
  width: 100%;
}

.stack {
  align-items: baseline;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding-top: 8px;
  > div {
    flex: 1;
    max-width: 332px;
    min-width: 210px;
  }
}

.candidateHeaderText {
  font-weight: 500;
  margin-right: 5px;
}

.logoWrapper {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 8px;
  width: 48px;
}

.logoWrapper img {
  max-height: 100%;
  max-width: 48px;
}

.statusContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
}

.dateContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  justify-content: center;
}

.emptyContainerContent {
  align-items: center;
  color: var(--gray-04, rgb(120, 124, 127));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.emptyContainerString {
  margin-bottom: 16px;
  margin-top: 32px;
}

.blockTitle {
  display: flex;
  margin-bottom: 8px;
  padding: 8px 0px;
}

.companyHeader {
  align-items: center;
  display: flex;
  margin-right: 10px;
  padding-left: 60px;
  width: 170px;
}

.otherHeader {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.candActionHeader {
  width: 110px;
}
