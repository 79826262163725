.employeesTable {
  height: 90vh;
  margin-left: 15px;
}

.employeeFilters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 100%;
}

.newEmployeeButton {
  align-items: center;
  display: flex;
  height: 32px;
  margin-right: 8px;
}

.employeesMainContent {
  height: 100%;
  min-height: 540px;
  min-width: 524px;
  padding: 8px;
}

.employeesHeader {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  height: 48px;
  justify-content: space-between;
  width: 100%;
}

.employeesHeaderText {
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
}

.sendConfirmation {
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  font-size: 14px;
  height: 40px;
  justify-content: center;
  margin-right: 8px;
  transition: all 0.2s linear;
  user-select: none;
  width: 100%;
}

.sendConfirmationSent {
  background-color: var(--gray-05, rgb(60, 63, 70)) !important;
  color: #fff !important;
  font-size: 14px;
  height: 40px;
  width: 100%;
  &:hover {
    background-color: var(--gray-05, rgb(60, 63, 70));
    color: #fff;
  }
}

.employeeName {
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  transition: color 0.07s linear;
  width: 214px;
}

.employeeStatusHeader {
  width: 150px;
}

.firstLabel {
  margin-left: 60px;
  width: 164px;
}

.jobTitle {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  width: 140px;
}

.emailContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  width: 150px;
}

.emailHeader {
  width: 160px;
}

.actionHeader {
  max-width: 180px;
  min-width: 180px;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 170px;
}
