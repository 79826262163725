.container {
  margin: 24px;
  margin-top: 8px;
}

.padded {
  margin: 24px 0px;
}

.active {
  color: var(--seafoam-02, rgb(0, 191, 186));
  margin-bottom: 12px;
  margin-top: 24px;
}
