.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: 32px;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
}

.subheader {
  margin-top: 20px;
}

.imageContainer {
  display: flex;
  padding: 20px 20px 20px 0;
}

.detailContainer {
  align-items: center;
  display: flex;
  gap: 10px;
  margin-top: 10px;

  button {
    margin-top: 0;
  }
}

.dataContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.avatarWrapper {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 100px;
  justify-content: center;
  overflow: hidden;
  width: 100px;
}
