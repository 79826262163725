.dealInfoContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 48px;
  width: 100%;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.container {
  margin-top: 48px;
  width: 100%;
}

.containerTitle {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
  width: 100%;
}

.oteInputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.formRow {
  display: flex;
  width: 100%;
  > div:nth-child(1) {
    margin-right: 12px;
    width: 170px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-left: 24px;
  }
}

.saleCycleDaysInput {
  align-items: center;
  display: flex;
  > label:nth-child(2) {
    margin-left: 16px;
  }
}

.rangeSlider {
  background: var(--gray-025, #d1d3d4);
  border-radius: 10px;
  height: 8px;
  margin: 12px 0;
  position: relative;
  > div:nth-child(1) {
    background-color: var(--seafoam-01, rgb(21, 218, 212));
    border-radius: 10px;
    height: 100%;
    top: 0;
  }
  > div:nth-child(2) {
    background: #fff;
    border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
    border-radius: 16px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: inline-block;
    height: 24px;
    position: absolute;
    top: -8px;
    transform: translate(-12px);
    width: 24px;
  }
  /**
   * Rangeslider - Labels
   */
  > ul {
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    position: relative;
    text-align: left;
    width: 250px;

    .rangeslider__label {
      position: absolute;

      &::before {
        background: black;
        content: '';
        height: 2px;
        left: -14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        z-index: -1;
      }
    }
    > li:nth-child(1) {
      color: var(--gray-03, rgb(155, 161, 167));
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      left: 218px !important;
      position: absolute;
      top: 10px;
    }
    > li:nth-child(2) {
      color: var(--gray-03, rgb(155, 161, 167));
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      left: -24px !important;
      position: absolute;
      top: 10px;
    }
  }
}

.slider {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  width: 256px;
}

.sliderPercent {
  font-size: 24px;
  font-weight: 500;
  margin-left: 16px;
}

.dealsAverage {
  display: flex;
  flex-direction: column;
}

.saleCycleDays {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .formRow {
    display: flex;
    flex-direction: column;
    > div:nth-child(1) {
      flex: 1;
      margin-right: 0px;
    }
    > div:nth-child(2) {
      flex: 1;
      margin-left: 0px;
    }
  }
  .slider {
    flex-direction: column-reverse;
    width: calc(100vw - 96px);
  }
  .rangeSlider {
    > ul {
      width: 200px;
      > li:nth-child(1) {
        left: 168px !important;
      }
    }
  }
}
