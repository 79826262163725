.list {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0px;
}

.nestedList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 8px;
  padding-left: 0px;
}

.nestedListItem {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  border: 1px solid var(--gray-025, #d1d3d4);
  border-radius: 2px;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  margin-bottom: 4px;
  margin-right: 4px;
  min-width: max-content;
  padding: 3px 12px;
}

.mainListItem {
  margin-bottom: 16px;
  margin-right: 32px;
}

.showMore {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  padding-left: 14px;
  text-decoration: underline;
}
