.Partners {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0px 24px;
  text-align: center;
  width: 100%;
}

.Title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 36px;
  text-align: center;
}

.Container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1080px;
  width: 100%;
}

.LogosContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 24px;
  min-height: 175px;
  width: 100%;
}

.Logo {
  align-items: center;
  display: flex;
  flex: 1;
  height: auto;
  justify-content: center;
  min-height: 175px;
  min-width: 240px;
  padding: 0 32px;
  position: relative;
  img {
    max-width: 240px;
  }
}

.LogoModHealth {
  align-items: center;
  display: flex;
  flex: 1;
  height: auto;
  justify-content: center;
  min-height: 175px;
  min-width: 240px;
  padding: 0 32px;
  position: relative;
  img {
    max-width: 300px;
  }
}
