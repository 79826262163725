.ConfirmModal {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 16px;
  text-align: center;
}

.content {
  font-size: 14px;
  padding-bottom: 24px;
  text-align: center;
  width: 75%;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 75%;
}
