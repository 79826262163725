.disabledContentContainer {
  align-items: center;
  display: flex;
  flex-direction: column;

  .bannerContainer {
    img {
      width: 100%;
    }
  }

  .bannerText {
    color: var(--gray-05, rgb(60, 63, 70));
    display: flex;
    font-size: 16px;
    font-weight: 700;
    justify-content: center;
    padding: 30px 0;
  }
}
