.workHistoryContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.workHistoryContainerFirst {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  padding-top: 24px;
}

@media print {
  .workHistoryContainer,
  .workHistoryContainerFirst {
    break-inside: avoid;
  }
}

.showDetails {
  font-weight: 500;
  height: 32px;
  min-width: 118px;
}

.showDetailsIcon {
  margin-left: 8px;
}

.workNameContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
