.Modal {
  background-color: var(--gray-02, rgb(247, 247, 247));
  border-radius: 4px 4px 0px 0px;
  height: calc(100vh - 48px);
  margin-top: 48px;
  max-width: var(--max-width-content, 952px);
  position: relative;
  width: 83.3vw;
  > div:nth-child(1) {
    height: 100%;
  }
}

.ModalContainer {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.closeButton {
  align-items: center;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 24px;
  transition: background 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;
  width: 40px;
}
.closeButton:hover {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}

.closeIcon {
  font-size: 32px;
  line-height: 16px;
}

.appear {
  opacity: 0;
  transform: translateY(150px);
}
.appear.appearActive {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.25s ease-out, opacity 0.25s linear;
}

.nextButton {
  align-items: center;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  margin-bottom: 16px;
  transition: background 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;
  width: 40px;
}
.nextButton:hover {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}

.prevButton {
  align-items: center;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  transition: background 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;
  width: 40px;
}
.prevButton:hover {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}

.navElementContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  right: -56px;
  top: 0px;
}

.pageButtons {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 200px;
  position: relative;
}

.disabledNavButton {
  background-color: rgba(236, 237, 239, 0.4);
  cursor: default;
}

.disabledNavButton:hover {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 768px) {
  .navElementContainer {
    left: 84vw;
    right: 0px;
  }

  .closeButton {
    height: 28px;
    margin-top: 0px;
    width: 28px;
  }

  .prevButton {
    height: 28px;
    width: 28px;
  }

  .nextButton {
    height: 28px;
    width: 28px;
  }
}
