.firstBlock {
  height: 100%;
  margin: 8px;
  min-height: 540px;
  min-width: 524px;
  padding: 8px;
  > div:nth-child(1) {
    padding-bottom: 8px;
    padding-left: 0px;
  }
  > div:nth-child(2) {
    padding-top: 12px;
  }
}

.templatesHeader {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  height: 48px;
  width: 100%;
  > span {
    margin-right: 5px;
  }
}
