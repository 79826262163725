.AboutCompany {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.AboutItems {
  display: flex;
  margin-top: 16px;
  > div:nth-child(1) {
    margin-left: 0px;
  }
}

.AboutItem {
  align-items: center;
  display: flex;
  margin-left: 32px;
}

.AboutIcon {
  margin-right: 5px;
}

.AboutContent {
  margin-bottom: 16px;
  margin-top: 16px;
}
