.toolboxCohortsMainContent {
  height: 100%;
  padding: 8px;
}

.SearchBarOne {
  flex: 1;
  margin-right: 32px;
  max-width: 332px;
  min-width: 210px;
  > div {
    margin-bottom: 4px;
  }
}

.SearchBarTwo {
  flex: 1;
  margin-right: 32px;
  max-width: 332px;
  min-width: 210px;
  > div {
    margin-bottom: 4px;
  }
}

.searchBarFilter {
  width: 100%;
}
