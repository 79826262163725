.marginTop {
  margin-top: 24px;
}

.summaryItem {
  align-items: center;
  background-color: var(gray-01, rgb(247, 247, 247));
  border-radius: 20px;
  display: flex;
  height: 40px;
  margin-bottom: 16px;
  margin-right: 16px;
  padding-right: 16px;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.summaryItemIcon {
  font-size: 10px;
  margin-right: 12px;
}

.flexRow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}

.summaryIcon {
  height: 32px;
  margin-left: 4px;
  margin-right: 8px;
  width: 32px;
}
