.LoginContainer {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  justify-content: flex-start;
  padding: 15vh 2px 0px 2px;
}

.innerLoginContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.loginIcon {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 50px;
  width: 50px;
}

.loginTitle {
  align-items: center;
  display: flex;
  font-size: 24px;
  font-weight: 600;
  justify-content: center;
  margin: 16px 0px;
  width: 250px;
}

.loginSubtitle {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
  text-align: center;
  width: 320px;
}

.loginForm {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  padding-top: 0px;
}

.loginInput {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: 260px;
}

.loginInput label {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
}

.loginInput input {
  border: 1px solid #d1d3d5;
  font-size: 14px;
  height: 40px;
  margin-bottom: 24px;
  padding-left: 12px;
}

.submitInput {
  align-items: center;
  align-self: center;
  -webkit-appearance: none;
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  border: none;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  min-width: 96px;
  outline: 0;
  padding: 0px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  transition: box-shadow 0.1s ease-in, color 0.2s ease-out, background-color 0.2s ease-in;
  user-select: none;
  white-space: normal;
  &:hover,
  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  }
}

.inputDisabled {
  align-items: center;
  align-self: center;
  -webkit-appearance: none;
  background-color: var(--gray-02, rgb(247, 247, 247));
  border: none;
  border-radius: 2px;
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: default;
  display: flex;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  min-width: 96px;
  outline: 0;
  padding: 0px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  transition: box-shadow 0.1s ease-in, color 0.2s ease-out, background-color 0.2s ease-in;
  user-select: none;
  white-space: normal;
  &:hover,
  &:active {
    box-shadow: none;
  }
}

.loginOptions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-width: 260px;
}

.forgotPasswordLink {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  text-decoration: underline;
  transition: color 0.2s linear;
  &:visited {
    color: var(--gray-03, rgb(155, 161, 167));
  }
  &:hover {
    color: var(--gray-05, rgb(60, 63, 70));
  }
}

.signUpContainer {
  font-size: 12px;
  margin-top: 24px;
}

.signUpLink {
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-weight: 600;
  transition: color 0.2s linear;
  &:visited {
    color: var(--seafoam-02, rgb(0, 191, 186));
  }
  &:hover {
    color: var(--seafoam-01, rgb(21, 218, 212));
  }
}

.loginError {
  color: var(--red-01, rgb(255, 0, 64));
  margin-bottom: 16px;
}
