.CandidateFAQ {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  justify-content: space-between;
  width: 100vw;
  & li {
    margin-bottom: 8px;
  }
  > div:nth-child(1) {
    max-width: 1132px;
    width: 100%;
  }
  > div:nth-child(2) {
    max-width: 1132px;
    width: 100%;
  }
}

.content {
  margin: 0 12%;
  margin-bottom: 128px;
  padding-bottom: 32px;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}

.question {
  margin-top: 24px;
}

.answer {
  margin-top: 12px;
}

.supportText {
  margin-top: 24px;
}

.bold {
  font-weight: 600;
  margin-right: 5px;
}
