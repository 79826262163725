.Button {
  align-items: center;
  -webkit-appearance: none;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  -webkit-font-smoothing: antialiased;
  justify-content: center;
  outline: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  transition: box-shadow 0.1s ease-in, color 0.2s ease-out, background-color 0.2s ease-in;
  user-select: none;
  white-space: normal;
  &.disabled {
    background-color: var(--gray-02, rgb(247, 247, 247));
    border: none;
    box-shadow: none;
    color: var(--gray-03, rgb(155, 161, 167));
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    &:focus {
      outline: none;
    }
    &:hover,
    &:active {
      box-shadow: none;
      color: var(--gray-03, rgb(155, 161, 167));
    }
  }
}

.highlight {
  outline: var(--seafoam-01, rgb(21, 218, 212)) auto 3px;
}

.fullWidth {
  width: 100%;
}

.left {
  float: left;
}
.right {
  float: right;
}

.primary {
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  min-width: 96px;
  padding: 0px 16px;
  &:hover,
  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  }
  &.fakeDisabled {
    background-color: var(--gray-02, rgb(247, 247, 247));
    border: none;
    box-shadow: none;
    color: var(--gray-03, rgb(155, 161, 167));
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    &:focus {
      outline: none;
    }
    &:hover,
    &:active {
      background-color: var(--seafoam-01, rgb(21, 218, 212));
      color: #fff;
      cursor: pointer;
    }
  }
}

.primary:focus {
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  outline: var(--seafoam-01, rgb(21, 218, 212)) auto 3px;
}

.circle {
  background-color: #fff;
  border-radius: 32px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 600;
  height: 64px;
  min-width: 64px;
  width: 64px;
  &:hover,
  &:active {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.22);
  }
  &.active {
    background-color: var(--seafoam-01, rgb(21, 218, 212));
    color: #fff;
  }
}

.smallCircle {
  background-color: #fff;
  border-radius: 32px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  min-width: 32px;
  padding-top: 4px;
  width: 32px;
  &:hover,
  &:active {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.22);
  }
  &.active {
    background-color: var(--seafoam-01, rgb(21, 218, 212));
    color: #fff;
  }
}

.warning {
  background-color: var(--red-01, rgb(255, 0, 64));
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  min-width: 96px;
  padding: 0px 16px;
  &:hover,
  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  }
  &.fakeDisabled {
    background-color: var(--gray-02, rgb(247, 247, 247));
    border: none;
    box-shadow: none;
    color: var(--gray-03, rgb(155, 161, 167));
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    &:focus {
      outline: none;
    }
    &:hover,
    &:active {
      background-color: var(--red-01, rgb(255, 0, 64));
      color: #fff;
      cursor: pointer;
    }
  }
}

.warningTertiary {
  background-color: #fff;
  border: 1px solid var(--red-01, rgb(255, 0, 64));
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  min-width: 96px;
  padding: 0px 16px;
  &:hover,
  &:active {
    color: var(--red-01, rgb(255, 0, 64));
  }
  &.disabled {
    background-color: inherit;
    border: 1px solid var(--gray-03, rgb(155, 161, 167));
    box-shadow: none;
    color: var(--gray-03, rgb(155, 161, 167));
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    &:focus {
      outline: none;
    }
    &:hover,
    &:active {
      box-shadow: none;
      color: var(--gray-03, rgb(155, 161, 167));
    }
  }
  &.fakeDisabled {
    background-color: inherit;
    border: none;
    box-shadow: none;
    color: var(--gray-03, rgb(155, 161, 167));
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    &:focus {
      outline: none;
    }
    &:hover,
    &:active {
      background-color: inherit;
      color: var(--red-01, rgb(255, 0, 64));
      cursor: pointer;
    }
  }
}

.secondary {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  border: 1px solid var(--gray-025, #d1d3d4);
  border-radius: 2px;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: space-between;
  min-width: 110px;
  padding: 3px 8px;
  &:hover,
  :active {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  }
  &.active {
    background-color: var(--seafoam-01, rgb(21, 218, 212));
    color: #fff;
  }
}

.tertiary {
  background-color: inherit;
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-size: 14px;
  font-weight: 600;
  padding: 0px 16px;
  &:hover,
  &:active {
    color: var(--seafoam-01, rgb(21, 218, 212));
  }
  &.disabled {
    background-color: inherit;
    border: none;
    box-shadow: none;
    color: var(--gray-03, rgb(155, 161, 167));
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    &:focus {
      outline: none;
    }
    &:hover,
    &:active {
      box-shadow: none;
      color: var(--gray-03, rgb(155, 161, 167));
    }
  }
  &.fakeDisabled {
    background-color: inherit;
    border: none;
    box-shadow: none;
    color: var(--gray-03, rgb(155, 161, 167));
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    &:focus {
      outline: none;
    }
    &:hover,
    &:active {
      background-color: inherit;
      color: var(--seafoam-02, rgb(0, 191, 186));
      cursor: pointer;
    }
  }
}

.tertiaryThin {
  background-color: inherit;
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-size: 14px;
  font-weight: 500;
  padding: 0px 16px;
  transition: inherit;
  &:hover,
  &:active {
    color: var(--seafoam-01, rgb(21, 218, 212));
  }
  &.disabled {
    background-color: #fff;
    border: none;
    box-shadow: none;
    color: var(--gray-03, rgb(155, 161, 167));
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    &:focus {
      outline: none;
    }
    &:hover,
    &:active {
      box-shadow: none;
      color: var(--gray-03, rgb(155, 161, 167));
    }
  }
}

.quaternary {
  background-color: #0000;
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  min-width: 75px;
  padding: 0px 16px;
  &:hover,
  &:active {
    color: var(--gray-05, rgb(60, 63, 70));
  }
}

.quinary {
  background-color: #fff;
  border: 1px solid var(--gray-025, #d1d3d4);
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  min-width: 75px;
  padding: 0px 16px;
  &:hover,
  &:active {
    color: var(--gray-04, rgb(120, 124, 127));
  }
}

.centered {
  justify-content: center;
  text-align: center;
}

/* RESPONSIVE BUTTONS */
@media only screen and (max-width: 768px) {
  .circle {
    font-size: 14px;
    height: 32px;
    min-width: 32px;
    padding-top: 4px;
    width: 32px;
  }
}
