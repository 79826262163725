.EditorPersonal {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  padding: 8px;
  padding-top: 2px;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.phoneEmailInputs {
  display: flex;
  justify-content: flex-start;
  max-width: 70%;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 20px;
  }
}

.nameInputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 20px;
    max-width: 260px;
    min-width: 150px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-right: 20px;
    max-width: 260px;
    min-width: 150px;
  }
}

.permPlaceholder {
  position: relative;
  width: 37.5%;
}
.permPlaceholder input {
  padding-left: 24px;
  width: 100%;
}

.permPlaceholder > i {
  color: var(--gray-05, rgb(60, 63, 70));
  display: block;
  font-size: 14px;
  font-style: normal;
  left: 9px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 60px;
  width: 18px;
  z-index: 1;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.errorText {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.emailContent {
  align-items: center;
  display: flex;
  font-size: 14px;
  > div:nth-child(1) {
    margin-left: 8px;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  min-height: 451px;
}
