.imageInput {
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-size: 14px;
  font-weight: 600;
}

.replaceImageInput {
  font-size: 14px;
  font-weight: 500;
}

.replaceButton {
  align-items: center;
  border-radius: inherit;
  display: flex;
  height: 128px;
  justify-content: center;
  width: 128px;
}

.imageInputContainer {
  display: flex;
  justify-content: center;
}

.replaceImageInputContainer {
  display: flex;
  justify-content: center;
}

.resumeUploadNewButton {
  margin-top: 0px;
  padding: 0px;
}

.uploadImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 157px;
  width: 128px;
}

.uploadImageContainer {
  border-radius: 0;
}

.uploadImageContainer.rounded {
  border-radius: 50%;
}

.uploadImageWrapper {
  align-items: center;
  border-radius: inherit;
  display: flex;
  height: 128px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 128px;
}

.uploadImageWrapperHover {
  align-items: center;
  border-radius: inherit;
  display: flex;
  height: 128px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 128px;
}

.uploadImageWrapper > img {
  height: 128px;
  object-fit: cover;
  width: 128px;
}

.uploadImageWrapperHover > img {
  height: 128px;
  object-fit: cover;
  width: 128px;
}

.noPhotoPlaceholder {
  align-items: center;
  border: 1px dashed #9499a2;
  border-radius: inherit;
  display: flex;
  height: 128px;
  justify-content: center;
  width: 128px;
}

.hoverOverlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: inherit;
  color: #fff;
  display: flex;
  height: 128px;
  justify-content: center;
  position: absolute;
  transition: opacity 0.2s linear;
  width: 128px;
}

.hoverOverlayHide {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: inherit;
  display: flex;
  height: 128px;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s linear;
  width: 128px;
}

.errorText {
  align-self: center;
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  padding-top: 16px;
  width: max-content;
}

.highlight {
  border: 3px dashed rgb(21, 218, 212) !important;
}
