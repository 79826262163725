.EmployerRegistration {
  height: 100%;
  width: 100vw;
}

.layout {
  flex: 1;
  max-width: 100%;
  position: relative;
}

.regWrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  > div:nth-child(1) {
    margin: 0px 48px;
    max-width: 100%;
    min-width: 482px;
    padding: 0px 48px;
    width: 50%;
  }
  > form:nth-child(2) {
    margin: 0px 48px;
    margin-bottom: 32px;
    max-width: 390px;
    min-width: 390px;
    width: 390px;
  }
}

.employerSignup {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 700;
  padding: 0px 16px;
  text-align: center;
}

.errorMessage {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  text-align: center;
}
