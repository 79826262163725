.hero {
  background: #fff url('../../assets/hero-02.jpg') no-repeat bottom;
  background-size: 100%;
  clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
  display: flex;
  flex: 1;
  height: 90vh;
  justify-content: center;
  padding: 0px 24px;
  padding-top: 25vh;
  width: 100vw;
}

.heroContainer {
  display: flex;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
}

.Text {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 1132px;
  min-width: 235px;
  overflow: auto;
  position: relative;
  text-align: center;
  transform: translate3d(0, 0, 0);
  width: 100%;
  z-index: 1;
}

.row1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 0;
}

.row2 {
  font-size: 64px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  margin-bottom: 16px;
  padding: 0;
}

.row3 {
  font-size: 28px;
  font-weight: 500;
  margin: 24px 0px;
  margin-bottom: 24px;
  padding: 0;
  width: 55%;
}

.row4 {
  font-size: 12px;
  margin-bottom: 8px;
}

.row5 {
  color: #00c0ba;
  font-size: 14px;
}

.mobileHero {
  display: none;
  overflow-x: hidden;
  z-index: -1;
}

@media only screen and (max-width: 968px) {
  .row1 {
    font-size: calc(10px + 7vmin);
  }
  .row2 {
    font-size: calc(10px + 7vmin);
  }
  .row3 {
    font-size: calc(8px + 2vmin);
  }
  .row4 {
    font-size: calc(6px + 2vmin);
  }
  .row5 {
    font-size: calc(6px + 2vmin);
  }
}

@media only screen and (max-width: 768px) {
  .hero {
    background: #fff;
    padding: 0px;
    padding-top: 12vh;
  }

  .row3 {
    width: 95%;
  }

  .mobileHero {
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow-x: hidden;
    position: absolute;
    z-index: -1;
    > img {
      max-height: 48vh;
      max-width: 100vw;
      &:nth-child(1) {
        bottom: 0;
        left: -20vw;
        position: absolute;
      }
      &:nth-child(2) {
        bottom: 0;
        position: absolute;
        right: -20vw;
      }
    }
  }
}

.Request {
  background-color: #15dad5;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  min-width: 92px;
  padding: 12px 24px;
  width: auto;
  &:hover,
  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  }
}
