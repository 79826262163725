.CandidateEditorEducation {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 100%;
  overflow-x: auto;
}

.EditorEducation {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
}

.basicInfoColumnInputs {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.orgLogo {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 16px;
  width: 48px;
}

.orgLogo img {
  max-height: 100%;
  max-width: 48px;
}

.basicInfoContainer {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  border-radius: 4px;
  display: flex;
  padding: 24px;
  width: 100%;
  > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
    padding-top: 0px;
  }
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.labelSubtext {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  margin-right: 10px;
}

.basicInfoRowInputSelects {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  > button {
    flex: 1;
    margin-bottom: 8px;
    margin-right: 10px;
    margin-top: 0px;
    max-width: max-content;
  }
  > span {
    flex: 1;
    margin-bottom: 8px;
    margin-right: 10px;
    margin-top: 0px;
    max-width: max-content;
  }
}

.inputCheckbox {
  margin-left: 140px;
}

.mainSubtitle {
  font-size: 14px;
  font-weight: 600;
  margin: 0px 8px;
}

.presentLabel {
  font-size: 14px;
  margin-top: 1px;
}

.deleteEducation {
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: pointer;
  transition: all 0.1s linear;
}

.deleteEducation:hover {
  color: var(--gray-05, rgb(60, 63, 70));
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  width: 100%;
}

.buttonsRight {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.autocompleteContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

@media only screen and (max-width: 768px) {
  .basicInfoColumnInputs {
    width: 100%;
  }

  .orgLogo {
    display: none !important;
  }

  .currentPositionCheckbox {
    > div:nth-child(1) {
      margin-left: 0px;
    }
  }

  .deleteButton {
    padding: 0px;
  }
}
