.CandidateEditorEducation {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw;
}

.CandidateEditorEducationModal {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100%;
}

.EditorEducation {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}
.educationBlock {
  margin-bottom: 8px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  padding: 8px;
  padding-top: 2px;
  position: relative;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.resumeDetails {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  margin: 0 25%;
  text-align: center;
}

.resumeUploadButton {
  margin-top: 32px;
}

.uploadImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 128px;
}

.uploadImageContainer > img {
  width: 128px;
}

.candidateImagePlaceholder {
  background-color: var(--gray-01, rgb(247, 247, 247));
  border-radius: 64px;
  height: 128px;
  width: 128px;
}

.candidateImageInput {
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-size: 14px;
  font-weight: 600;
}

.nameInputs {
  display: flex;
  justify-content: flex-start;
  > div {
    flex: 1;
    margin-right: 20px;
    max-width: 250px;
    min-width: 150px;
  }
}

.basicInfoContainer {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  padding: 0px 16px;
  padding-bottom: 32px;
}

.label {
  font-size: 12px;
  margin-top: 24px;
}

.labelSubtext {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  margin-right: 10px;
  margin-top: 8px;
}

.basicInfoRowInputs {
  display: flex;
  justify-content: flex-start;
  > div {
    flex: 1;
    margin-right: 20px;
    max-width: 250px;
    min-width: 150px;
  }
}

.basicInfoRowInputSelects {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  > div {
    flex: 1;
    margin-right: 30px;
    margin-top: 0px;
    max-width: max-content;
  }
}

.inputCheckbox {
  margin-left: 220px;
}

.mainSubtitle {
  align-items: center;
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 48px;
}

.autocompleteContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  align-items: center;
  display: flex;
}

.notificationBar {
  align-items: center;
  background-color: #daf9f8;
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  padding-left: 12px;
}

.profileLocked {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  margin-right: 12px;
}

@media only screen and (max-width: 768px) {
  .EditorEducation {
    min-width: 0px;
  }
}
