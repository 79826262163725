.Block {
  position: relative;
}

.blockFadeBottom::after {
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 5%,
    rgba(255, 255, 255, 0.2) 10%,
    rgba(255, 255, 255, 0.3) 15%,
    rgba(255, 255, 255, 0.5) 20%,
    rgba(255, 255, 255, 0.5) 25%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.7) 35%,
    rgba(255, 255, 255, 0.8) 40%,
    rgba(255, 255, 255, 1) 100%
  );
  bottom: 0;
  content: '';
  height: 16px;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.blockFadeTop::before {
  background: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0));
  content: '';
  height: 15%;
  position: absolute;
  top: 49px;
  width: 100%;
}

.blockTitle {
  align-items: center;
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  min-height: 48px;
  padding-left: 16px;
  > div {
    width: 100%;
  }
}

.blockTitleSecondary {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 12px;
  > div {
    width: 100%;
  }
}

.blockTitleTertiary {
  font-size: 14px;
  font-weight: 700;
  > div {
    width: 100%;
  }
}

.blockNumberDisplay {
  color: var(--gray-04, rgb(120, 124, 127));
  font-size: 14px;
  padding-left: 5px;
}

.blockBoxShadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
}

.blockPadding {
  padding: 16px;
}

.blockMarginBottom {
  margin-bottom: 8px;
}

.blockVertPadding {
  padding: 9px 0;
}

.blockWhiteBG {
  background-color: #fff;
}

.blockFlex {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.blockChildFlex {
  flex: 1;
  overflow-y: auto;
}

.blockChildFlexDisplay {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.blockChildFlexRow {
  display: flex;
  justify-content: center;
}

.noPadding {
  padding: 0px;
}

.formTitle {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 18px;
  margin-top: 48px;
}

.largeTitleFont {
  font-size: 14px;
}

.noTitleBorder {
  border-bottom: none;
}

.blockChildRightMargin {
  margin-right: 20px;
}

.disabledBlock {
  background-color: rgba(236, 237, 239, 0.3);
  height: 100%;
  position: absolute;
  width: 100%;
}

.blockMargin {
  margin: 8px;
}
