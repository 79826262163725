.adminContent {
  color: inherit;
  width: 210px;
}

.dashboardTitleContainer {
  padding-left: 16px;
}

.adminHeader {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  margin-right: 16px;
  padding: 8px 0px;
  > div:first-child {
    margin-bottom: 4px;
    min-height: 40px;
  }
}

.adminHeaderLeft {
  align-items: center;
  display: flex;
  min-width: 450px;
}

.adminHeaderBold {
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
}

.adminHeaderText {
  font-weight: 500;
  margin-right: 5px;
}

.nameHeader {
  padding-left: 60px;
  width: 220px;
}

.updatedAtContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  width: 105px;
}

.updatedAtHeader {
  display: flex;
  justify-content: center;
  margin-right: 6px;
  width: 115px;
}

.lastActivityContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-align: center;
  width: 100px;
}

.lastActivityHeader {
  display: flex;
  justify-content: center;
  width: 110px;
}
