.candidateContent {
  color: inherit;
  width: 210px;
}

.dashboardTitleContainer {
  padding-left: 16px;
}

.checkboxHeader {
  width: 30px;
}

.candidateHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin-right: 16px;
  padding: 8px 0px;
}

.candidateHeaderLeft {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.candidateHeaderRight {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.onboardingStatus {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin-left: 50px;
  text-transform: capitalize;
  width: 133px;
}

.conciergeStatus {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  width: 133px;
}

.pendingStatus {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 88px;
}

.approvedStatus {
  color: var(--green-01, #5cab06);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 88px;
}

.archivedStatus {
  color: var(--gray-04, rgb(120, 124, 127));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 88px;
}

.activeStatus {
  align-items: center;
  color: var(--green-01, #5cab06);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 70px;
}
.inactiveStatus {
  align-items: center;
  color: var(--red-01, rgb(255, 0, 64));
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  text-transform: capitalize;
  width: 70px;
}

.locationContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  text-transform: capitalize;
  width: 130px;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: 30px;
  width: 100px;
}

.capitalizeSubtitle {
  text-transform: capitalize;
}

.nameHeader {
  padding-left: 45px;
  width: 160px;
}

.statusHeader {
  min-width: 98px;
}

.onboardingStatusHeader {
  min-width: 143px;
}

.conciergeStatusHeader {
  justify-content: center;
  min-width: 143px;
}

.candActionHeader {
  width: 110px;
}

.updatedAtContent {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-transform: capitalize;
  width: 80px;
}

.updatedAtHeader {
  display: flex;
  justify-content: center;
  width: 90px;
}

.date {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
}

.iconDanger {
  align-items: center;
  background-color: var(--red-01, rgb(255, 0, 64));
  border-radius: 18px;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 18px;
  justify-content: center;
  user-select: none;
  width: 18px;
}

.resumeUploadArea {
  input {
    display: none;
  }

  .resumeUploadBtn {
    align-items: center;
    background-color: var(--gray-01, rgb(247, 247, 247));
    border: 1px solid var(--gray-025, #d1d3d4);
    border-radius: 2px;
    color: var(--gray-05, rgb(60, 63, 70));
    cursor: pointer;
    display: flex;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    justify-content: center !important;
    justify-content: space-between;
    min-width: 110px;
    padding: 3px 8px;
    text-align: center;
    &:hover,
    :active {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    }
    &.active {
      background-color: var(--seafoam-01, rgb(21, 218, 212));
      color: #fff;
    }
  }
}

.progressModalContainer {
  align-items: center;
  background: #fff;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  min-height: 100px;
  padding: 20px;
  width: 250px;

  button.closeButton {
    align-self: flex-end;
    height: 25px;
    margin-top: 20px;
    min-width: unset;
    width: 60px;
  }
}

.progressModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bulkActionsSelect {
  width: 165px;
}

.selectCheckbox {
  padding-left: 5px;
}
