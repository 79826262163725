.container {
  margin: 24px;
  margin-top: 8px;
}

.padded {
  margin-top: 24px;
}

.inactive {
  color: var(--red-01, rgb(255, 0, 64));
  margin-bottom: 12px;
  margin-top: 24px;
}

.profileLocked {
  font-weight: 600;
  margin-left: 8px;
}

.active {
  color: var(--seafoam-02, rgb(0, 191, 186));
  margin-bottom: 12px;
  margin-top: 24px;
}

.passive {
  color: var(--seafoam-02, rgb(0, 191, 186));
  margin-bottom: 12px;
  margin-top: 24px;
}

.resetStatus {
  color: var(--gray-05, rgb(60, 63, 70));
}

.inputContainer {
  margin-top: 24px;
  > div:nth-child(1) {
    > div:nth-child(1) {
      justify-content: center;
    }
  }
}

.contentContainer {
  position: relative;
}
