.recentCompensationContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 48px;
  width: 100%;
}

.containerTitle {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
  width: 100%;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.formRow {
  display: flex;
  width: 100%;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 12px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-left: 12px;
  }
  > label:nth-child(1) {
    flex: 1;
    margin-right: 12px;
  }
  > label:nth-child(2) {
    flex: 1;
    margin-left: 12px;
  }
}

.oteInputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0;
}
