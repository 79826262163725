.editorButton {
  border: 1px solid #d1d3d4;
  border-radius: 0px !important;
  color: var(--gray-05, rgb(60, 63, 70));
}

.editor {
  border-radius: 0px;
  color: var(--gray-05, rgb(60, 63, 70));
  &.focus {
    outline: var(--seafoam-01, rgb(21, 218, 212)) auto 3px;
    outline-offset: -2px;
  }
  > div > div {
    margin: 0px;
  }
  > div > div > div > button {
    border: 1px solid #d1d3d4;
    border-radius: 0px !important;
    color: var(--gray-05, rgb(60, 63, 70));
    margin-right: 5px !important;
  }
}

.container {
  max-width: calc(100vw - 64px);
  > div {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif; /* 1 */
    min-height: 200px;
  }
  > div > div {
    font-size: 14px !important;
  }
  > div:nth-child(1) > div:nth-child(2) {
    display: flex;
    flex: 1;
    flex-direction: column;
    > div {
      display: flex;
      flex: 1;
      flex-direction: column;
      > div:nth-child(2) {
        display: flex;
        flex: 1;
        flex-direction: column;
        > div {
          flex: 1;
        }
      }
    }
  }
  > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    min-height: 135px;
  }
}
