.adminRequestOptionsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-height: max-content;
  width: 100%;
}

.emptyTable {
  align-items: center;
  border: 1px solid var(--gray-hairline, #dbdbdb);
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: center;
  min-height: 250px;
  overflow-y: scroll;
  width: 100%;
  > .row:nth-child(1) {
    border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
    cursor: default;
    padding: 8px 0px;
  }
}
.table {
  border: 1px solid var(--gray-hairline, #dbdbdb);
  height: 250px;
  min-height: 250px;
  overflow-y: scroll;
  width: 100%;
  > .row:nth-child(1) {
    border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
    cursor: default;
    padding: 8px 0px;
  }
}

.row {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 4px 0px;
  > div:nth-child(2) {
    flex: 2;
    padding-right: 8px;
  }
}

.mainRow {
  &:hover {
    background-color: var(--gray-01, rgb(247, 247, 247));
  }
}

.shareCell {
  display: flex;
  justify-content: center;
  margin: 0px 8px;
  min-width: 36px;
  & a {
    color: var(--gray-04, rgb(120, 124, 127));
    &:visited {
      color: var(--gray-04, rgb(120, 124, 127));
    }
    &:hover {
      color: var(--gray-04, rgb(120, 124, 127));
    }
  }
}

.selectedCell {
  color: var(--seafoam-01, rgb(21, 218, 212));
  display: flex;
  justify-content: center;
  margin-left: 8px;
  min-width: 36px;
}

.cell {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.requestIntroButtons {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 32px;
  min-height: 40px;
  width: 100%;
}

.createNewReqButton {
  align-self: flex-start;
  margin-bottom: 8px;
  margin-left: 28px;
  min-height: 40px;
}

.selected {
  color: var(--seafoam-01, rgb(0, 191, 186));
  > div:nth-child(2) {
    font-weight: 700;
  }
}

.avatarContainer {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-bottom: 16px;
  min-height: 48px;
  min-width: 48px;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  width: 48px;
}

.avatar {
  height: 48px;
  object-fit: cover;
  width: 48px;
}

.header {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
}

.subheader {
  margin-bottom: 24px;
  text-align: center;
  width: 448px;
}

.smallIcon {
  position: relative;
  top: 5px;
}

.nextButton {
  width: 448px;
}

@media only screen and (max-width: 768px) {
  .row {
    div:nth-child(4) {
      display: none;
    }
  }
}
