.Modal {
  background-color: var(--gray-02, rgb(247, 247, 247));
  /* min-width: 90vw; */
  height: 100vh;
  max-width: var(--max-width-total, 1152px);
  position: relative;
  width: 90vw;
}

.closeButton {
  align-items: center;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 6vw;
  justify-content: center;
  margin-bottom: 16px;
  max-height: 64px;
  max-width: 64px;
  min-height: 32px;
  min-width: 32px;
  transition: background 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;
  width: 6vw;
  &:hover {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  }
}

.closeIcon {
  font-size: 32px;
  line-height: 16px;
}

.appear {
  opacity: 0;
  transform: translateX(-200px);
}
.appear.appearActive {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s ease-in-out;
}

.nextButton {
  align-items: center;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 6vw;
  justify-content: center;
  margin-bottom: 16px;
  max-height: 64px;
  max-width: 64px;
  min-height: 32px;
  min-width: 32px;
  transition: background 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;
  width: 6vw;
  &:hover {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  }
}

.prevButton {
  align-items: center;
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 6vw;
  justify-content: center;
  margin-bottom: 16px;
  max-height: 64px;
  max-width: 64px;
  min-height: 32px;
  min-width: 32px;
  transition: background 0.3s ease-in, color 0.3s ease-in, border 0.3s ease-in;
  width: 6vw;
  &:hover {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  }
}

.navElementContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding-left: 2vw;
  padding-top: 8px;
  position: relative;
  width: 10vw;
}

.pageButtons {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 200px;
  position: relative;
}

.disabledNavButton {
  background-color: rgba(236, 237, 239, 0.5);
  cursor: default;
}

.disabledNavButton:hover {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
}
