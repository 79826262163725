.profileRecruitmentContainer {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}

.question {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  margin-bottom: 13px;
}

.answer {
  display: flex;
  font-size: 14px;
  margin-bottom: 32px;
}

.marginBottom {
  margin-bottom: 36px;
}
