.promptModal {
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 325px;
  padding: 16px;
}

.promptContent {
  font-size: 18px;
  padding-bottom: 16px;
  padding-top: 16px;
  text-align: center;
}

.inputContent {
  padding-bottom: 32px;
}

.inputsRow {
  display: flex;
  justify-content: space-between;
}

.inputsMain {
  > div:nth-child(1) {
    margin-bottom: 24px;
  }
}

.promptButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.promptTitle {
  display: flex;
  flex-direction: column;
}

.subtitle {
  font-size: 14px;
  font-weight: 500;
  padding-top: 16px;
  text-align: start;
}

.confirm {
  height: 32px;
  margin-left: 8px;
}

.inputContainerNoPad {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inputsColumn {
  display: flex;
  flex-direction: column;
  width: 48%;
  > button {
    margin-bottom: 4px;
  }
}

.inputRadio {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100% !important;
  > div {
    margin: 8px 0px;
  }
}
