.Dropdown {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  border: 1px solid var(--gray-025, #d1d3d4);
  border-radius: 2px;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 0;
  padding: 3px 8px;
  position: relative;
  transition: box-shadow 0.1s ease-in, color 0.2s ease-out, background-color 0.2s ease-in;
  user-select: none;
  width: 100%;

  &.disabled {
    background-color: var(--gray-02, rgb(247, 247, 247));
    color: var(--gray-03, rgb(155, 161, 167));
    cursor: default;
  }

  .DefaultLabel {
    margin-right: 8px;
  }

  &.active {
    background-color: var(--gray-05, rgb(60, 63, 70));
    border: 1px solid var(--gray-05, rgb(60, 63, 70));
    color: #fff;

    .OptionsContainer {
      cursor: default;
      top: 33px;
    }
  }
}

.Dropdown:hover {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  &.disabled {
    box-shadow: none;
    cursor: default;
  }
}

.OptionsContainer {
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  color: initial;
  min-height: 30px;
  min-width: 50px;
  position: absolute;
  top: -10000px;
  z-index: 200;
}

.DefaultLabel {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin-right: 8px;
}

.left {
  .OptionsContainer {
    right: 0;
  }
}

.above {
  &.active {
    .OptionsContainer {
      bottom: 33px;
      top: initial;
    }
  }
}
