.profileContactButton {
  height: 40px;
  margin-bottom: 8px;
  width: 100%;
}

.seafoam {
  color: var(--seafoam-01, rgb(21, 218, 212));
  font-size: 16px;
  margin-bottom: 1px;
  margin-right: 8px;
}

.contactedNote {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  margin-bottom: 8px;
}

.fakeContactOne {
  background-color: #fff;
  border-radius: 4px 4px 4px 4px;
  height: 40px;
  margin-bottom: 8px;
  max-height: 40px;
  opacity: 0;
  width: 100%;
}

.fakeContact {
  background-color: var(--gray-01, rgb(247, 247, 247));
  border-radius: 4px 4px 4px 4px;
  height: 40px;
  margin-bottom: 8px;
  max-height: 40px;
  opacity: 0.6;
  width: 100%;
}

.fakeContactTwo {
  background-color: var(--gray-01, rgb(247, 247, 247));
  border-radius: 4px 4px 4px 4px;
  height: 40px;
  margin-bottom: 8px;
  max-height: 40px;
  opacity: 0.6;
  width: 100%;
}

.hideButton {
  flex: 1;
  margin-bottom: 8px;
  margin-right: 8px;
  transition: all 0.2s linear;
}

.undoButton {
  flex: 1;
  margin-bottom: 8px;
  margin-right: 0px;
  transition: all 0.2s linear;
}

.row {
  display: flex;
  width: 100%;
  > button {
    width: 50%;
  }
}

.notBookmarked {
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  transition: all 0.2s linear;
  user-select: none;
}

.bookmarked {
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  transition: all 0.2s linear;
  user-select: none;
}

.requestIntroModal {
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
}

.requestIntroContent {
  font-size: 14px;
  margin-bottom: 16px;
}

.requestIntroButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
