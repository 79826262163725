.toolboxBatchRequestsMainContent {
  height: 100%;
  padding: 8px;
}

.cohortModal {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-height: 60vh;
  min-height: 300px;
  padding: 12px;
  > div:nth-child(1) {
    max-height: calc(60vh - 76px);
  }
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  width: 100%;
}

.cohortBlockTitle {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  padding: 8px 0px;
}

.cohortHeaderText {
  font-weight: 500;
  margin: 0px 5px;
}

.titleContent {
  align-items: center;
  display: flex;
  margin-right: 32px;
}

.cohortFilterContainer {
  align-items: center;
  border: 1px solid var(--gray-03, rgb(155, 161, 167));
  border-radius: 2px;
  display: flex;
  div:nth-child(1) {
    border-radius: 2px 0px 0px 2px;
    border-right: 1px solid var(--gray-03, rgb(155, 161, 167));
  }
  div:nth-child(2) {
    border-left: 1px solid var(--gray-03, rgb(155, 161, 167));
    border-radius: 0px 2px 2px 0px;
  }
}

.cohortFilter {
  align-items: center;
  background-color: var(--gray-01, rgb(247, 247, 247));
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  width: 84px;
}

.activeFilter {
  background-color: var(--gray-03, rgb(155, 161, 167));
  color: #fff;
}

.activeContent {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.padded {
  margin-top: 8px;
}

.noWidth {
  max-width: max-content !important;
  width: max-content;
}
