.EducationOrgData {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  min-height: 48px;
  transition: color 0.07s linear;
}

.EducationOrgData:hover {
  color: var(--seafoam-02, rgb(0, 191, 186));
}

.educationOrgDataInfoWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 48px;
  justify-content: center;
  min-width: 0;
  padding-left: 12px;
}

.educationOrgDataInfoWrapperShort {
  width: 135px;
}

.educationOrgDataAvatarWrapper {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  width: 48px;
}
.educationOrgDataAvatarLargeWrapper {
  align-items: center;
  display: flex;
  height: 128px;
  justify-content: center;
  position: relative;
  width: 128px;
}
.educationOrgDataAvatarSmallWrapper {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  position: relative;
  width: 32px;
}

.educationOrgDataAvatarWrapper img {
  max-height: 100%;
  max-width: 48px;
}

.educationOrgDataAvatarLargeWrapper img {
  max-height: 100%;
  max-width: 128px;
}
.educationOrgDataAvatarSmallWrapper img {
  max-height: 100%;
  max-width: 32px;
}

.educationOrgDataTitle {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
.educationOrgDataTitleSmall {
  font-size: 12px;
}

.educationOrgName {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.educationOrgDataSubtitle {
  align-items: flex-start;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.educationOrgDataSubtitleBold {
  font-weight: 600;
}
