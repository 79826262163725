.TableHeader {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  height: 20px;
  justify-content: space-between;
  min-height: 20px;
  padding-right: 15px;
  &.adminReqTable {
    div:nth-child(2) {
      @media (max-width: 582px) {
        display: none;
      }
    }
  }
  &.reqTable {
    div:nth-child(7) {
      @media (max-width: 1206px) {
        display: none;
      }
    }
    div:nth-child(6) {
      @media (max-width: 1116px) {
        display: none;
      }
    }
    div:nth-child(3) {
      @media (max-width: 1026px) {
        display: none;
      }
    }
    div:nth-child(4) {
      @media (max-width: 896px) {
        display: none;
      }
    }
    div:nth-child(5) {
      @media (max-width: 796px) {
        display: none;
      }
    }
    div:nth-child(2) {
      @media (max-width: 524px) {
        display: none;
      }
    }
  }
  &.candTable {
    div:nth-child(3) {
      @media (max-width: 862px) {
        display: none;
      }
    }
    > div:nth-child(4) {
      @media (max-width: 612px) {
        display: none;
      }
    }
    > div:nth-child(2) {
      @media (max-width: 527px) {
        display: none;
      }
    }
  }
  &.decidedTable {
    div:nth-child(3) {
      @media (max-width: 527px) {
        display: none;
      }
    }
  }
}

.TableHeaderCell {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 12px;
  font-weight: 600;
}

.clickable {
  cursor: pointer;
}

.sortable {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}

.Arrow {
  color: var(--gray-025, #d1d3d4);
}

.UpArrow {
  color: var(--gray-025, #d1d3d4);
  margin-bottom: -4px !important;
}

.active {
  color: var(--seafoam-01, rgb(21, 218, 212)) !important;
}
