.HeaderContainer {
  background-color: #fff;
  display: flex;
  justify-content: center;
  position: fixed;
  transition: box-shadow 0.15s linear;
  width: 100vw;
  z-index: 4;
}

.HeaderTransparent {
  background-color: #fff;

  .button {
    color: #fff;
    &:hover,
    &:active {
      color: #fff;
    }
  }
}

.Header {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  height: 48px;
  justify-content: space-between;
  max-width: var(--max-width-total, 1152px);
  z-index: 4;
}

.headerBoxShadow {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.LogoBlock {
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: 16px;
  > a {
    height: 20px;
    margin-right: 16px;
  }
}

.navLinksContainer {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
}

.navLink {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 500;
  padding: 0px 12px;
  text-align: center;
  transition: color 0.2s linear;
  &::before {
    color: transparent;
    content: attr(data-title);
    display: block;
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    white-space: nowrap;
  }
}

.navLink:hover {
  color: var(--gray-05, rgb(60, 63, 70));
}

.button {
  align-items: center;
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  border-radius: 2px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  margin-left: 16px;
  min-width: 146px;
  padding: 0px 16px;
  &:hover,
  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  }
}

.activeNavLink {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 0 12px;
  position: relative;
  &::after {
    border-bottom: 2px solid var(--seafoam-01, rgb(21, 218, 212));
    bottom: -5px;
    content: '';
    display: block;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    width: 20px;
  }
}

.darkLogo {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 22px;
  width: 176px;
}

.NavDropdown {
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  color: var(--gray-05, rgb(60, 63, 70));
  display: none;
  min-width: 153px;
  position: absolute;
  right: 0px;
  top: 49px;
  z-index: 200;
}

.NavHamburger {
  display: none;
  height: 48px;
  transition: 0.2s all linear;
  width: 32px;
}

.seafoam {
  color: var(--seafoam-01, rgb(21, 218, 212));
}

@media only screen and (max-width: 768px) {
  .NavBlock {
    display: none;
  }

  .NavDropdown {
    display: flex;
    flex-direction: column;
  }
  .NavHamburger {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  .navLink {
    align-items: center;
    color: var(--gray-03, rgb(155, 161, 167));
    display: flex;
    height: 46px;
    min-width: max-content;
    padding: 0px 12px;
    width: 100% !important;
    &::after {
      display: none;
    }
  }

  .activeNavLink {
    color: var(--seafoam-02, rgb(0, 191, 186));
    font-weight: 600;
    padding: 0px 12px;
    &:hover {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
  }
}
