.profileSocialButton {
  background-color: #fff;
  /* border-left: 1px solid var(--gray-hairline, #dbdbdb); */
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 500;
  height: 14px;
  line-height: 14px;
  padding: 0px 8px;
  > i {
    margin-right: 5px;
  }
}

.socialMediaButton {
  align-items: center;
  display: flex;
  text-decoration: underline;
}
