.CompanyForm {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.titleBlock {
  margin: 8px;
}

.scrollDiv {
  flex: 1;
  padding: 8px;
  padding-top: 2px;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.companyProfileHeader {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-right: 20px;
}

.companyProfileTitle {
  font-size: 14px;
  font-weight: 600;
}

.basicInfoTop {
  display: flex;
  flex-direction: column;
}

.noPadding {
  padding-top: 0px;
}

.labelFlex {
  display: flex;
  justify-content: space-between;
  max-width: 470px;
  width: 100%;
}

.label {
  display: flex;
  justify-content: space-between;
  max-width: 470px;
  padding-top: 16px;
  width: 100%;
}

.sublabel {
  font-size: 12px;
  font-weight: 500;
  padding-top: 4px;
}

.hairline {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  height: 1px;
  padding-top: 16px;
  width: 100%;
}

.valid {
  color: var(--green-01, #5cab06);
}

.invalid {
  color: var(--red-01, rgb(255, 0, 64));
}

.pending {
  color: var(--orange-01, rgb(255, 167, 0));
}

.mainBlock {
  margin: 8px;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}

.containerMain {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 300px;
}

.needContact {
  text-align: center;
}

.integrationButton {
  margin: 16px 0px;
}
