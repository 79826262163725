.CompanyHeader {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
}

.headerLogoContainer {
  align-items: center;
  display: flex;
  height: 128px;
  justify-content: center;
  margin-right: 16px;
  position: relative;
  width: 128px;
}

.headerLogoContainer img {
  max-height: 100%;
  max-width: 128px;
}

.headerDetailsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 113px;
}

.headerName {
  font-size: 24px;
  font-weight: 600;
}

.headerIndustry {
  color: var(--gray-03, rgb(155, 161, 167));
}

.headerTagline {
  margin-bottom: 12px;
}

.headerDetailContainer {
  display: flex;
}

.headerDeets {
  align-items: center;
  display: flex;
}

.headerDetail {
  align-items: center;
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  font-size: 12px;
  margin-right: 8px;
}

.headerDetailBull {
  align-items: center;
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  font-size: 8px;
  margin-right: 8px;
}

.headerLink {
  font-size: 12px;
  > a {
    color: var(--gray-03, rgb(155, 161, 167));
  }
  > a:visited {
    color: var(--gray-03, rgb(155, 161, 167));
  }
  > a:hover {
    color: var(--gray-03, rgb(155, 161, 167));
  }
}

.sizeMax {
  margin-right: 4px;
}
