.EmployeeData {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  min-height: 48px;
  transition: color 0.07s linear;
}

.EmployeeData:hover {
  color: var(--seafoam-02, rgb(0, 191, 186));
}

.employeeDataInfoWrapper {
  display: flex;
  flex-direction: column;
  height: 48px;
  padding-left: 12px;
}

.employeeDataInfoWrapperShort {
  width: 135px;
}

.employeeDataAvatarWrapper {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 48px;
}
.employeeDataAvatarLargeWrapper {
  align-items: center;
  border-radius: 64px;
  display: flex;
  height: 128px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 128px;
}
.employeeDataAvatarSmallWrapper {
  align-items: center;
  border-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 32px;
}

.employeeDataAvatarWrapper img {
  height: 48px;
  object-fit: cover;
  width: 48px;
}

.employeeDataAvatarLargeWrapper img {
  height: 128px;
  object-fit: cover;
  width: 128px;
}
.employeeDataAvatarSmallWrapper img {
  height: 32px;
  object-fit: cover;
  width: 32px;
}

.employeeDataTitle {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.employeeDataTitleSmall {
  font-size: 12px;
}

.employeeName {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.employeeDataSubtitle {
  align-items: flex-start;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.employeeDataSubtitleBold {
  font-weight: 600;
}

.positionTitle {
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.companyName {
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sideTable {
  padding-bottom: 12px;
}
