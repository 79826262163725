.SearchBarOne {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  > div {
    flex: 1;
    max-width: 332px;
    min-width: 210px;
    > div {
      height: min-content;
      margin-bottom: 4px;
      min-height: 40px;
    }
  }
  > div:nth-child(1) {
    margin-right: 32px;
  }
}

.searchBarFilter {
  width: 100%;
}

.checkbox {
  margin-left: 0px;
  > label {
    align-items: center;
    > span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
