.container {
  cursor: pointer;
  display: flex;
  position: relative;
}

.optionsContainer {
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-direction: column;
  font-size: 14px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 175px;
  z-index: 200;
}

.option {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  height: 46px;
  justify-content: flex-start;
  padding: 0px 12px;
}

.option:hover {
  background-color: var(--gray-01, rgb(247, 247, 247));
}

.internalContainer {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
}

.caret {
  color: var(--seafoam-01, rgb(21, 218, 212)) !important;
  margin-left: 5px;
}

.title {
  max-width: 118px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
