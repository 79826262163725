.CompanyAbout {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  line-height: 1.5;
}

.markdown {
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  > div {
    margin-bottom: 12px;
  }

  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}
