.Header {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.company {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 3px;
}

.reqTitle {
  font-size: 14px;
}

.location {
  color: var(--gray-04, rgb(120, 124, 127));
}

.headerLogoContainer {
  align-items: center;
  display: flex;
  height: 128px;
  justify-content: center;
  margin-right: 48px;
  position: relative;
  width: 128px;
}

.headerLogoContainer img {
  max-height: 100%;
  max-width: 128px;
}

.rightContent {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.mainDetails {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
}

.links {
  display: flex;
  & a {
    color: var(--gray-05, rgb(60, 63, 70));
    &:visited {
      color: var(--gray-05, rgb(60, 63, 70));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
  > div:first-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  > div {
    &:after {
      content: '|';
      margin-left: 10px;
    }
  }

  > div:last-child {
    &:after {
      content: '';
      margin-left: 10px;
    }
  }
}

.headerLink {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 16px;
  text-decoration: underline;
}

@media only screen and (max-width: 586px) {
  .headerLogoContainer {
    margin-right: 0px;
  }
  .Header {
    align-items: center;
    flex-direction: column;
  }
  .rightContent {
    margin-top: 24px;
    text-align: center;
    width: 100%;
  }
  .links {
    justify-content: center;
    margin-right: -10px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 898px) {
  .headerLogoContainer {
    margin-right: 0px;
  }
  .Header {
    align-items: center;
    flex-direction: column;
  }
  .rightContent {
    margin-top: 24px;
    text-align: center;
    width: 100%;
  }
  .links {
    justify-content: center;
    margin-right: -10px;
  }
}
