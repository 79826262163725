.Careers {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
  h2 {
    color: var(--seafoam-02, rgb(0, 191, 186));
    font-size: 12px;
    margin-bottom: 0px;
    text-align: center;
    text-transform: uppercase;
  }
  h1 {
    margin-top: 4px;
  }
  > div:nth-child(1) {
    margin: 0 auto;
    margin-bottom: 128px;
    max-width: 548px;
    text-align: center;
    width: 100%;
  }
  > div:nth-child(2) {
    margin: 0 auto;
    max-width: 1132px;
    width: 100%;
  }
}

.angelListJobs {
  color: var(--gray-05, rgb(60, 63, 70));
  margin-bottom: 24px;
  margin-top: 5px;
}

.headerTwo {
  display: flex;
  justify-content: center;
}

.Content {
  margin-bottom: 48px;
  max-width: 648px;
}

.jobs {
  align-items: center;
  display: flex;
  margin-bottom: 48px;
}

.button {
  align-items: center;
  background-color: var(--seafoam-01, rgb(21, 218, 212));
  border-radius: 2px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  margin-left: 16px;
  min-width: 146px;
  padding: 0px 16px;
  width: min-content;
  &:hover,
  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  }
}
