.CompanyPress {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
}

.pressLink {
  color: var(--seafoam-02, rgb(0, 191, 186));
  max-width: 300px;
  overflow: hidden;
  padding-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pressLink a {
  color: var(--seafoam-02, rgb(0, 191, 186));
  &:visited {
    color: var(--seafoam-02, rgb(0, 191, 186));
  }
  &:hover {
    color: var(--seafoam-01, rgb(12, 218, 212));
  }
}

.companyPressList {
  list-style: none;
  margin: 0;
  padding: 0;
}
