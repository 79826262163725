.summary {
  line-height: 1.5;
  margin-top: 24px;
}

.summaryHide {
  visibility: hidden;
}

.showMoreButton {
  position: relative;
  top: -24px;
}

.summaryClosed {
  margin-top: -2px;
  max-height: 80px;
  overflow: hidden;
}

.summaryClosedFade {
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 5%,
    rgba(255, 255, 255, 0.2) 10%,
    rgba(255, 255, 255, 0.3) 15%,
    rgba(255, 255, 255, 0.5) 20%,
    rgba(255, 255, 255, 0.5) 25%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.7) 35%,
    rgba(255, 255, 255, 0.8) 40%,
    rgba(255, 255, 255, 1) 100%
  );
  content: '';
  height: 32px;
  pointer-events: none;
  position: relative;
  top: -32px;
  width: 100%;
}

.blockContainer {
  margin-bottom: 36px;
}

@media print {
  .blockContainer {
    break-inside: avoid;
  }
}

.markdownSummary {
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  > div {
    margin-bottom: 12px;
  }

  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}

.summaryItem {
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-bottom: 12px;
  margin-top: 24px;
}

.summaryItemIcon {
  font-size: 10px;
  margin-right: 12px;
}

.calendarIcon {
  color: var(--gray-04, rgb(120, 124, 127));
  margin-right: 5px;
}

.summaryIcon {
  height: 32px;
  margin-left: 4px;
  margin-right: 8px;
  width: 32px;
}

.showMoreIcon {
  margin-left: 8px;
}
