.readyContainer {
  display: block;
  width: 70%;

  h1 {
    font-size: 1.8em;
  }

  table {
    border-collapse: collapse;
    font-size: 20px;
    margin-bottom: 40px;
    width: 100%;

    tbody {
      tr:nth-child(odd) {
        background-color: var(--gray-02);
      }

      a {
        color: var(--gray-05);
      }
    }
  }
}
