.CandidateEditorSkills {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100vw;
}

.CandidateEditorSkillsModal {
  color: var(--gray-05, rgb(60, 63, 70));
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  overflow-x: auto;
  width: 100%;
}

.EditorSkills {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 534px;
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.skillsBlock {
  margin-bottom: 8px;
}

.candReqBlock {
  margin-bottom: 8px;
}

.qAndABlock {
  margin-bottom: 8px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  padding: 8px;
  padding-top: 2px;
  position: relative;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.basicInfoContainer {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  padding-bottom: 32px;
  padding-top: 24px;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.aggroQuestionContainer {
  width: 70%;
}

.aggroAnswerContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.checkboxContainer {
  padding-top: 24px;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.profileLocked {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.highlight {
  border: 3px solid rgb(21, 218, 212) !important;
}

@media only screen and (max-width: 768px) {
  .EditorSkills {
    min-width: 0px;
  }
}
