.Logo {
  height: 20px;
  width: 168px;
}

.Logo:after {
  content: url('./assets/rm-long-logo.svg');
}

@media only screen and (max-width: 768px) {
  .Logo {
    height: 20px;
    width: 20px;
  }

  .Logo:after {
    content: url('./assets/rm-short-logo.svg');
  }
}
