.quickEditorContainer {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  position: relative;
  width: 100%;

  .linksSectionPlaceholder {
    background: var(--gray-02, rgb(247, 247, 247));
    height: 220px;
    width: 320px;
  }

  .linkSection {
    align-items: center;
    display: grid;
    grid-gap: 7px;
    grid-template-columns: 3fr 2fr 2fr;
    margin-bottom: 20px;

    button {
      box-sizing: border-box;
      height: 30px;
      min-width: unset;
      width: 90px;
    }

    .turboHeader {
      grid-column: 2 / 4;
      grid-row: 1 / 2;
      text-align: center;
    }

    .fullHeader {
      grid-column: 4 / 6;
      grid-row: 1 / 2;
      text-align: center;
    }

    .shareIcon {
      margin-right: 10px;
    }

    .copiedLinkIcon {
      color: var(--seafoam-01, rgb(21, 218, 212));
      margin-right: 10px;
    }
  }

  .bullhornWrapper {
    margin: -20px 0 0 -20px;
  }

  .editorSection {
    .inputsArea {
      .moneyInputsRow {
        display: flex;
        gap: 10px;
      }
    }
  }

  .blockTitle {
    align-items: center;
    background-color: #fff;
    display: flex;
    height: 48px;
    justify-content: space-between;
    padding-left: 16px;
  }

  .activeInputContainer {
    min-width: 184px;
    padding-left: 16px;
  }
}
