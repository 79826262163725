.inputStyle {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
}

.focus {
  outline: var(--seafoam-01, rgb(21, 218, 212)) auto 3px;
  padding-left: 12px;
}

.container {
  display: flex;
  flex-direction: column;
}

.labelStyle {
  margin-bottom: 16px;
  margin-top: 24px;
}

.flexFitContainer {
  width: 100%;
  > div:nth-child(1) > div {
    max-width: 172px;
  }
  > div:nth-child(1) > div:last-child {
    margin-right: 0px;
  }
  > div:nth-child(1) > div:nth-child(3) {
    margin-right: 0px;
  }
  > div:nth-child(1) > div:nth-child(6) {
    margin-right: 0px;
  }
}

.flexFit {
  > div {
    flex: 1;
    min-width: 30%;
  }
}
