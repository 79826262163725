.modal {
  align-items: center;
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
}

.actionButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
