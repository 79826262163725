.ReadyContainer {
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  height: 100%;
  max-width: var(--max-width-total, 1152px);
  width: 100%;
}

.backLinkPresent {
  height: calc(100% - 32px);
  position: relative;
  top: 32px;
}

.scroll {
  overflow: scroll;
}

.backButton {
  border-radius: 0px !important;
  height: 32px !important;
  position: absolute;
  width: 100% !important;
  z-index: 3;
}

@media only screen and (max-width: 768px) {
  .ReadyContainer {
    flex-direction: column;
  }
}
