.requestConfirmationModal {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.dialogueModal {
  min-height: 100px;
  width: 250px;
}

.requestConfirmationContent {
  font-size: 14px;
  margin-bottom: 24px;
  max-height: 50vh;
  overflow: auto;
  padding-right: 12px;
  text-align: center;
  width: 90%;
}

.requestConfirmationButtons {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  > div {
    margin-left: 8px;
  }
}
