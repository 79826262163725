.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 24px;
  margin-top: 8px;
  max-height: 512px;
  min-height: 350px;
  > div:nth-child(4) {
    overflow-y: auto;
    > div:nth-child(1) {
      > div:nth-child(1) {
        overflow-y: unset;
      }
    }
  }
}

.content {
  margin-bottom: 32px;
  margin-top: 24px;
}

.actionsContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.companyContent {
  color: inherit;
  max-width: 400px;
}

.button {
  align-self: flex-start;
  margin-bottom: 24px;
}

.inputContainer {
  > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    padding-top: 0px;
  }
  margin-bottom: 24px;
  width: 98%;
}

.error {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  padding-bottom: 8px;
}

.companyData {
  max-width: calc(100% - 100px);
  padding-right: 0px !important;
  > div:nth-child(1) {
    width: 100%;
  }
}

.noPaddingCell {
  padding-right: 8px !important;
}
