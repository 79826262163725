.CandidateHome {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: var(--max-width-total, 1152px);
}

.mainTitle {
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .CandidateHome {
    min-height: 0px;
    min-width: 0px;
    width: calc(100vw - 16px);
  }
}
