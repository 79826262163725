.previewContainer {
  display: flex;
  flex-direction: column;
  height: 0px;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  > span {
    align-self: flex-end;
    padding-top: 12px;
  }
}

.player {
  background-color: #000;
  border-radius: 2px 2px 0px 0px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.recordingContainer {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  width: 100%;
  > button {
    display: flex;
    justify-content: center;
    padding: 0px;
  }
}

.RainCam {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.player:focus {
  outline: none !important;
}

.startTimer {
  align-items: center;
  background-color: transparent;
  bottom: 0px;
  display: flex;
  font-size: 48px;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.recordTimer {
  align-items: flex-end;
  background-color: transparent;
  bottom: 0px;
  display: flex;
  font-size: 32px;
  font-weight: 600;
  height: 100%;
  justify-content: flex-end;
  padding-right: 8px;
  position: absolute;
  width: 100%;
}

.stopButton {
  border-left: none;
  border-radius: 0px 0px 0px 2px;
  border-right: none;
  border-top: none;
  flex: 1;
  min-width: 0px;
  i {
    margin-right: 4px;
  }
}

.startButton {
  border-radius: 0px 0px 0px 2px;
  flex: 1;
  min-width: 0px;
  i {
    margin-right: 4px;
  }
}

.uploadButton {
  border-radius: 0px 0px 2px 0px;
  flex: 1;
  min-width: 0px;
  i {
    margin-right: 4px;
  }
}

.selectButton {
  border: none;
  border-radius: 0px 0px 2px 0px;
  flex: 1;
  justify-content: center;
  min-width: 0px;
  i {
    margin-right: 4px;
  }
}

.selectVideoInput {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.videoInput {
  display: flex;
  flex: 1;
}

.modal {
  align-items: center;
  background-color: #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 270px;
  padding: 16px;
}

.actionButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.header {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 8px;
}

.message {
  font-size: 12px;
  margin-bottom: 16px;
  text-align: center;
}

.logo {
  height: 64px;
  margin-bottom: 16px;
  width: 64px;
}

.browser {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
}

.logoContainer {
  display: flex;
  > a {
    color: var(--gray-035, rgb(148, 153, 162));
    margin: 16px 32px;
    &:hover {
      color: var(--gray-05, rgb(60, 63, 70));
    }
  }
}

.mainBlock {
  margin: 8px;
  margin-top: 16px;
}

.instructionsBlock {
  margin-top: 16px;
}

.instructionsBlockTop {
  margin-bottom: 16px;
}

.instructions {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.instructionsHead {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
}

.delete {
  margin-left: 24px;
  > i {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .discard {
    display: none;
  }

  .delete {
    margin-top: 0px;
    min-width: 0px;
    width: unset;
    > i {
      margin-right: 0px;
    }
  }
}
