.companyLogo {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 16px;
  width: 48px;
}

.companyLogo img {
  max-height: 100%;
  max-width: 48px;
}

.companyTitleLink {
  color: inherit;
  text-decoration: none;
}

.basicInfoContainer {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  border-radius: 4px;
  display: flex;
  padding: 24px;
  transition: background-color 3s linear;
  width: 100%;
  > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
    padding-top: 0px;
  }
}

.basicInfoContainerFull {
  border-radius: 4px;
  display: flex;
  padding: 24px;
  transition: background-color 3s linear;
  width: 100%;
  > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
    padding-top: 0px;
  }
}

.basicInfoColumnInputs {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.mainSubtitle {
  background-color: rbga(0, 0, 0, 0);
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  transition: none;
}

.companyName {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.positionName {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
}

.years {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
}

.description {
  font-size: 14px;
  line-height: 20px;
  max-width: 488px;
  word-break: break-word;
  & a {
    color: var(--seafoam-02, rgb(0, 191, 186));
    &:visited {
      color: var(--seafoam-02, rgb(0, 191, 186));
    }
    &:hover {
      color: var(--seafoam-01, rgb(12, 218, 212));
    }
  }
}

.movedContainer {
  background-color: #fff9df;
  border-radius: 4px;
  display: flex;
  padding: 24px;
  width: 100%;
  > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
    padding-top: 0px;
  }
}

.addButtonBorder {
  align-items: center;
  border: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  border-radius: 2px;
  display: flex;
  font-size: 12px;
  height: 32px;
  justify-content: center;
  min-width: 48px;
  padding: 5px 0px;
}

.avatarWrapper {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  width: 48px;
}

@media only screen and (max-width: 768px) {
  .companyLogo {
    display: none !important;
  }
}
