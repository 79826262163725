/* TODO: redesign this "beauty" */

.SearchKeyword {
  flex: 1;
  min-width: 400px;
  > div:nth-child(1) {
    margin-top: 0px;
    > div:nth-child(1) {
      justify-content: flex-start;
      > div:nth-child(1) {
        max-width: 400px;
        > div:nth-child(1) {
          > input {
            margin-left: 12px;
            width: unset;
          }
        }
      }
    }
  }
}
