.CandidateRegistration {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1152px;
  position: relative;
  width: 100%;
}

.mainContainer {
  align-self: flex-start;
  margin-bottom: 48px;
  margin-top: 24px;
}

.contentContainer {
  margin: 16px auto;
  margin-bottom: 48px;
  max-width: 368px;
  text-align: center;
}

.prevButtonContainer {
  height: 50%;
  margin-left: 16px;
  min-width: 64px;
  width: 64px;
}

.nextButtonContainer {
  height: 50%;
  margin-right: 16px;
  min-width: 64px;
  width: 64px;
}

.actionButton {
  position: fixed !important;
}

.scrollToDiv {
  height: 0px;
}

.errorMessage {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
  margin-top: 24px;
  text-align: center;
}

.imageContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.playerContainer {
  width: 100%;
  > div {
    > iframe {
      max-height: 462px;
      width: 100%;
    }
  }
  > button {
    margin-left: auto;
  }
}

.currentRole {
  font-size: 24px;
  text-align: center;
  width: 100%;
}

.largeIcon {
  font-size: 24px;
}

.skipButton {
  font-weight: 500;
  margin-left: auto;
  margin-top: 80px;
  padding: 0px;
  position: fixed;
  text-decoration: underline;
  width: 64px;
}

.skipTextLong {
  display: block;
  /* min-width: max-content; */
}

.skipTextShort {
  display: none;
}

.modal {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
}

.modalContent {
  font-size: 14px;
  margin-bottom: 16px;
  width: 85%;
}

.modalButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.emptyVideo {
  height: 462px;
}

@media only screen and (max-width: 768px) {
  .mainContainer {
    max-width: calc(100vw - 96px);
    width: calc(100% - 80px);
  }

  .nextButtonContainer {
    margin-right: 8px;
    min-width: 32px;
    width: 32px;
  }

  .prevButtonContainer {
    margin-left: 8px;
    min-width: 32px;
    width: 32px;
  }

  .contentContainer {
    max-width: calc(100vw - 96px);
    text-align: center;
  }

  .CandidateRegistration {
    flex-direction: row !important;
  }

  .largeIcon {
    font-size: 14px;
  }

  .skipTextLong {
    display: none;
  }
  .skipTextShort {
    display: block;
  }

  .skipButton {
    font-weight: 500;
    margin-left: auto;
    margin-top: 48px;
    min-width: 32px;
    padding: 0px;
    position: fixed;
    text-decoration: underline;
    width: 32px;
  }
}
