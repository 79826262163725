.HowItWorks {
  background: #fff;
  padding-bottom: 120px;
  padding-top: 120px;
}

.Container {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 1132px;
  overflow: hidden;
  padding: 0px 24px;
  position: relative;
  width: 100%;
}

.Title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 64px;
  text-align: center;
}

.Column {
  display: inline-block;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  width: 33.33333333333333%;
}

.Image {
  height: auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;

  img {
    width: 50%;
  }
}

.StepTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
}

.Description {
  font-size: 20px;
  line-height: 1.75;
  padding: 0 8px;
}

.Underline {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .Column {
    margin-bottom: 48px;
    width: 100%;
  }
}
