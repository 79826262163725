.Hero {
  background: #fff url('../../assets/hero-01.jpg') no-repeat bottom;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  min-height: 700px;
  padding: 0px 24px;
  padding-top: 6vh;
  width: 100vw;
}

.Text {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  position: relative;
  text-align: center;
}

.row1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  margin-bottom: 16px;
  padding: 0;
}

.row2 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  width: 75%;
}

@media only screen and (max-width: 968px) {
  .row1 {
    font-size: calc(10px + 7vmin);
  }
  .row2 {
    font-size: calc(8px + 2vmin);
  }
}

.Request {
  background-color: #15dad5;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0 0;
  min-width: 92px;
  padding: 12px 24px;
  width: auto;
  &:hover,
  &:active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (max-width: 768px) {
  .Hero {
    background: #fff;
    height: max-content;
    min-height: 500px;
    padding-top: 25vh;
  }
  .row2 {
    width: 100%;
  }
}
