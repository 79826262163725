.workDescriptionContainer {
  max-width: calc(100vw - 64px);
  > div {
    font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif; /* 1 */
    min-height: 200px;
  }
  > div > div {
    font-size: 14px !important;
  }
  > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    min-height: 154px;
  }
}

.companyLogo {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 16px;
  width: 48px;
}

.companyLogo img {
  max-height: 100%;
  max-width: 48px;
}

.basicInfoContainer {
  border-bottom: 1px solid var(--gray-hairline, #dbdbdb);
  padding: 24px;
}

.basicInfoContainerFull {
  padding: 24px;
}

.basicContainer {
  border-radius: 4px;
  display: flex;
  width: 100%;
  > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
    padding-top: 0px;
  }
}

.basicContainerFull {
  border-radius: 4px;
  display: flex;
  width: 100%;
  > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
    padding-top: 0px;
  }
}

.basicInfoColumnInputs {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  max-width: 488px;
}

.label {
  margin-top: 24px;
}

.labelSubtext {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  margin-right: 10px;
}

.labelSubtextTo {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
  margin-right: 10px;
}

.basicInfoRowInputSelects {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.inputSelectsRow {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  min-height: 32px;
  > span {
    flex: 1;
    margin-top: 0px;
    max-width: max-content;
  }
  > button {
    flex: 1;
    margin-top: 0px;
    max-width: max-content;
  }
}

.inputCheckbox {
  margin-left: 220px;
}

.autocompleteContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.deleteWorkHistory {
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: pointer;
  transition: all 0.1s linear;
}

.deleteWorkHistory:hover {
  color: var(--gray-05, rgb(60, 63, 70));
}

.presentLabel {
  font-size: 14px;
  font-weight: 500;
  margin-top: 1px;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 24px;
  width: 100%;
}

.buttonsRight {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .basicInfoColumnInputs {
    width: 100%;
  }

  .companyLogo {
    display: none !important;
  }

  .deleteButton {
    min-width: 70px;
    padding: 0px;
  }
  .inputCheckbox {
    margin-bottom: 16px;
    margin-left: 0px;
  }
  .labelSubtextTo {
    margin-left: 17px;
  }
  .basicInfoRowInputSelects {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .basicInfoContainer {
    padding: 24px 0px;
  }
}
