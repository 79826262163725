.initialContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
}

.uploadButton {
  justify-content: center !important;
  margin-bottom: 8px;
  width: 180px;
}

.skipButton {
  background-color: #fff;
  justify-content: center;
  margin-top: 24px;
  width: 180px;
}

.errorMessage {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.gray {
  color: var(--gray-03, rgb(155, 161, 167));
  font-size: 12px;
}
