.container {
  margin: 24px;
  margin-top: 8px;
}

.detailContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 24px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  gap: 20px;
}

.rowCentered {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;

  & + & {
    margin-top: 10px;
  }
}

.linkNotCopied {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.linkCopied {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.linkCopiedText {
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  transition: all 0.2s linear;
  user-select: none;
  width: 136px;
}

.linkCopiedActive {
  background-color: var(--gray-05, rgb(60, 63, 70)) !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  width: 136px;
}
.linkCopiedActive:hover {
  background-color: var(--gray-05, rgb(60, 63, 70));
  color: #fff;
}

.copyLinkIcon {
  font-size: 24px;
  margin-right: 8px;
}

.copiedLinkIcon {
  color: #fff;
  font-size: 16px;
  margin-right: 8px;
}

.hidden {
  position: absolute;
  right: 10000px;
}
