.titlePadding {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  margin: 0px 16px;
  padding: 0px;
}

.permBlocksContainer {
  padding-bottom: 24px;
  > div > div:nth-child(1) {
    padding-top: 0px;
  }
}

.globalBlocksContainer {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 32px;
  text-transform: capitalize;
}

.subtext {
  color: var(--gray-04, rgb(120, 124, 127));
  font-size: 12px;
  font-weight: 500;
}

.tempBlocksContainer {
  padding-bottom: 24px;
  > div > div:nth-child(1) {
    padding-top: 0px;
  }
}

.tempBlocksHeader {
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 32px;
}

.companyHeader {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: 75px;
  width: 250px;
}

.checkboxHeader {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.companiesContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}

.empContent {
  align-items: center;
  border-bottom: 1px solid var(--gray-025, #d1d3d4);
  display: flex;
  width: 100%;
}
.empData {
  display: flex;
  justify-content: flex-start;
  width: 250px;
}
.removeBlock {
  color: var(--gray-05, rgb(60, 63, 70));
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 24px;
  margin-left: 89px;
  padding: 0px 4px;
  transition: color 0.2s linear;
}

.removeBlock:hover {
  color: var(--red-01, rgb(255, 0, 64));
}

.warning {
  color: var(--red-01, rgb(255, 0, 64));
  padding-bottom: 8px;
}

.info {
  color: var(--seafoam-02, rgb(0, 191, 186));
  padding-bottom: 8px;
}

.blockedStatuses {
  margin-left: 8px;
}

.blockerType {
  align-items: center;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  flex: 1;
  font-size: 12px;
  justify-content: center;
}

.actionContent {
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100px;
}

.cohortActionHeader {
  min-width: 110px;
  width: 110px;
}
