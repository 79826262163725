@import './vars.scss';

.turboProfileContainer {
  background: white;

  color: black;

  display: flex;

  /* body regular */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: $landscapeWidth;

  position: relative;
  width: 100%;

  /* Title */
  h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
    text-transform: uppercase;
  }

  /* Header */
  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
  }

  .bodyBold {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .profileColumn {
    color: white;
    display: flex;
    flex-direction: column;
    height: $profileColumnFixedHeight;
    width: $profileColumnFixedWidth;

    .profileSectionWrapper {
      flex-grow: 1;
    }
  }

  .profileColumn:not(.screenshotView) {
    height: unset;
    min-height: $profileColumnFixedHeight;
  }

  .dataColumnGroup {
    display: flex;
    flex: 3;

    .executiveSummaryColumn {
      flex: 1;
      padding: 16px;
    }

    .professionalSummaryColumn {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 16px;

      .hirewellLogo {
        align-items: flex-end;

        bottom: 16px;

        display: flex;
        flex: 1;
        justify-content: flex-end;
        margin-top: 16px;
        position: absolute;
        right: 16px;

        width: 100%;

        &.screenshotView {
          bottom: unset;
          top: calc($profileColumnFixedHeight - 16px - $logoHeight);
        }

        img {
          height: $logoHeight;
          width: 200px;
        }
      }
    }
  }
}

@media (max-width: $landscapeWidthLess) {
  .turboProfileContainer {
    position: unset;

    .profileColumn {
      height: unset;
      min-height: $profileColumnFixedHeight;
    }

    .dataColumnGroup {
      .professionalSummaryColumn {
        .hirewellLogo {
          bottom: unset;
          position: static;
          right: unset;
          top: unset;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .turboProfileContainer {
    .dataColumnGroup {
      flex-direction: column;
    }
  }
}

@media (max-width: 768px) {
  .turboProfileContainer {
    flex-direction: column;

    .profileColumn {
      min-height: unset;
      width: unset;
    }
  }
}

@media (max-width: 430px) {
  .turboProfileContainer {
    .dataColumnGroup {
      .professionalSummaryColumn {
        .hirewellLogo {
          justify-content: center;
        }
      }
    }
  }
}

@media print {
  @page {
    margin: 0.5in;
    size: landscape;
  }
}
