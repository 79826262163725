.EditorWork {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.workHistoryBlock {
  margin-bottom: 8px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  align-items: center;
  display: flex;
}
