.fileInput {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}

.fileInputLabel {
  cursor: pointer;
}
