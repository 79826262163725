.savedSearch {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: space-between;
}

.savedSearchTitle {
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.07s linear;
  white-space: nowrap;
}

.savedSearchActivated {
  font-weight: 600;
}

.savedSearchTitle:hover {
  color: var(--seafoam-02, rgb(0, 191, 186));
}

.hoverContent {
  color: var(--gray-03, rgb(155, 161, 167));
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: all 0.1s linear;
  visibility: hidden;
}

.activeHoverContent {
  opacity: 1;
  visibility: visible;
}

.edit {
  cursor: pointer;
  text-align: center;
  transition: all 0.1s linear;
  width: 50px;
}

.delete {
  align-items: center;
  color: var(--gray-03, rgb(155, 161, 167));
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: all 0.1s linear;
  width: 50px;
}

.edit:hover {
  color: var(--gray-05, rgb(60, 63, 70));
}

.delete:hover {
  color: var(--gray-05, rgb(60, 63, 70));
}
