.InputBullets {
  display: flex;
}

.InputBulletsPad {
  display: flex;
  padding-top: 16px;
}

.item {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  user-select: none;
}

.itemDrag {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  opacity: 0.7;
  user-select: none;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 262px;
  width: 100%;
}

.disabledDeleteItem {
  align-items: center;
  color: var(--gray-04, rgb(120, 124, 127));
  cursor: not-allowed;
  display: flex;
  font-size: 18px;
  padding: 0 8px;
  padding-top: 16px;
}

.deleteItem {
  align-items: center;
  color: var(--gray-04, rgb(120, 124, 127));
  cursor: pointer;
  display: flex;
  font-size: 18px;
  padding: 0 8px;
  padding-top: 16px;
  transition: color 0.2s;
}

.deleteItem:hover {
  color: var(--red-01, rgb(255, 0, 64));
}

.inputLabel {
  margin-bottom: 16px;
}

.inputLabelMain {
  margin-bottom: 8px;
}

.secondaryInputLabel {
  color: var(--gray-04, rgb(120, 124, 127));
  font-size: 12px;
  margin-bottom: 8px;
}

.inputs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.inputsArray {
  display: block;
}

.addInput {
  font-size: 12px !important;
  padding-left: 0px !important;
}

.dragIcon {
  color: var(--gray-04, rgb(120, 124, 127));
  padding: 11px 9px;
  position: relative;
  top: 2px;
}

.dragIcon:hover {
  color: var(--seafoam-01, rgb(21, 218, 212));
}
