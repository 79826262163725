:root {
  --red-01: rgb(255, 0, 64);
  --red-02: rgb(226, 0, 64);
  --orange-01: rgb(255, 167, 0);
  --green-01: #5cab06;
  --seafoam-01: rgb(21, 218, 212);
  --seafoam-02: rgb(0, 191, 186);
  --seafoam-03: rgb(16, 234, 228);
  --seafoam-04: rgb(219, 252, 251);
  --gray-hairline: #dbdbdb;
  --gray-01: rgb(247, 247, 247);
  --gray-02: rgb(247, 247, 247);
  --gray-025: #d1d3d4;
  --gray-03: rgb(155, 161, 167);
  --gray-035: rgb(148, 153, 162);
  --gray-04: rgb(120, 124, 127);
  --gray-05: rgb(60, 63, 70);
  --level-1-box-shadow-01: rgba(0, 0, 0, 0.15);
  --level-1-box-shadow-02: rgba(0, 0, 0, 0.1);
  --viewport-breakpoint-01: 1220px;
  --max-width-total: 1152px;
  --max-width-content: 952px;
  --max-width-mobile: 768px;
}
