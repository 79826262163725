.EditorPersonal {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.editorButton {
  border: 1px solid #d1d3d4;
  border-radius: 0px !important;
  color: var(--gray-05, rgb(60, 63, 70));
}

.editor {
  border-radius: 0px;
  color: var(--gray-05, rgb(60, 63, 70));
  &.focus {
    outline: var(--seafoam-01, rgb(21, 218, 212)) auto 3px;
    outline-offset: -2px;
  }
  > div > div {
    margin: 0px;
  }
  > div > div > div > button {
    border: 1px solid #d1d3d4;
    border-radius: 0px !important;
    color: var(--gray-05, rgb(60, 63, 70));
    margin-right: 5px !important;
  }
}

.summaryContainer {
  max-width: calc(100vw - 64px);

  position: relative;
  > div {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', 'Helvetica Neue', Arial, sans-serif; /* 1 */
    min-height: 200px;
  }
  > div > div {
    font-size: 14px !important;
  }
  > div:nth-child(1) > div:nth-child(2) {
    display: flex;
    flex: 1;
    flex-direction: column;
    > div {
      display: flex;
      flex: 1;
      flex-direction: column;
      > div:nth-child(2) {
        display: flex;
        flex: 1;
        flex-direction: column;
        > div {
          flex: 1;
        }
      }
    }
  }
  > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    min-height: 154px;
  }

  .summaryLengthNote {
    position: absolute;
    right: 13px;
    top: 13px;
  }
}

.firstBlock {
  margin-top: 8px;
}

.titleBlock {
  margin: 8px;
  margin-bottom: 6px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.scrollDiv {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  padding-top: 2px;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .titleRightSideBlock {
    display: flex;
  }
}

.resumeDetails {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  margin: 0 25%;
  text-align: center;
}

.resumePresentDetails {
  display: flex;
  flex-direction: column;
  > div {
    justify-content: flex-start;
  }
  & input {
    top: 8px;
    width: 98px;
  }
}

.resumeLabel {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.resumeName {
  color: var(--gray-05, rgb(60, 63, 70));
  font-size: 14px;
  font-weight: 600;
}

.resumeUploadButton {
  margin-top: 16px;
}
.resumeUploadNewButton {
  margin-top: 0px;
  padding-left: 0px;
}

.uploadImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 128px;
}

.uploadImageWrapper {
  align-items: center;
  border-radius: 64px;
  display: flex;
  height: 128px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 128px;
}

.uploadImageWrapper > img {
  height: 128px;
  object-fit: cover;
  width: 128px;
}

.candidateImagePlaceholder {
  background-color: var(--gray-01, rgb(247, 247, 247));
  border-radius: 64px;
  height: 128px;
  width: 128px;
}

.candidateImageInput {
  color: var(--seafoam-02, rgb(0, 191, 186));
  font-size: 14px;
  font-weight: 600;
}

.candidateImageInputContainer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.candidateResumeInputContainer {
  display: flex;
  justify-content: flex-start;
}

.phoneEmailInputs {
  display: flex;
  justify-content: flex-start;
  max-width: 520px;
  > div:nth-child(1) {
    flex: 1;
    margin-right: 20px;
  }
}

.nameInputs {
  display: flex;
  justify-content: flex-start;
  > div {
    flex: 1;
    margin-right: 20px;
    max-width: 250px;
    min-width: 150px;
  }
}

.permPlaceholder {
  position: relative;
}
.permPlaceholder input {
  max-width: 470px;
  padding-left: 24px;
  width: 100%;
}

.permPlaceholder > i {
  color: var(--gray-05, rgb(60, 63, 70));
  display: block;
  font-size: 14px;
  font-style: normal;
  left: 9px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 41px;
  width: 18px;
  z-index: 1;
}

.autoSaving {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}
.autoSaved {
  align-items: center;
  color: var(--seafoam-02, rgb(0, 191, 186));
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-right: 12px;
  transition: color 0.2s ease-out, background-color 0.2s ease-in;
}

.saveContent {
  display: flex;
}

.genInfoInputs {
  display: flex;
  flex-direction: column;
  max-width: 685px;

  position: relative;
}

.summarySizeMeasurement {

  border: none;

  font-size: 16px;
  font-weight: 400;
  left: 1000000px;
  line-height: 24px;
  padding: 16px;
  position: absolute;
  top: 0;

  width: 480px;
  z-index: 100;

  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
  }

  ul {
    padding-inline-start: 18px;
  }
}

.resumeSaving {
  max-height: 77px;
  min-height: 77px;
}

.errorText {
  color: var(--red-01, rgb(255, 0, 64));
  font-size: 12px;
}

.socialMediaInputs {
  > div:nth-child(1) > div:nth-child(2) {
    padding-top: 0px;
  }
}

.yearMonthContainer {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.yearContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
  width: 100px;
  > label:nth-child(2) {
    margin-left: 16px;
  }
}

.summaryLabel {
  margin-bottom: 5px;
  margin-top: 24px;
}

.summaryInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.summarySublabel {
  margin-bottom: 16px;
}

.label {
  margin-bottom: 16px;
  margin-top: 24px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
